import { AQString } from 'src/shared/AQString';
import { UserIdentificationStatus } from 'src/interfaces/user';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { contactSupportHandler } from '../../shared';
import { memo, useCallback, useEffect, useState } from 'react';
import { useRouter } from 'next/router';

interface RetryIdentificationButtonProps {
  retryIdentification: () => void;
}

const RetryIdentificationButton = memo(
  ({ retryIdentification }: RetryIdentificationButtonProps) => {
    const [counter, setCounter] = useState(60);

    useEffect(() => {
      const timer = setInterval(() => setCounter(counter - 1), 1000);
      if (counter === 0) {
        clearInterval(timer);
      }
      return () => clearInterval(timer);
    }, [counter]);

    return (
      <ServerSharedButton
        style={{ width: 248, padding: '11px 0' }}
        onClick={retryIdentification}
        label={
          <AQString
            componentId="aquaty-app.profile-completion.retry-identification.cta"
            variables={{ counter }}
          />
        }
        disabled={!!counter}
        variant="inform"
      />
    );
  },
);

interface IdentificationButtonsProps {
  status?: UserIdentificationStatus;
  path: string;
  showRetryButton: boolean;
  buttonLabel: string;
  queryParams?: { [key: string]: any };
}

export const IdentificationButtons = ({
  status,
  path,
  showRetryButton,
  buttonLabel,
  queryParams = {},
}: IdentificationButtonsProps) => {
  const { push } = useRouter();

  const goToIdentification = useCallback(() => {
    push(path);
  }, [path, push]);

  const retryIdentification = useCallback(() => {
    push({
      pathname: path,
      query: { retry: true, ...queryParams },
    });
  }, [path, push, queryParams]);

  return (
    <>
      {(!status || status === UserIdentificationStatus.NOT_STARTED) && (
        <ServerSharedButton
          onClick={goToIdentification}
          label={<AQString componentId={buttonLabel} />}
          variant="inform"
        />
      )}

      {[UserIdentificationStatus.PENDING, UserIdentificationStatus.FAILED].includes(
        status as UserIdentificationStatus,
      ) && (
        <div
          className={
            showRetryButton || status === UserIdentificationStatus.FAILED
              ? 'grid-column template-mc-mc'
              : 'grid-column'
          }
        >
          <ServerSharedButton
            style={{ width: 177, padding: '11px 0' }}
            onClick={contactSupportHandler}
            label={<AQString componentId="aquaty-generic.menu.contact-support.title" />}
            variant="secondary"
          />
          {(showRetryButton || status === UserIdentificationStatus.FAILED) && (
            <RetryIdentificationButton retryIdentification={retryIdentification} />
          )}
        </div>
      )}
    </>
  );
};
