import { useCallback } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { linkFields } from '../../common.strings';
import { VentureMarket, VentureMarketRequest } from '../../../interfaces/venture-pitch';

export const venturePitchMarketGQLString = `
links {
  ${linkFields}
}
serviceAddressableMarketAmount
serviceAddressableMarketCurrency
serviceAddressableMarketNotes
serviceObtainableMarketAmount
serviceObtainableMarketNotes
serviceObtainableMarketCurrency
totalAddressableMarketAmount
totalAddressableMarketCurrency
totalAddressableMarketNotes
`;

const venturePitchMarketQuery = gql`
  query venturePitchMarket($equityOfferingId: Long!) {
    venturePitchMarket(equityOfferingId: $equityOfferingId) {
      ${venturePitchMarketGQLString}
    }
  }
`;

const venturePitchMarketMutation = gql`
  mutation saveVenturePitchMarket($venturePitchMarket: VenturePitchMarketInput) {
    saveVenturePitchMarket(venturePitchMarket: $venturePitchMarket) {
      ${venturePitchMarketGQLString}
    }
  }
`;

type UseGetMarketQuery = {
  venturePitchMarket: VentureMarket;
};

export const useGetMarket = (
  id?: number,
  onCompleted?: (data: UseGetMarketQuery) => void,
  params?: QueryHookOptions,
) => {
  const { data, loading } = useQuery<UseGetMarketQuery>(venturePitchMarketQuery, {
    variables: { equityOfferingId: id },
    onCompleted,
    fetchPolicy: 'no-cache',
    ...params,
  });

  return { venturePitchMarket: data?.venturePitchMarket, loading };
};

type UseSaveMarketMutation = { venturePitchMarket: VentureMarketRequest };

export const useSaveMarket = () => {
  const [mutateMarket, { loading, data }] = useMutation<
    { saveVenturePitchMarket: VentureMarket },
    UseSaveMarketMutation
  >(venturePitchMarketMutation);

  const saveMarket = useCallback(
    async (variables: UseSaveMarketMutation) => {
      await mutateMarket({ variables });
    },
    [mutateMarket],
  );
  return { saveMarket, loading, venturePitchMarket: data?.saveVenturePitchMarket };
};
