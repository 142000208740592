import { SVGProps, memo } from 'react';

const SvgCurrent24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <circle cx={12} cy={12} r={10} fill="#D4D8DE" />
    <circle cx={12} cy={12} r={5} fill="#1F2937" />
  </svg>
);

const Memo = memo(SvgCurrent24Px);
export default Memo;
