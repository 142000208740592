import { SVGProps, memo } from 'react';

const SvgUserAccreditationSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M20.125 4.5H3.875C3.54348 4.5 3.22554 4.6317 2.99112 4.86612C2.7567 5.10054 2.625 5.41848 2.625 5.75V18.25C2.625 18.5815 2.7567 18.8995 2.99112 19.1339C3.22554 19.3683 3.54348 19.5 3.875 19.5H6.375C6.375 19.1685 6.5067 18.8505 6.74112 18.6161C6.97554 18.3817 7.29348 18.25 7.625 18.25C7.95652 18.25 8.27446 18.3817 8.50888 18.6161C8.7433 18.8505 8.875 19.1685 8.875 19.5H15.125C15.125 19.1685 15.2567 18.8505 15.4911 18.6161C15.7255 18.3817 16.0435 18.25 16.375 18.25C16.7065 18.25 17.0245 18.3817 17.2589 18.6161C17.4933 18.8505 17.625 19.1685 17.625 19.5H20.125C20.4565 19.5 20.7745 19.3683 21.0089 19.1339C21.2433 18.8995 21.375 18.5815 21.375 18.25V5.75C21.375 5.41848 21.2433 5.10054 21.0089 4.86612C20.7745 4.6317 20.4565 4.5 20.125 4.5Z"
      fill="white"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.875 9.5H17.625"
      stroke="#0D1117"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.875 12H18.875"
      stroke="#0D1117"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M6.6875 9.1875C6.6875 9.47477 6.74408 9.75922 6.85401 10.0246C6.96395 10.29 7.12508 10.5312 7.3282 10.7343C7.53133 10.9374 7.77248 11.0986 8.03788 11.2085C8.30328 11.3184 8.58773 11.375 8.875 11.375C9.16227 11.375 9.44672 11.3184 9.71212 11.2085C9.97752 11.0986 10.2187 10.9374 10.4218 10.7343C10.6249 10.5312 10.7861 10.29 10.896 10.0246C11.0059 9.75922 11.0625 9.47477 11.0625 9.1875C11.0625 8.90023 11.0059 8.61578 10.896 8.35038C10.7861 8.08498 10.6249 7.84383 10.4218 7.6407C10.2187 7.43758 9.97752 7.27645 9.71212 7.16651C9.44672 7.05658 9.16227 7 8.875 7C8.58773 7 8.30328 7.05658 8.03788 7.16651C7.77248 7.27645 7.53133 7.43758 7.3282 7.6407C7.12508 7.84383 6.96395 8.08498 6.85401 8.35038C6.74408 8.61578 6.6875 8.90023 6.6875 9.1875Z"
      fill="white"
      stroke="#0D1117"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.125 15.75C5.125 14.7554 5.52009 13.8016 6.22335 13.0983C6.92661 12.3951 7.88044 12 8.875 12C9.86956 12 10.8234 12.3951 11.5266 13.0983C12.2299 13.8016 12.625 14.7554 12.625 15.75H5.125Z"
      fill="white"
      stroke="#0D1117"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgUserAccreditationSelected);
export default Memo;
