import { useCallback } from 'react';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';

// HOOK FOR NEW VERSION OF COMPONENT
export const useListActions = ({ steps }: { steps: StepIndicatorObject[] }) => {
  const getNextElement = useCallback((): StepIndicatorObject | null => {
    const activeElementIndex = steps.findIndex((el) => el.active);
    if (activeElementIndex === -1) return null;

    const nextElement = steps[activeElementIndex + 1];

    return nextElement ? nextElement : null;
  }, [steps]);

  const getPreviousElement = useCallback((): StepIndicatorObject | null => {
    const activeElementIndex = steps.findIndex((el) => el.active);
    if (activeElementIndex === -1) return null;

    const previousElement = steps[activeElementIndex - 1];

    return previousElement ? previousElement : null;
  }, [steps]);
  return {
    getNextElement,
    getPreviousElement,
  };
};
