import { SVGProps, memo } from 'react';

const SvgHomeSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#home-selected_svg__a)">
      <path
        d="M22 9.917a.834.834 0 0 0-.325-.659l-9.167-7.083a.833.833 0 0 0-1.016 0L2.325 9.258A.833.833 0 0 0 2 9.917v11.25a.833.833 0 0 0 .833.833H9.5a.417.417 0 0 0 .417-.417v-3.75a2.083 2.083 0 0 1 4.166 0v3.75A.417.417 0 0 0 14.5 22h6.667a.833.833 0 0 0 .833-.833V9.917Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="home-selected_svg__a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgHomeSelected);
export default Memo;
