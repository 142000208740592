import { SVGProps, memo } from 'react';

const SvgAlertCircle48Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#alert-circle-48px_svg__a)">
      <path
        d="M24 4a20.4 20.4 0 0 0-14.217 6.083A19.75 19.75 0 0 0 4 24.35 19.633 19.633 0 0 0 23.667 44H24a20.183 20.183 0 0 0 20-20.35A19.616 19.616 0 0 0 24 4Zm-2.5 27.567A2.467 2.467 0 0 1 24 29a2.55 2.55 0 0 1 2.533 2.45 2.453 2.453 0 0 1-2.45 2.55 2.55 2.55 0 0 1-2.583-2.433Zm.833-6.667v-10a1.667 1.667 0 0 1 3.334 0v10a1.666 1.666 0 1 1-3.334 0Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="alert-circle-48px_svg__a">
        <path fill="#fff" transform="translate(4 4)" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgAlertCircle48Px);
export default Memo;
