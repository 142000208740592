import { DataRoomSteps, DataRoomVentureAssetType } from 'src/interfaces/data-room';

export const dataRoomSetupSteps = [
  {
    value: DataRoomSteps.EXTENDED_PITCH_DECK,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.extended-pitch-deck.title',
    active: true,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.LEGAL_COMPANY_FOUNDATION,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.legal-company-foundation.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.PREVIOUS_FINANCING,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.previous-financings.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.MANAGING_BOARD,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.managing-board.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.EMPLOYEES,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.employees.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.ADVISORS_AND_INVESTORS,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.advisors-and-investors.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.FURTHER_SHAREHOLDERS,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.further-shareholders.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.PRODUCT_AND_DEMO,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.product-and-demo.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.INTELLECTUAL_PROPERTY_AND_ASSETS,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.CURRENT_TRACTION,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.current-tractio.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.BUSINESS_MODEL_AND_GROWTH,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.business-model-and-growth-plan.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.GO_TO_MARKET_AND_DISTRIBUTION,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.go-to-market-and-distribution-strategy.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.COMPETITIVE_LANDSCAPE,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.competitive-landscape.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.FINANCIALS,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.financials.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: DataRoomSteps.OTHERS,
    label: 'aquaty-app.equity-actions.equity-offering.data-room-setup.others.title',
    active: false,
    available: true,
    complete: false,
  },
];

export const assetTypeOptions = [
  {
    value: DataRoomVentureAssetType.TRADEMARK,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.modal.definition.type.trademark',
  },
  {
    value: DataRoomVentureAssetType.PATENT,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.modal.definition.type.patent',
  },
  {
    value: DataRoomVentureAssetType.INTERNET_DOMAIN,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.modal.definition.type.internet-domain',
  },
  {
    value: DataRoomVentureAssetType.PROPRIETARY_TECHNOLOGY,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.modal.definition.type.proprietary-technology',
  },
  {
    value: DataRoomVentureAssetType.EXCLUSIVE_DATASET,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.modal.definition.type.exclusive-dataset',
  },
  {
    value: DataRoomVentureAssetType.REAL_ESTATE,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.modal.definition.type.real-estate',
  },
  {
    value: DataRoomVentureAssetType.MEDIA_REACH,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.modal.definition.type.media-reach',
  },
  {
    value: DataRoomVentureAssetType.OTHER,
    label:
      'aquaty-app.equity-actions.equity-offering.data-room-setup.intellectual-property-and-assets.modal.definition.type.others',
  },
];

export const ventureAssetsTypeLabels = assetTypeOptions.reduce((acc: any, curr: any) => {
  return {
    ...acc,
    [curr.value]: curr.label,
  };
}, {} as Record<DataRoomVentureAssetType, string>);
