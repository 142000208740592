import { SVGProps, memo } from 'react';

const SvgEmail24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.25 5.958h17.5v12.5H3.25v-12.5Z"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m20.468 6.417-6.787 5.22a2.757 2.757 0 0 1-3.362 0l-6.787-5.22"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgEmail24Px);
export default Memo;
