import { SVGProps, memo } from 'react';

const SvgInformationCircle24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.875 15.75h-.625A1.25 1.25 0 0 1 12 14.5v-3.125a.624.624 0 0 0-.625-.625h-.625"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M11.688 8.25a.312.312 0 1 1 0-.625M11.688 8.25a.312.312 0 1 0 0-.625" stroke="#1F2937" strokeWidth={1.5} />
    <path
      d="M12 21.375a9.375 9.375 0 1 0 0-18.75 9.375 9.375 0 1 0 0 18.75Z"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgInformationCircle24Px);
export default Memo;
