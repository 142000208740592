import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { DataRoomCompetitiveLandscape } from 'src/interfaces/data-room';
import { fileFields } from '../common.strings';
import { venturePitchPositioningGQLString } from '../venture-pitch/setup/positioning.query';

export const competitiveLandscapeGQLString = `
competitiveLandscapeAttachments {
  ${fileFields}  
}
competitiveLandscapeDescription
positioning {
  ${venturePitchPositioningGQLString}
}
`;

const dataRoomCompetitiveLandscapeMutation = gql`
  mutation saveDataRoomCompetitiveLandscape(
    $equityOfferingId: Long!
    $competitiveLandscape: DataRoomCompetitiveLandscapeInput
  ) {
    saveDataRoomCompetitiveLandscape(
      equityOfferingId: $equityOfferingId
      competitiveLandscape: $competitiveLandscape
    ) {
      ${competitiveLandscapeGQLString}
    }
  }
`;

interface UseSaveDataRoomCompetitiveLandscapeMutation {
  equityOfferingId: number;
  competitiveLandscape: DataRoomCompetitiveLandscape;
}

export const useSaveDataRoomCompetitiveLandscape = () => {
  const [mutateDataRoomCompetitiveLandscape, { loading, data }] = useMutation(
    dataRoomCompetitiveLandscapeMutation,
  );

  const saveDataRoomCompetitiveLandscape = useCallback(
    async (variables: UseSaveDataRoomCompetitiveLandscapeMutation) => {
      await mutateDataRoomCompetitiveLandscape({ variables });
    },
    [mutateDataRoomCompetitiveLandscape],
  );
  return {
    saveDataRoomCompetitiveLandscape,
    loading,
    savedDataRoomCompetitiveLandscape: data?.saveDataRoomCompetitiveLandscape,
  };
};
