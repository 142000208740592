import { useCallback, useLayoutEffect, useRef, useState, FC, CSSProperties } from 'react';
import ReactDOM from 'react-dom';

import InformationCircle16Px from 'public/svg-components/InformationCircle16Px';
import { ServerSharedText } from 'src/server-shared/texts/text';
import useEventListener from 'src/hooks/useEventListener';
import { AQString } from 'src/shared/AQString';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

const getNumber = (number: number | undefined) => number || 0;

const middleOfTooltipToMiddleOfContainer = (container: DOMRect, element: DOMRect) => {
  return `${
    getNumber(container.left) + getNumber(container.width) / 2 - getNumber(element.width) / 2
  }px`;
};

const getTopVertical = (container: DOMRect, element: DOMRect, shiftY: number) => {
  return `${getNumber(container.top) - getNumber(element.height) - shiftY}px`;
};

const getBottomVertical = (container: DOMRect, shiftY: number) => {
  return `${getNumber(container.bottom) + shiftY}px`;
};

const getLeftHorizontal = (container: DOMRect) => {
  return `${getNumber(container.left)}px`;
};

interface ServerSharedTooltipProps {
  text: string | JSX.Element;
  shiftX?: number;
  position?:
    | 'auto'
    | 'left'
    | 'right'
    | 'top'
    | 'top-left'
    | 'bottom-left'
    | 'bottom'
    | 'bottom-right'
    | 'progress-top';
  maxWidth?: number;
  childContainerClassName?: string;
  disabled?: boolean;
  style?: CSSProperties;
  shiftY?: number;
}

export const ServerSharedTooltip: FC<ServerSharedTooltipProps> = ({
  text,
  shiftX = 8,
  position = 'auto',
  maxWidth = 800,
  children,
  childContainerClassName = '',
  disabled = false,
  style,
  shiftY = 8,
}) => {
  const iconContainerRef = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const [hovered, setHovered] = useState<boolean>(false);
  const [showTooltip, setShowTooltip] = useState<boolean>(false);
  const clientLeftRef = useRef(0);

  const onMouseLeave = useCallback(() => {
    if (hovered) {
      setHovered(false);
      setShowTooltip(false);
    }
  }, [hovered]);

  const onMouseEnter = useCallback(
    (e) => {
      if (!hovered) {
        clientLeftRef.current = e.clientX;
        setHovered(true);
        setShowTooltip(true);
      }
    },
    [hovered],
  );

  const calculatePosition = useCallback(() => {
    const container = iconContainerRef.current?.getBoundingClientRect();
    const increasedWidthByHover = 8;

    if (container && tooltipRef.current) {
      const element = tooltipRef.current?.getBoundingClientRect();
      const halfHeightOfTooltip = element.height / 2;
      const halfHeightOfContainer = getNumber(container.height) / 2;

      tooltipRef.current.style.top = `${
        getNumber(container.top) - halfHeightOfTooltip + halfHeightOfContainer
      }px`;

      switch (position) {
        case 'left': {
          tooltipRef.current.style.left = `${
            getNumber(container.x) - getNumber(element.width) - increasedWidthByHover - shiftX
          }px`;

          tooltipRef.current.classList.add(classes.tooltip__left);
          break;
        }

        case 'right': {
          tooltipRef.current.style.left = `${
            getNumber(container.x) + getNumber(container.width) + increasedWidthByHover + shiftX
          }px`;

          tooltipRef.current.classList.add(classes.tooltip__right);
          break;
        }

        case 'top': {
          tooltipRef.current.style.left = middleOfTooltipToMiddleOfContainer(container, element);
          tooltipRef.current.style.top = getTopVertical(container, element, shiftY);
          tooltipRef.current.classList.add(classes.tooltip__top);
          break;
        }

        case 'progress-top': {
          tooltipRef.current.style.left = `${
            getNumber(clientLeftRef.current) - getNumber(element.width) / 2
          }px`;
          tooltipRef.current.style.top = `${
            getNumber(container.top) - getNumber(element.height) - shiftY
          }px`;

          tooltipRef.current.classList.add(classes.tooltip__top);
          break;
        }

        case 'top-left': {
          tooltipRef.current.style.top = getTopVertical(container, element, shiftY);
          tooltipRef.current.style.left = getLeftHorizontal(container);
          tooltipRef.current.classList.add(classes['tooltip__top-left']);
          break;
        }

        case 'bottom': {
          tooltipRef.current.style.top = getBottomVertical(container, shiftY);
          tooltipRef.current.style.left = middleOfTooltipToMiddleOfContainer(container, element);
          tooltipRef.current.classList.add(classes.tooltip__bottom);

          break;
        }

        case 'bottom-right': {
          tooltipRef.current.style.top = getBottomVertical(container, shiftY);
          tooltipRef.current.style.left = `${
            getNumber(container.right) - getNumber(element.width)
          }px`;
          tooltipRef.current.classList.add(classes['tooltip__bottom-right']);
          break;
        }

        case 'bottom-left': {
          tooltipRef.current.style.top = getBottomVertical(container, shiftY);
          tooltipRef.current.style.left = getLeftHorizontal(container);
          tooltipRef.current.classList.add(classes['tooltip__bottom-left']);
          break;
        }

        case 'auto': {
          const screenWidth = window.innerWidth;
          const availableSpaceAtRight = screenWidth - (container.x + container.width);
          const spaceWitchAreNeeded = element.width + increasedWidthByHover + shiftX;

          if (availableSpaceAtRight > spaceWitchAreNeeded) {
            tooltipRef.current.style.left = `${
              getNumber(container.x) + getNumber(container.width) + increasedWidthByHover + shiftX
            }px`;

            tooltipRef.current.classList.add(classes.tooltip__right);
          } else {
            tooltipRef.current.style.left = `${
              getNumber(container.x) - getNumber(element.width) - increasedWidthByHover - shiftX
            }px`;

            tooltipRef.current.classList.add(classes.tooltip__left);
          }
          break;
        }
      }
    }
  }, [position, shiftX, shiftY]);

  useLayoutEffect(() => {
    calculatePosition();
  }, [calculatePosition, hovered]);

  useEventListener('scroll', calculatePosition);

  useLayoutEffect(() => {
    const hide = () => {
      setHovered(false);
      setShowTooltip(false);
    };
    window.addEventListener('blur', hide);
    return () => window.removeEventListener('blur', hide);
  }, []);

  return (
    <>
      <div
        className={classNames(classes.container, childContainerClassName)}
        ref={iconContainerRef}
        style={style}
      >
        {children ? (
          <div onPointerLeave={onMouseLeave} onPointerEnter={onMouseEnter}>
            {children}
          </div>
        ) : (
          <>
            <div
              className={classNames(classes.backdrop, {
                [classes['backdrop--hovered']]: hovered,
              })}
            />
            <InformationCircle16Px
              className={classes.container__icon}
              onPointerLeave={onMouseLeave}
              onPointerEnter={onMouseEnter}
            />
          </>
        )}
      </div>

      {showTooltip &&
        !disabled &&
        ReactDOM.createPortal(
          <div ref={tooltipRef} className={classes.tooltip} style={{ maxWidth }}>
            <ServerSharedText color="body">
              {typeof text === 'string' ? <AQString componentId={text} /> : text}
            </ServerSharedText>
          </div>,
          document.body,
        )}
    </>
  );
};
