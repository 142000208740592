import { useCallback } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { fileFields } from '../../common.strings';
import { VentureTeamMember, VentureTeamMemberRequest } from 'src/interfaces/venture-pitch';

export const venturePitchTeamGQLString = `
advisorSince
aquatyAccountEmail
category
comments
customRole
equityParticipation
expertiseAreas {
  id
  nameStringId
}
fullName
id
introductionVideo {
  ${fileFields}
}
profileImage {
  ${fileFields}
}
role
shareholder {
  fullName
  id
}
socialLinkUrl
`;

const venturePitchTeamQuery = gql`
  query venturePitchTeamMembers($equityOfferingId: Long!) {
    venturePitchTeamMembers(equityOfferingId: $equityOfferingId) {
      ${venturePitchTeamGQLString}
    }
  }
`;

const venturePitchTeamMutation = gql`
  mutation saveVenturePitchTeamMembers($equityOfferingId: Long!, $venturePitchTeamMembers: [VentureTeamMemberInput]) {
    saveVenturePitchTeamMembers(equityOfferingId: $equityOfferingId, venturePitchTeamMembers: $venturePitchTeamMembers) {
      ${venturePitchTeamGQLString}
    }
  }
`;

type UseGetVenturePitchTeamQuery = {
  venturePitchTeamMembers: VentureTeamMember[];
};

export const useGetVenturePitchTeam = (
  id?: number,
  onCompleted?: (data: UseGetVenturePitchTeamQuery) => void,
  params?: QueryHookOptions,
) => {
  const { data, loading } = useQuery<UseGetVenturePitchTeamQuery>(venturePitchTeamQuery, {
    variables: { equityOfferingId: id },
    onCompleted,
    fetchPolicy: 'no-cache',
    ...params,
  });

  return { venturePitchTeamMembers: data?.venturePitchTeamMembers, loading };
};

type UseSaveVenturePitchTeamMutation = {
  venturePitchTeamMembers: VentureTeamMemberRequest[];
  equityOfferingId: number;
};

export const useSaveVenturePitchTeam = () => {
  const [mutateVenturePitchTeam, { loading, data }] = useMutation<
    { saveVenturePitchTeamMembers: VentureTeamMember[] },
    UseSaveVenturePitchTeamMutation
  >(venturePitchTeamMutation);

  const saveVenturePitchTeam = useCallback(
    async (variables: UseSaveVenturePitchTeamMutation) => {
      await mutateVenturePitchTeam({ variables });
    },
    [mutateVenturePitchTeam],
  );
  return {
    saveVenturePitchTeam,
    loading,
    venturePitchTeamMembers: data?.saveVenturePitchTeamMembers,
  };
};
