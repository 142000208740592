import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { Configuration } from 'src/interfaces/configuration';

const configurationQuery = gql`
  query {
    configuration {
      dataRoomReviewSections {
        titleStringId
        value
      }
      dataRoomEvaluationSections {
        titleStringId
        value
      }
      dataRoomSectionAttributes {
        descriptionStringId
        maxLabelStringId
        minLabelStringId
        titleStringId
        value
      }
      notificationEventClusters {
        titleStringId
        value
      }
      notificationEvents {
        titleStringId
        value
      }
    }
  }
`;

export function useGetConfiguration(params?: QueryHookOptions) {
  const { data, loading } = useQuery<{ configuration: Configuration }>(configurationQuery, {
    context: {
      headers: {
        authorization: null,
      },
    },
    ...params,
  });

  return {
    configuration: data?.configuration,
    loading,
  };
}
