import { SVGProps, memo } from 'react';

const SvgInformationCircle16Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#information-circle-16px_svg__a)">
      <path
        d="M8 2a6 6 0 1 0 0 12A6 6 0 0 0 8 2Zm.643 9a.643.643 0 0 1-1.286 0V8a.643.643 0 0 1 1.286 0v3ZM8 6.286A.857.857 0 1 1 8 4.57a.857.857 0 0 1 0 1.715Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="information-circle-16px_svg__a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgInformationCircle16Px);
export default Memo;
