import { SVGProps, memo } from 'react';

const SvgAdd16Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M14 8a.857.857 0 0 0-.857-.857H9.07a.214.214 0 0 1-.214-.214V2.857a.857.857 0 1 0-1.714 0V6.93a.214.214 0 0 1-.214.214H2.857a.857.857 0 1 0 0 1.714H6.93a.214.214 0 0 1 .214.214v4.072a.857.857 0 1 0 1.714 0V9.07a.214.214 0 0 1 .214-.214h4.072A.857.857 0 0 0 14 8Z"
      fill="#1F2937"
    />
  </svg>
);

const Memo = memo(SvgAdd16Px);
export default Memo;
