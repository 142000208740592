export type PaletteKeys =
  | 'body'
  | 'bodyOpacity50'
  | 'bodyOpacity10'
  | 'bodyOpacity15'
  | 'accent'
  | 'accentColor'
  | 'ternary'
  | 'ternaryOpacity50'
  | 'ternaryOpacity15'
  | 'ternaryOpacity10'
  | 'ternaryOpacity5'
  | 'aqua100'
  | 'aqua800'
  | 'grey50'
  | 'grey100'
  | 'grey200'
  | 'grey300'
  | 'grey400'
  | 'grey500'
  | 'grey600'
  | 'grey700'
  | 'grey800'
  | 'grey900'
  | 'grey1000'
  | 'alert50'
  | 'alert100'
  | 'alert600'
  | 'alert700'
  | 'alert800'
  | 'primary50'
  | 'primary100'
  | 'primary200'
  | 'primary300'
  | 'primary500'
  | 'primary600'
  | 'primary700'
  | 'primary800'
  | 'primary900'
  | 'verde200'
  | 'verde800'
  | 'success50'
  | 'success100'
  | 'success200'
  | 'success500'
  | 'success600'
  | 'success700'
  | 'success800'
  | 'warning100'
  | 'warning200'
  | 'warning500'
  | 'warning600'
  | 'warning800'
  | 'verde700'
  | 'green'
  | 'green500'
  | 'rework100'
  | 'rework600';

export const palette: Record<PaletteKeys, string> = {
  body: '#ffffff',
  bodyOpacity10: 'rgba(255, 255, 255, 0.1)',
  bodyOpacity15: 'rgba(255, 255, 255, 0.15)',
  bodyOpacity50: 'rgba(255, 255, 255, 0.5)',
  accent: '#E5DE17',
  accentColor: '#161616',
  ternary: '#000000',
  ternaryOpacity10: 'rgba(0, 0, 0, 0.1)',
  ternaryOpacity15: 'rgba(0, 0, 0, 0.15)',
  ternaryOpacity50: 'rgba(0, 0, 0, 0.5)',
  ternaryOpacity5: 'rgba(0, 0, 0, 0.05)',
  aqua100: '#DFEAF2',
  aqua800: '#263B4C',
  grey50: '#F9FAFB',
  grey100: '#F1F2F4',
  grey200: '#E2E4E9',
  grey300: '#D4D8DE',
  grey400: '#9CA3AF',
  grey500: '#6B7280',
  grey600: '#4B5563',
  grey700: '#374151',
  grey800: '#1F2937',
  grey900: '#0D1117',
  grey1000: '#11161D',
  alert50: '#FEF6F6',
  alert100: '#fde7e7',
  alert600: '#F23535',
  alert700: '#E50F0F',
  alert800: '#B50C0C',
  primary50: '#F6FAFE',
  primary100: '#E9F3FC',
  primary200: '#D3E7F8',
  primary300: '#BDDCF5',
  primary500: '#4299E1',
  primary600: '#2180CF',
  primary700: '#1A65A3',
  primary800: '#134A77',
  primary900: '#0C2E4B',
  verde200: '#D0E9DE',
  verde800: '#375045',
  success50: '#F8FCF9',
  success100: '#EDF8F1',
  success200: '#DAF1E3',
  success500: '#48BB78',
  success600: '#389860',
  success700: '#2B7249',
  success800: '#1D4D31',
  warning100: '#FEF0CD',
  warning200: '#FEF0CD',
  warning500: '#FBBF28',
  warning600: '#EBAA05',
  warning800: '#876203',
  verde700: '#527767',
  green: '#3EB261',
  green500: '#48BB78',
  rework600: '#EB7305',
  rework100: '#FEF2E6',
};
