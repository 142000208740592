import { FileField } from './common';
import { Venture } from './venture';
import {
  VentureMarket,
  VenturePositioning,
  VentureProblem,
  VentureSolution,
  VentureTeamMember,
  VentureProduct,
} from './venture-pitch';

export enum DataRoomSteps {
  'ADVISORS_AND_INVESTORS' = 'ADVISORS_AND_INVESTORS',
  'BUSINESS_MODEL_AND_GROWTH' = 'BUSINESS_MODEL_AND_GROWTH',
  'COMPETITIVE_LANDSCAPE' = 'COMPETITIVE_LANDSCAPE',
  'CURRENT_TRACTION' = 'CURRENT_TRACTION',
  'EMPLOYEES' = 'EMPLOYEES',
  'EXTENDED_PITCH_DECK' = 'EXTENDED_PITCH_DECK',
  'FINANCIALS' = 'FINANCIALS',
  'FURTHER_SHAREHOLDERS' = 'FURTHER_SHAREHOLDERS',
  'GO_TO_MARKET_AND_DISTRIBUTION' = 'GO_TO_MARKET_AND_DISTRIBUTION',
  'INTELLECTUAL_PROPERTY_AND_ASSETS' = 'INTELLECTUAL_PROPERTY_AND_ASSETS',
  'LEGAL_COMPANY_FOUNDATION' = 'LEGAL_COMPANY_FOUNDATION',
  'MANAGING_BOARD' = 'MANAGING_BOARD',
  'OTHERS' = 'OTHERS',
  'PREVIOUS_FINANCING' = 'PREVIOUS_FINANCING',
  'PRODUCT_AND_DEMO' = 'PRODUCT_AND_DEMO',
}

export interface DataRoom {
  marketOpportunity: any;
  id: number;
  extendedPitchDeck?: FileField;
  marketAndDistributionStrategy: DataRoomMarketAndDistributionStrategy;
  currentTraction: DataRoomCurrentTraction;
  competitiveLandscape: DataRoomCompetitiveLandscape;
  equityOffering?: {
    venture?: Venture;
  }; // do we need this interface?
  productAndDemo: DataRoomProductAndDemo;
  completedStates?: DataRoomSteps[];
  synchronisedVentureDocuments?: VentureDocument[];
  teamMembers: DataRoomTeamMember[];
  uploadedVentureDocuments?: VentureDocument[];
  previousFinancingEvents: DataRoomPreviousFinancingEvent[];
  intellectualProperty: DataRoomIntellectualProperty;
  growthPlan: DataRoomGrowthPlan;
  financials: DataRoomFinancials;
  additionalInformation: DataRoomAdditionalInformation;
}

export interface DataRoomMarketAndDistributionStrategy {
  marketValidationDescription: string;
  marketValidationAttachments: FileField[];
  addressableMarket: VentureMarket;
  distributionChanelAttachments: FileField[];
  distributionChanelDescription: string;
  advantagesAttachments: FileField[];
  advantagesDescription: string;
  obstaclesAttachments: FileField[];
  obstaclesDescription: string;
}

export interface DataRoomCurrentTraction {
  currentTractionAttachments: FileField[];
  currentTractionDescription: string;
  contracts: VentureDocument[];
  furtherInformationDescription: string;
}

export interface DataRoomCompetitiveLandscape {
  competitiveLandscapeAttachments: FileField[];
  competitiveLandscapeDescription: string;
  positioning: VenturePositioning;
}

export interface DataRoomProductAndDemo {
  customerPersonasAttachments: FileField[];
  customerPersonasDescription: string;
  customerProblem: VentureProblem;
  customerSolution: VentureSolution;
  uspClarificationAttachments: FileField[];
  uspClarificationDescription: string;
  product: VentureProduct;
  problemValidationDescription: string;
  problemValidationAttachments: FileField[];
}

export interface VentureDocument {
  description: string;
  document: FileField;
  title: string;
  id?: number | string;
  uploadDate?: string;
  ordered?: boolean;
}

export interface DataRoomTeamMember extends VentureTeamMember {
  completed: boolean;
  contractType: EmployeeContractType;
  cv: FileField;
  employmentContract: FileField;
  entranceDate: string;
  latestEmployerReferenceDocument: FileField;
  noEmploymentContract: boolean;
  noLatestEmployerReferenceDocument: boolean;
  noPreviousReferences: boolean;
  noPreviousReferencesExplanation: string;
  references: ManagingBoardReference[];
  noEmploymentContractExplanation: string;
  noLatestEmployerReferenceDocumentExplanation: string;
  addedInVenturePitch: boolean;
}

export enum EmployeeContractType {
  'FULL_TIME' = 'FULL_TIME',
  'PART_TIME' = 'PART_TIME',
}

export interface ManagingBoardReference {
  id?: number | string;
  name: string;
  phoneNumber: string;
  phoneNumberCountryCode: string;
  professionalTitle: string;
}

export enum FinancingCapitalType {
  'CONVERTABLE_LOANS' = 'CONVERTABLE_LOANS',
  'DEBT' = 'DEBT',
  'EQUITY_VIA_CAPITAL_INCREASE' = 'EQUITY_VIA_CAPITAL_INCREASE',
}

export interface DataRoomPreviousFinancingEvent {
  capitalAmount: number;
  id?: number;
  interestRate?: number;
  legalLenderName?: string;
  loanTerminationDate?: string | null;
  participants?: DataRoomPreviousFinancingEventParticipation[];
  postMoneyValuation?: number;
  roundName: string;
  selectedConvertibleLoans?: number[];
  signingDate: string;
  type: FinancingCapitalType;
}

interface DataRoomPreviousFinancingEventParticipation {
  id?: number;
  participationAmount: number;
  investorId: number;
}

export interface DataRoomIntellectualProperty {
  assets: DataRoomVentureAsset[];
  furtherIntellectualPropertiesInformation: string;
}

export interface DataRoomVentureAsset {
  creatorName: string;
  definitionAttachments: FileField[];
  description: string;
  id: number;
  maintenanceAttachments: FileField[];
  name: string;
  protectionAttachments: FileField[];
  protectionDescription: string;
  qualityMaintenanceDescription: string;
  type: DataRoomVentureAssetType;
}

export enum DataRoomVentureAssetType {
  'EXCLUSIVE_DATASET' = 'EXCLUSIVE_DATASET',
  'INTERNET_DOMAIN' = 'INTERNET_DOMAIN',
  'OTHER' = 'OTHER',
  'PATENT' = 'PATENT',
  'PROPRIETARY_TECHNOLOGY' = 'PROPRIETARY_TECHNOLOGY',
  'TRADEMARK' = 'TRADEMARK',
  'MEDIA_REACH' = 'MEDIA_REACH',
  'REAL_ESTATE' = 'REAL_ESTATE',
}

export interface DataRoomGrowthPlan {
  businessModelAttachments: FileField[];
  businessModelDescription: string;
  customerPersonasAttachments: FileField[];
  customerPersonasDescription: string;
  growthPlanAttachments: FileField[];
  growthPlanDescription: string;
  revenueStreamsDescription: string;
  revenueStreamsAttachments: FileField[];
  unitEconomicsDescription: string;
  unitEconomicsAttachments: FileField[];
}

export interface DataRoomFinancials {
  liquidityPlaning: FileField;
  longTermFinancingPlaning: FileField;
  longTermPlaningDescription: string;
  opexes: VenturePitchInvestmentOfferingOpex[];
  shortTermPlaningDescription: string;
  financialStatements: DataRoomFinancialStatement[];
}

export interface VenturePitchInvestmentOfferingOpex {
  amount: number;
  id: number;
  name: string;
}
export interface DataRoomFinancialStatement {
  description: string;
  file: FileField;
  id?: number | string;
  title: string;
}

export interface DataRoomReviewer {
  cv: FileField;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  photo: FileField;
  socialLinkUrl: string;
}

export interface DataRoomAdditionalInformation {
  otherFiles: FileField[];
  additionalInformationDescription: string;
}
