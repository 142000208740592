import { DocumentNode, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';
import { venturePitchTargetsQuery } from '../venture-pitch/venture-pitch-targets.query';
import { venturesQuery } from '../ventures.query';

const mutationCompleteEvaluation = gql`
  mutation completeDataRoomEvaluation($equityOfferingId: Long!) {
    completeDataRoomEvaluation(equityOfferingId: $equityOfferingId)
  }
`;

interface CompleteEvaluationMutation {
  equityOfferingId: number;
}

export const useSaveCompleteEvaluation = (
  refetchQueries: DocumentNode[] = [venturesQuery, venturePitchTargetsQuery],
) => {
  const [mutate, { loading }] = useMutation<any>(mutationCompleteEvaluation, {
    refetchQueries: refetchQueries,
  });

  const saveCompleteEvaluation = useCallback(
    async (values: CompleteEvaluationMutation) => {
      await mutate({
        variables: values,
      });
    },
    [mutate],
  );

  return {
    saveCompleteEvaluation,
    loading,
  };
};
