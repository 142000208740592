import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { DataRoomCurrentTraction } from 'src/interfaces/data-room';
import { fileFields, ventureDocument } from '../common.strings';

export const currentTractionGQLString = `
currentTractionAttachments {
  ${fileFields}  
}
currentTractionDescription
contracts {
  ${ventureDocument}
}
furtherInformationDescription
`;

const dataRoomCurrentTractionMutation = gql`
  mutation saveDataRoomCurrentTraction(
    $equityOfferingId: Long!
    $currentTraction: DataRoomCurrentTractionInput
  ) {
    saveDataRoomCurrentTraction(
      equityOfferingId: $equityOfferingId
      currentTraction: $currentTraction
    ) {
      ${currentTractionGQLString}
    }
  }
`;

interface UseSaveDataRoomCurrentTractionMutation {
  equityOfferingId: number;
  currentTraction: DataRoomCurrentTraction;
}

export const useSaveDataRoomCurrentTraction = () => {
  const [mutateDataRoomCurrentTraction, { loading, data }] = useMutation(
    dataRoomCurrentTractionMutation,
  );

  const saveDataRoomCurrentTraction = useCallback(
    async (variables: UseSaveDataRoomCurrentTractionMutation) => {
      await mutateDataRoomCurrentTraction({ variables });
    },
    [mutateDataRoomCurrentTraction],
  );
  return {
    saveDataRoomCurrentTraction,
    loading,
    savedDataRoomCurrentTraction: data?.saveDataRoomCurrentTraction,
  };
};
