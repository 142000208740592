import { VentureShareholders } from './cap-table';
import {
  FileField,
  Currency,
  MarketIntegrationStage,
  UserFont,
  VentureBusinessCategory,
  VentureStage,
  InitializationType,
} from './common';
import { Industry } from './industries';
import { EquityOffering, InvestmentOffering } from './offering';
import { Venture } from './venture';

export interface VenturePitchBasicsOffering
  extends Pick<
    EquityOffering,
    | 'closingDate'
    | 'releaseDate'
    | 'investmentCommitments'
    | 'requiredInvestmentAmount'
    | 'maxInvestmentAmount'
    | 'createdAt'
    | 'id'
    | 'preMoneyValuationFounderIndication'
    | 'name'
    | 'clubId'
  > {
  venture?: Venture;
}

export interface VenturePitchBasics {
  accentColor?: string;
  backgroundColor?: string;
  businessCategory?: VentureBusinessCategory;
  description?: string;
  equityOffering?: VenturePitchBasicsOffering;
  font?: UserFont;
  fundingStage?: VentureStage;
  id: number;
  industries?: Industry[];
  completedStates?: VenturePitchSteps[];
  initializationType?: InitializationType;
  userVenturePitchFile?: FileField;
}

export enum VenturePitchSteps {
  'UPLOAD_PITCH_DECK' = 'UPLOAD_PITCH_DECK',
  'PITCH_DESIGN' = 'PITCH_DESIGN',
  'EXECUTIVE_SUMMARY' = 'EXECUTIVE_SUMMARY',
  'PROBLEM' = 'PROBLEM',
  'SOLUTION' = 'SOLUTION',
  'PRODUCT' = 'PRODUCT',
  'TEAM' = 'TEAM',
  'MARKET' = 'MARKET',
  'POSITIONING' = 'POSITIONING',
  'BUSINESS_MODEL_AND_TRACTION' = 'BUSINESS_MODEL_AND_TRACTION',
  'ELEVATOR_PITCH' = 'ELEVATOR_PITCH',
  'INVESTMENT_OFFERING' = 'INVESTMENT_OFFERING',
  'SUMMARY' = 'SUMMARY',
}

export type VenturePitchStepsForDesktopPreview = Exclude<
  VenturePitchSteps,
  VenturePitchSteps.SUMMARY
>;

export interface VenturePitch extends VenturePitchBasics {
  problem: VentureProblem;
  solution: VentureSolution;
  product: VentureProduct;
  teamMembers: VentureTeamMember[];
  market: VentureMarket;
  positioning: VenturePositioning;
  businessModel: VentureBusinessModel;
  elevatorPitch: VentureElevatorPitch;
  investmentOffering: InvestmentOffering;
}

export interface VenturePitchMobile extends Omit<VenturePitch, 'industries' | 'elevatorPitch'> {
  industries: number[];
  elevatorPitch: {
    video: FileField;
    speakers: number[] | { id: number }[];
  };
}

export interface VentureElevatorPitch {
  video: FileField;
  speakers: VentureTeamMember[];
}

export interface VentureElevatorPitchRequest extends VentureElevatorPitch {
  venturePitch: {
    id: number;
  };
}

export interface VentureMarket {
  links: VenturePitchLink[];
  serviceAddressableMarketAmount: number;
  serviceAddressableMarketCurrency: Currency;
  serviceAddressableMarketNotes: string;
  serviceObtainableMarketAmount: number;
  serviceObtainableMarketCurrency: Currency;
  serviceObtainableMarketNotes: string;
  totalAddressableMarketAmount: number;
  totalAddressableMarketCurrency: Currency;
  totalAddressableMarketNotes: string;
}

export interface VentureMarketRequest extends VentureMarket {
  venturePitch: {
    id: number;
  };
}

export interface VenturePositioning {
  labelX: string;
  labelY: string;
  maxLabelX: string;
  maxLabelY: string;
  minLabelX: string;
  minLabelY: string;
  competitors: Competitor[];
  venturePositioningX: number;
  venturePositioningY: number;
}

export interface VenturePositioningRequest extends VenturePositioning {
  venturePitch: {
    id: number;
  };
}

export interface VentureProblem {
  description: string;
  links: VenturePitchLink[];
  images: FileField[];
}

export interface VentureProblemRequest extends VentureProblem {
  venturePitch: {
    id?: number;
  };
}

export interface VentureProduct {
  demoLink: string;
  description: string;
  images: FileField[];
  password: string;
  text: string;
  username: string;
}

export interface VentureProductRequest extends VentureProduct {
  venturePitch: {
    id?: number;
  };
}

export interface VentureSolution {
  description: string;
  images: FileField[];
}

export interface VentureSolutionRequest extends VentureSolution {
  venturePitch: {
    id?: number;
  };
}

export interface VentureTeamMember {
  advisorSince: string;
  category: TeamMemberCategory;
  comments: string;
  aquatyAccountEmail: string;
  customRole: string;
  equityParticipation: number | string;
  expertiseAreas: number[];
  fullName: string;
  introductionVideo: FileField;
  profileImage: FileField;
  role?: TeamMemberRole | null;
  shareholder: VentureShareholders | null;
  socialLinkUrl: string;
  id?: number | string;
}

export interface VentureTeamMemberRequest extends VentureTeamMember {
  venturePitch: {
    id?: number;
  };
}

export interface VentureBusinessModel {
  businessModelDescription: string;
  fullTimeEmployeesNumber: number | string;
  images: FileField[];
  marketIntegrationStage: MarketIntegrationStage;
  partTimeEmployeesNumber: number | string;
  tractionDescription: string;
}

export interface VentureBusinessModelRequest extends VentureBusinessModel {
  venturePitch: {
    id?: number;
  };
}

// common
export enum TeamMemberCategory {
  'FURTHER_SHAREHOLDER' = 'FURTHER_SHAREHOLDER',
  'INVESTOR_AND_ADVISOR' = 'INVESTOR_AND_ADVISOR',
  'KEY_EMPLOYEE' = 'KEY_EMPLOYEE',
  'MANAGING_BOARD' = 'MANAGING_BOARD',
  'FURTHER_EMPLOYEES' = 'FURTHER_EMPLOYEES',
}

export enum TeamMemberRole {
  ADVISOR = 'ADVISOR',
  FOUNDER = 'FOUNDER',
  INVESTOR = 'INVESTOR',
}

export interface Competitor {
  clusterColor: string;
  clusterName: string;
  companyImage: FileField;
  companyName: string;
  id: number | string;
  positioningX: number;
  positioningY: number;
  websiteUrl: string;
}

export interface VentureInvestmentOfferingOpex {
  amount: number | string;
  id: number | string;
  name: string;
}

export interface VenturePitchLink {
  index: number;
  title: string;
  url: string;
}
