import { FC } from 'react';
import { Worker } from '@react-pdf-viewer/core';

// css files of pdf viewer plugins
import '@react-pdf-viewer/search/lib/styles/index.css';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/default-layout/lib/styles/index.css';

/*eslint-disable */

// @ts-ignore
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';

/*eslint-enable */

/**
 * local Pdf provider
 * splitted oly for performance reason
 * DO not include to the root of application
 */
export const PDFViewerProvider: FC = ({ children }) => {
  return <Worker workerUrl={pdfjsWorker}>{children}</Worker>;
};
