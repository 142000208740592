import { MenuProps, components } from 'react-select';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import classes from './index.module.scss';

export const HeaderStepperMenu = (props: MenuProps<StepIndicatorObject>) => {
  return (
    <components.Menu {...props} className={classes['header-menu']}>
      {props.children}
    </components.Menu>
  );
};
