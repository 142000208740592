import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { AQString } from 'src/shared/AQString';
import { ProfileCompetitionContentProps, StepCardProps } from '../types';
import {
  HOME_PAGE,
  FOUNDER_PERSONAL_DATA_PAGE,
  FOUNDER_IDENTIFICATION_PAGE,
  FOUNDER_VENTURE_SELECTION_PAGE,
} from 'src/constants/paths';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { User, UserIdentificationStatus } from 'src/interfaces/user';
import { contactSupportHandler, fiveSeconds, tenMin } from '../shared';
import { useSaveIdentificationDetails } from 'src/queries/users/user-identification-details.query';
import { RegistrationStep } from '../components/step';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { mixpanelEvents, useMixpanel } from 'src/hooks/useMixpanel';

export const isPersonalDataIsFilled = (user: User): boolean =>
  [user.firstName, user.lastName, user.gender, user.title].every(Boolean);

export const hasVerifiedVenture = (user: User) =>
  user.userVentureRelations?.some((e) => e.verified);

export const identificationIsPassed = (user: User) =>
  user.identificationDetails?.status === UserIdentificationStatus.SUCCESS;

type FounderRegistrationStage = 'PERSONAL_DATA' | 'IDENTIFICATION' | 'VENTURE_SELECTION';

export const FounderRegistration = ({ user, refetch }: ProfileCompetitionContentProps) => {
  const { track } = useMixpanel();
  const { saveIdentification } = useSaveIdentificationDetails();
  const [showRetryButton, setShowRetryButton] = useState(false);
  const initialRefetchRef = useRef(false);

  const { push } = useRouter();

  const activeStage = useMemo<FounderRegistrationStage>(() => {
    if (!isPersonalDataIsFilled(user)) return 'PERSONAL_DATA';

    const isIdentificationIsFinished =
      user?.identificationDetails?.status === UserIdentificationStatus.SUCCESS;
    if (!isIdentificationIsFinished) return 'IDENTIFICATION';
    return 'VENTURE_SELECTION';
  }, [user]);

  const personalDataStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'PERSONAL_DATA') {
      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.registration.personal-data.subtitle',
      };
    }
    return { status: 'COMPLETED', showSubtitle: false };
  }, [activeStage]);

  const identificationStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'VENTURE_SELECTION') return { status: 'COMPLETED', showSubtitle: false };

    if (activeStage === 'IDENTIFICATION') {
      if (user.identificationDetails?.status === UserIdentificationStatus.FAILED) {
        return {
          status: 'FAILED',
          subtitle: 'aquaty-app.profile-completion.founder.identification.failed.subtitle',
        };
      }

      if (user.identificationDetails?.status === UserIdentificationStatus.PENDING) {
        return {
          status: 'WAITING',
          subtitle: 'aquaty-app.profile-completion.founder.identification.waiting.subtitle',
        };
      }

      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.founder.identification.not-started.subtitle',
      };
    }

    return {
      status: 'TODO',
      subtitle: 'aquaty-app.profile-completion.founder.identification.not-started.subtitle',
      showSubtitle: false,
    };
  }, [activeStage, user]);

  const ventureSelectionStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'VENTURE_SELECTION') {
      if (user.userVentureRelations?.length === 0) {
        return {
          status: 'INPROGRESS',
          subtitle: 'aquaty-app.profile-completion.founder.venture-selection.not-started.subtitle',
        };
      }

      if (hasVerifiedVenture(user)) return { status: 'COMPLETED', showSubtitle: false };

      const hasWaitingVenture = user.userVentureRelations?.some((e) => e.verified === null);
      if (hasWaitingVenture) {
        return {
          status: 'WAITING',
          subtitle: 'aquaty-app.profile-completion.founder.venture-selection.waiting.subtitle',
        };
      }

      // if all ventures with verified status = false
      return {
        status: 'FAILED',
        subtitle: 'aquaty-app.profile-completion.founder.venture-selection.failed.subtitle',
      };
    }

    return {
      status: 'TODO',
      subtitle: 'aquaty-app.profile-completion.founder.venture-selection.not-started.subtitle',
      showSubtitle: false,
    };
  }, [activeStage, user]);

  const goToPersonalData = useCallback(() => {
    push(FOUNDER_PERSONAL_DATA_PAGE);
  }, [push]);

  const goToIdentification = useCallback(() => {
    push(FOUNDER_IDENTIFICATION_PAGE);
  }, [push]);

  const goToVentureSelection = useCallback(() => {
    push(FOUNDER_VENTURE_SELECTION_PAGE);
  }, [push]);

  useEffect(() => {
    let identificationInterval: any = undefined;
    let userInterval: any = undefined;

    if (user?.identificationDetails?.status === UserIdentificationStatus.PENDING) {
      setShowRetryButton(true);
      if (!initialRefetchRef.current) {
        initialRefetchRef.current = true;
        saveIdentification();
      }

      identificationInterval = setInterval(() => {
        saveIdentification();
      }, tenMin);
      userInterval = setInterval(() => refetch(), fiveSeconds);
    } else {
      clearInterval(identificationInterval);
      clearInterval(userInterval);
    }

    return () => {
      clearInterval(identificationInterval);
      clearInterval(userInterval);
    };
  }, [user, saveIdentification, refetch]);

  useEffect(() => {
    if (isPersonalDataIsFilled(user) && identificationIsPassed(user) && hasVerifiedVenture(user)) {
      track(mixpanelEvents.fullProfileCompletion, {
        ['Role']: 'venture_founder',
      });
      push(HOME_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, push]);

  const retryIdentification = useCallback(() => {
    push({
      pathname: FOUNDER_IDENTIFICATION_PAGE,
      query: { retry: true },
    });
  }, [push]);

  return (
    <div className="grid-row">
      <div className="grid-row xs">
        <ServerSharedText type="header">
          <AQString componentId="aquaty-app.profile-completion.founder.title" />
        </ServerSharedText>

        <ServerSharedText color="grey500" as="pre">
          <AQString
            componentId="aquaty-app.profile-completion.founder.subtitle"
            variables={{ email: user.email || '' }}
          />
        </ServerSharedText>
      </div>

      <div className="grid-row m">
        <RegistrationStep
          title="aquaty-app.registration.personal-data.title"
          {...personalDataStep}
          index={1}
          button={
            activeStage === 'PERSONAL_DATA' ? (
              <ServerSharedButton
                onClick={goToPersonalData}
                label={<AQString componentId="aquaty-app.profile-completion.start-personal-data" />}
                variant="inform"
              />
            ) : null
          }
        />

        <RegistrationStep
          title="aquaty-app.profile-completion.founder.identification.title"
          {...identificationStep}
          index={2}
          button={
            activeStage === 'IDENTIFICATION' ? (
              <>
                {(!user?.identificationDetails ||
                  user?.identificationDetails?.status === UserIdentificationStatus.NOT_STARTED) && (
                  <ServerSharedButton
                    onClick={goToIdentification}
                    label={
                      <AQString componentId="aquaty-app.profile-completion.start-identification" />
                    }
                    variant="inform"
                  />
                )}

                {user?.identificationDetails?.status === UserIdentificationStatus.PENDING && (
                  <div className={showRetryButton ? 'grid-column template-mc-mc' : 'grid-column'}>
                    <ServerSharedButton
                      style={{ width: 177, padding: '11px 0' }}
                      onClick={contactSupportHandler}
                      label={<AQString componentId="aquaty-generic.menu.contact-support.title" />}
                      variant="secondary"
                    />
                    {showRetryButton && (
                      <ServerSharedButton
                        style={{ width: 207, padding: '11px 0' }}
                        onClick={retryIdentification}
                        label={
                          <AQString componentId="aquaty-app.profile-completion.retry-identification" />
                        }
                        variant="inform"
                      />
                    )}
                  </div>
                )}

                {user?.identificationDetails?.status === UserIdentificationStatus.FAILED && (
                  <div className="grid-column template-mc-mc">
                    <ServerSharedButton
                      style={{ width: 177, padding: '11px 0' }}
                      onClick={contactSupportHandler}
                      label={<AQString componentId="aquaty-generic.menu.contact-support.title" />}
                      variant="secondary"
                    />
                    <ServerSharedButton
                      style={{ width: 207, padding: '11px 0' }}
                      onClick={goToIdentification}
                      label={
                        <AQString componentId="aquaty-app.profile-completion.retry-identification" />
                      }
                      variant="inform"
                    />
                  </div>
                )}
              </>
            ) : null
          }
        />
        <RegistrationStep
          title="aquaty-app.profile-completion.founder.venture-selection.title"
          {...ventureSelectionStep}
          index={3}
          button={
            activeStage === 'VENTURE_SELECTION' ? (
              <>
                {['FAILED', 'WAITING'].includes(ventureSelectionStep.status) && (
                  <div className="grid-column template-mc-mc">
                    <ServerSharedButton
                      style={{ width: 177, padding: '11px 0' }}
                      onClick={contactSupportHandler}
                      label={<AQString componentId="aquaty-generic.menu.contact-support.title" />}
                      variant="secondary"
                    />
                    <ServerSharedButton
                      style={{ width: 207, padding: '11px 0' }}
                      onClick={goToVentureSelection}
                      label="Add Another Venture"
                      variant="inform"
                    />
                  </div>
                )}
                {ventureSelectionStep.status === 'INPROGRESS' && (
                  <ServerSharedButton
                    onClick={goToVentureSelection}
                    label={
                      <AQString componentId="aquaty-app.profile-completion.start-venture-selection" />
                    }
                    variant="inform"
                  />
                )}
              </>
            ) : null
          }
        />
      </div>
    </div>
  );
};
