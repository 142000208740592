import { DataRoom } from 'src/interfaces/data-room';
import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { fileFields, ventureDocument } from '../common.strings';
import { teamGQLString } from './team-member.query';
import { previousFinancingEventsGQLString } from './previous-financings.query';
import { productAndDemoGQLString } from './product-and-demo.query';
import { competitiveLandscapeGQLString } from './competitive-landscape.query';
import { currentTractionGQLString } from './current-traction.query';
import { intellectualPropertyGQLString } from './intellectual-property-assets.query';
import { marketAndDistributionStrategyGQLString } from './market-distribution-strategy.query';
import { growthPlanGQLString } from './business-model-growth-plan.query';
import { financialsGQLString } from './financials.query';

const dataRoomQueryById = gql`
  query dataRoom($equityOfferingId: Long!) {
    dataRoom(equityOfferingId: $equityOfferingId) {
      id
      competitiveLandscape {
        ${competitiveLandscapeGQLString}
      }
      currentTraction {
        ${currentTractionGQLString}
      }
      extendedPitchDeck {
        ${fileFields}   
      }
      productAndDemo {
        ${productAndDemoGQLString}
      }
      intellectualProperty {
        ${intellectualPropertyGQLString}
      }
      completedStates
      synchronisedVentureDocuments {
        ${ventureDocument}
      }
      teamMembers {
        ${teamGQLString}
      }
      uploadedVentureDocuments {
        ${ventureDocument}
      }
      previousFinancingEvents {
        ${previousFinancingEventsGQLString}
      }
      marketAndDistributionStrategy {
        ${marketAndDistributionStrategyGQLString}
      }
      growthPlan {
        ${growthPlanGQLString}
      }
      financials {
        ${financialsGQLString}
      }
      additionalInformation {
        additionalInformationDescription
        otherFiles {
          ${fileFields}  
        }
      }
      equityOffering {
        venture {
          id
        }
      }
    }
  }
`;

export function useGetDataRoomSetup(id: number, params?: QueryHookOptions) {
  const { data, loading, refetch } = useQuery<{ dataRoom: DataRoom }>(dataRoomQueryById, {
    variables: { equityOfferingId: id },
    fetchPolicy: 'no-cache',
    ...params,
  });

  return {
    dataRoom: data?.dataRoom || null,
    loading,
    refetch,
  };
}
