import { Roles, User } from './user';
import {
  Currency,
  EquityOfferingInvestmentCommitment,
  InitializationType,
  VentureBusinessCategory,
  VentureStage,
} from './common';
import { Venture } from './venture';
import { FileField } from './common';
import { DataRoomEvaluationSteps } from './data-room-evaluation';
import { VentureInvestmentOfferingOpex, VenturePitchSteps } from './venture-pitch';
import { PotentialInvestmentAmountRange } from 'src/ui/auth/signup/interfaces';

// PLEASE USE ONLY THIS INTERFACE FOR DEFINE ANY EquityOffering's
export interface EquityOffering {
  id: number;
  tasks: EquityOfferingState[];
  activeStage: EquityOfferingStageEnum;
  type: EquityOfferingTypeEnum;
  name: string | null;
  closingDate?: string;
  venture?: Pick<Venture, 'id' | 'logo' | 'name' | 'headerImage'>;
  status: EquityOfferingStatus;
  investmentCommitments?: EquityOfferingInvestmentCommitment[];
  requiredInvestmentAmount?: number;
  maxInvestmentAmount?: number;
  preMoneyValuationFounderIndication?: number;
  createdAt?: string;
  releaseDate: string | null;
  clubId: number | null;
  committedFundsAmount: number | null;
  coverImage: FileField | null;
  dealSpvId: number | null;
  targetsCount: number | null;
  tokenSymbol: string;
}

export enum EquityOfferingTaskStatusEnum {
  'COMPLETED' = 'COMPLETED',
  'INSUFFICIENT' = 'INSUFFICIENT',
  'LOCKED' = 'LOCKED',
  'TO_COMPLETE' = 'TO_COMPLETE',
  'TO_REWORK' = 'TO_REWORK',
  'WAITING' = 'WAITING',
  'SKIPPED' = 'SKIPPED',
}

export enum EquityOfferingStageEnum {
  'BASIC_OFFERING_REQUIREMENTS' = 'BASIC_OFFERING_REQUIREMENTS',
  'CLOSING' = 'CLOSING',
  'DEAL_TEAM_SETUP' = 'DEAL_TEAM_SETUP',
  'DUE_DILIGENCE' = 'DUE_DILIGENCE',
  'DUE_DILIGENCE_PREPARATION' = 'DUE_DILIGENCE_PREPARATION',
  'DUE_DILIGENCE_REVIEW' = 'DUE_DILIGENCE_REVIEW',
  'EQUITY_OFFERING_RELEASE' = 'EQUITY_OFFERING_RELEASE',
  'FOUNDERS_MEETING' = 'FOUNDERS_MEETING',
  'PRE_SCREENING' = 'PRE_SCREENING',
  'SIGNING' = 'SIGNING',
  'VENTURE_INVESTMENT_MANAGER_MATCHING' = 'VENTURE_INVESTMENT_MANAGER_MATCHING',
  'VENTURE_INVESTOR_MATCHING' = 'VENTURE_INVESTOR_MATCHING',
}

export enum EquityOfferingTaskEnum {
  'ACCEPT_EXPERT_VENTURE_PARTNER_ASSIGNMENT' = 'ACCEPT_EXPERT_VENTURE_PARTNER_ASSIGNMENT',
  'ACCEPT_GENERAL_VENTURE_PARTNER_ASSIGNMENT' = 'ACCEPT_GENERAL_VENTURE_PARTNER_ASSIGNMENT',
  'ACCEPT_INVESTMENT_COMMITMENT' = 'ACCEPT_INVESTMENT_COMMITMENT',
  'ACCEPT_INVESTMENT_MANAGER_ASSIGNMENT' = 'ACCEPT_INVESTMENT_MANAGER_ASSIGNMENT',
  'ACCEPT_VENTURE_PARTNER_ASSIGNMENT' = 'ACCEPT_VENTURE_PARTNER_ASSIGNMENT',
  'APPLY_AS_INVESTMENT_MANAGER' = 'APPLY_AS_INVESTMENT_MANAGER',
  'APPROVE_EQUITY_OFFERING_FOR_RELEASE' = 'APPROVE_EQUITY_OFFERING_FOR_RELEASE',
  'APPROVE_PUBLIC_EQUITY_OFFERING_ACCESS' = 'APPROVE_PUBLIC_EQUITY_OFFERING_ACCESS',
  'ASSIGN_DEAL_TEAM' = 'ASSIGN_DEAL_TEAM',
  'ASSIGN_VENTURE_FOUNDER' = 'ASSIGN_VENTURE_FOUNDER',
  'CAP_TABLE_SETUP' = 'CAP_TABLE_SETUP',
  'CHOOSE_FOUNDERS_MEETING_TIME_SLOT' = 'CHOOSE_FOUNDERS_MEETING_TIME_SLOT',
  'CLOSE_EQUITY_OFFERING' = 'CLOSE_EQUITY_OFFERING',
  'CONFIRM_FOUNDERS_MEETING_COMPLETION' = 'CONFIRM_FOUNDERS_MEETING_COMPLETION',
  'CONFIRM_INVESTMENT_COMMITMENT_PAYMENT' = 'CONFIRM_INVESTMENT_COMMITMENT_PAYMENT',
  'DATA_ROOM_SETUP' = 'DATA_ROOM_SETUP',
  'DUE_DILIGENCE_EXECUTE_EXPERT_DATA_ROOM_EVALUATION' = 'DUE_DILIGENCE_EXECUTE_EXPERT_DATA_ROOM_EVALUATION',
  'DUE_DILIGENCE_EXECUTE_GENERAL_DATA_ROOM_EVALUATION' = 'DUE_DILIGENCE_EXECUTE_GENERAL_DATA_ROOM_EVALUATION',
  'DUE_DILIGENCE_RESPONSIBILITY_ORGANIZATION_AND_PARTICIPATION' = 'DUE_DILIGENCE_RESPONSIBILITY_ORGANIZATION_AND_PARTICIPATION',
  'DUE_DILIGENCE_REVIEW_EVALUATION_AND_COMPLETE' = 'DUE_DILIGENCE_REVIEW_EVALUATION_AND_COMPLETE',
  'EXTENDED_DATA_ROOM_ACCESS_GRANTED' = 'EXTENDED_DATA_ROOM_ACCESS_GRANTED',
  'GRANT_DEAL_ROOM_ACCESS' = 'GRANT_DEAL_ROOM_ACCESS',
  'INVESTMENT_MEMORANDUM_SETUP' = 'INVESTMENT_MEMORANDUM_SETUP',
  'INVESTMENT_OFFERING_SETUP' = 'INVESTMENT_OFFERING_SETUP',
  'LEAVE_THE_DEAL_ROOM' = 'LEAVE_THE_DEAL_ROOM',
  'MINIMUM_FUNDING_AMOUNT_COMMITTED' = 'MINIMUM_FUNDING_AMOUNT_COMMITTED',
  'MINT_INVESTOR_TOKENS' = 'MINT_INVESTOR_TOKENS',
  'NOTARIZE_EQUITY_CONTRACTS' = 'NOTARIZE_EQUITY_CONTRACTS',
  'PROVIDE_SHAREHOLDER_CONTRACTS' = 'PROVIDE_SHAREHOLDER_CONTRACTS',
  'RECEIVE_DEAL_TEAM_ASSIGNMENT' = 'RECEIVE_DEAL_TEAM_ASSIGNMENT',
  'RECEIVE_DUE_DILIGENCE_REVIEW_COMPLETION' = 'RECEIVE_DUE_DILIGENCE_REVIEW_COMPLETION',
  'RECEIVE_EQUITY_OFFERING_CLOSING_CONFIRMATION' = 'RECEIVE_EQUITY_OFFERING_CLOSING_CONFIRMATION',
  'RECEIVE_FOUNDERS_COMMITMENT_ACCEPTANCE' = 'RECEIVE_FOUNDERS_COMMITMENT_ACCEPTANCE',
  'RECEIVE_FOUNDERS_MEETING_COMPLETION_CONFIRMATION' = 'RECEIVE_FOUNDERS_MEETING_COMPLETION_CONFIRMATION',
  'RECEIVE_INVESTMENT_COMMITMENT_PAYMENT_CONFIRMATION' = 'RECEIVE_INVESTMENT_COMMITMENT_PAYMENT_CONFIRMATION',
  'RECEIVE_INVESTMENT_MANAGER_APPLICATION' = 'RECEIVE_INVESTMENT_MANAGER_APPLICATION',
  'RECEIVE_INVESTMENT_TOKENS' = 'RECEIVE_INVESTMENT_TOKENS',
  'RECEIVE_MINIMUM_FUNDING_AMOUNT_COMMITMENT_CONFIRMATION' = 'RECEIVE_MINIMUM_FUNDING_AMOUNT_COMMITMENT_CONFIRMATION',
  'RECEIVE_NOTARIZED_SHAREHOLDER_CONTRACTS' = 'RECEIVE_NOTARIZED_SHAREHOLDER_CONTRACTS',
  'RECEIVE_PRE_SCREENING_FEEDBACK' = 'RECEIVE_PRE_SCREENING_FEEDBACK',
  'RECEIVE_TRANSFER_FUNDING_TO_VENTURE_CONFIRMATION' = 'RECEIVE_TRANSFER_FUNDING_TO_VENTURE_CONFIRMATION',
  'REGISTER_TO_FOUNDERS_MEETING' = 'REGISTER_TO_FOUNDERS_MEETING',
  'REGULATORY_APPROVAL' = 'REGULATORY_APPROVAL',
  'RELEASE_EQUITY_OFFERING' = 'RELEASE_EQUITY_OFFERING',
  'REQUEST_DEAL_ROOM_ACCESS' = 'REQUEST_DEAL_ROOM_ACCESS',
  'REQUEST_EXTENDED_DATA_ROOM_ACCESS' = 'REQUEST_EXTENDED_DATA_ROOM_ACCESS',
  'REQUEST_PUBLIC_EQUITY_OFFERING_ACCESS' = 'REQUEST_PUBLIC_EQUITY_OFFERING_ACCESS',
  'REVIEW_DATA_ROOM' = 'REVIEW_DATA_ROOM',
  'REVIEW_TERM_SHEET' = 'REVIEW_TERM_SHEET',
  'SIGN_INVESTMENT_COMMITMENT' = 'SIGN_INVESTMENT_COMMITMENT',
  'SPV_ISSUANCE_SIGNING' = 'SPV_ISSUANCE_SIGNING',
  'SUBMIT_PRE_SCREENING_FEEDBACK' = 'SUBMIT_PRE_SCREENING_FEEDBACK',
  'TARGET_EQUITY_OFFERING' = 'TARGET_EQUITY_OFFERING',
  'TERM_SHEET_SETUP' = 'TERM_SHEET_SETUP',
  'TRANSFER_FUNDING_TO_VENTURE' = 'TRANSFER_FUNDING_TO_VENTURE',
  'VENTURE_PITCH_SETUP' = 'VENTURE_PITCH_SETUP',
  'WAIT_FOR_DATA_ROOM_SETUP' = 'WAIT_FOR_DATA_ROOM_SETUP',
  'WAIT_FOR_DUE_DILIGENCE_FEEDBACK' = 'WAIT_FOR_DUE_DILIGENCE_FEEDBACK',
  'WAIT_FOR_EQUITY_OFFERING_RELEASE' = 'WAIT_FOR_EQUITY_OFFERING_RELEASE',
  'WAIT_FOR_EQUITY_OFFERING_RELEASE_APPROVAL' = 'WAIT_FOR_EQUITY_OFFERING_RELEASE_APPROVAL',
  'WAIT_FOR_EXPERT_PARTNER_EVALUATION' = 'WAIT_FOR_EXPERT_PARTNER_EVALUATION',
  'WAIT_FOR_INVESTMENT_MANAGER_MATCH' = 'WAIT_FOR_INVESTMENT_MANAGER_MATCH',
  'WAIT_FOR_INVESTMENT_MEMORANDUM_SETUP' = 'WAIT_FOR_INVESTMENT_MEMORANDUM_SETUP',
  'WAIT_FOR_REGULATORY_APPROVAL' = 'WAIT_FOR_REGULATORY_APPROVAL',
  'WAIT_FOR_SPV_ISSUANCE_SIGNING' = 'WAIT_FOR_SPV_ISSUANCE_SIGNING',
  'WAIT_FOR_TERM_SHEET_SETUP' = 'WAIT_FOR_TERM_SHEET_SETUP',
  'WAIT_FOR_VENTURE_PARTNER_EVALUATION' = 'WAIT_FOR_VENTURE_PARTNER_EVALUATION',
}

export enum EquityOfferingTypeEnum {
  'PRIVATE' = 'PRIVATE',
  'PUBLIC' = 'PUBLIC',
}

export interface EquityOfferingState {
  relatedEquityOfferingStage: EquityOfferingStageEnum;
  status: EquityOfferingTaskStatusEnum;
  statusArgumentsJson: any;
  statusDescriptionStringId: string;
  relatedEquityOfferingStageTitleStringId: string;
  task: EquityOfferingTaskEnum;
  taskTitleStringId: string;
  executable: boolean;
  statusCtaTitleStringId?: string;
  statusTitleStringId?: string;
  userId: number | null;
  transitionDate: Date | null;
}

export interface ExtendedEquityOfferingState extends EquityOfferingState {
  statusIcon?: any;
  path?: string;
}

export interface EquityOfferingValuation {
  avgPreMoneyValuationInvestorIndication: number;
  equityOfferingId: number;
  preMoneyValuationFounderIndication: number;
  primaryCurrency: Currency;
  totalInterestedInvestors: number;
}

export enum EquityOfferingTaskStatus {
  'COMPLETED' = 'COMPLETED',
  'INSUFFICIENT' = 'INSUFFICIENT',
  'LOCKED' = 'LOCKED',
  'TO_COMPLETE' = 'TO_COMPLETE',
  'TO_REWORK' = 'TO_REWORK',
  'WAITING' = 'WAITING',
}

export interface EquityOfferingParticipants {
  equityOfferingId: number;
  id: number;
  participantAccreditationDetails: {
    linkedInUrl: string;
    user: User;
    cv: FileField;
  };
  participationRole: Roles;
  carryParticipation: number;
  comment: string;
  filteredEvaluationSections: DataRoomEvaluationSteps[];
  participantId: number;
  acceptanceStatus: EquityOfferingTaskStatus;
}

export enum EquityOfferingStatus {
  'ACTIVE' = 'ACTIVE',
  'COMPLETED' = 'COMPLETED',
  'CANCELED' = 'CANCELED',
  'CLOSED' = 'CLOSED',
}
export interface EquityOfferingOverview {
  equityOfferingId: number;
  equityOfferingName: string;
  equityOfferingStage: EquityOfferingStageEnum;
  ventureLogo: FileField;
  ventureName: string;
  requiresAdminAction: boolean;
}

export interface PreScreeningParticipation {
  comment: string;
  equityOfferingId: number;
  highlights: VenturePitchSteps[];
  potentialInvestmentAmountRange: PotentialInvestmentAmountRange | null;
  interested: boolean;
  voteDateTime: Date;
}

export interface EquityOfferingSigning {
  brokerageFeeRate: number;
  equityOfferingId: number;
  hurdleRate: number;
  id: number;
  investmentAccountId: number;
  investorId: number;
  performanceFeeRate: number;
  signedTokenAmount: number;
  signingDate: string;
  status: EquityOfferingSigningStatus;
  tokenUnitPrice: number;
}

export enum EquityOfferingSigningStatus {
  'CONFIRMED_BY_INVESTOR' = 'CONFIRMED_BY_INVESTOR',
  'INVESTMENT_ACCOUNT_DEFINED' = 'INVESTMENT_ACCOUNT_DEFINED',
  'INVESTMENT_AMOUNT_DEFINED' = 'INVESTMENT_AMOUNT_DEFINED',
  'PAYMENT_CONFIRMED' = 'PAYMENT_CONFIRMED',
}

export interface UserInvestmentAccountOverview {
  holdingName: string | null;
  userFirstName: string;
  userInvestmentAccountId: number;
  userLastName: string;
}

export interface PendingUsersInvitations {
  createdAt: string;
  email: string;
  equityOfferingId: number;
  grantDataRoomAccess: boolean;
  grantDealRoomAccess: boolean;
  invitedBy: number;
  userId: number;
}

export enum RevisableStatus {
  ACCEPTED = 'ACCEPTED',
  NOT_STARTED = 'NOT_STARTED',
  REFUSED = 'REFUSED',
  WAITING = 'WAITING',
}

export interface ConcedusProductImport {
  productId: string;
  response: string;
  status: RevisableStatus;
}

export interface EquityOfferingProposals {
  content: EquityOffering[];
  pageNumber: number;
  pageSize: number;
  totalCount: number;
}

export interface EquityOfferingProposalsOverview {
  businessCategoriesNumber: Record<VentureBusinessCategory, number>;
  fundingStagesNumber: Record<VentureStage, number>;
  industriesNumber: Record<number, number>;
  maxValuation: number;
  minValuation: number;
  totalCount: number;
}

export interface InvestmentMemorandum {
  id: number;
  createdAt: string;
  initializationType: InitializationType;
  file: FileField;
}

export enum OptionalEquityOfferingStage {
  'PRE_SCREENING' = 'PRE_SCREENING',
  'DATA_ROOM' = 'DATA_ROOM',
  'FOUNDER_MEETING' = 'FOUNDER_MEETING',
  'DEAL_TEAM_SETUP' = 'DEAL_TEAM_SETUP',
  'INVESTMENT_MEMORANDUM' = 'INVESTMENT_MEMORANDUM',
}

export enum InvestmentOfferingState {
  'CARD' = 'CARD',
  'EXECUTIVE_SUMMARY' = 'EXECUTIVE_SUMMARY',
  'INVESTMENT_OFFERING' = 'INVESTMENT_OFFERING',
}

export interface InvestmentOffering {
  completedStates: InvestmentOfferingState[];
  equityOfferingId: number;
  fundingCommitments: EquityOfferingInvestmentCommitment[];
  fundingStage: VentureStage;
  maxInvestmentAmount: number;
  opexes: VentureInvestmentOfferingOpex[];
  preMoneyValuationFounderIndication: number;
  requiredInvestmentAmount: number;
  runaway: string;
}

export interface InvestmentOfferingForAPI {
  completedStates: InvestmentOfferingState[];
  equityOfferingId: number;
  fundingCommitments: EquityOfferingInvestmentCommitment[];
  fundingStage: VentureStage;
  maxInvestmentAmount: number | null;
  opexes: VentureInvestmentOfferingOpex[];
  preMoneyValuationFounderIndication: number | null;
  requiredInvestmentAmount: number | null;
  runaway: string | null;
}
