import { useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { useRouter } from 'next/router';

import {
  getDataRoomSetupSelector,
  getVenturePitchSelector,
} from 'src/store/founder-shared-data/reducer';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { AQString } from 'src/shared/AQString';
import { EquityOfferingTaskEnum, EquityOfferingTaskStatusEnum } from 'src/interfaces/offering';
import { VENTURE_DEAL_ROOM_PAGE } from 'src/constants/paths';
import { useSaveEquityOfferingStage } from 'src/queries/offering/save-equity-offering';
import { pitchSetupSteps } from 'src/constants/venturePitch';
import { dataRoomSetupSteps } from 'src/constants/dataRoom';
import { getHeaderStepper } from 'src/store/app/reducer';

interface SubmitActionButtonProps {
  isVenturePitch?: boolean;
}

export const SubmitActionButton = ({ isVenturePitch }: SubmitActionButtonProps) => {
  const { push, query } = useRouter();
  const { steps } = useSelector(getHeaderStepper);
  const { saveEquityOfferingStage, loading } = useSaveEquityOfferingStage();
  const venturePitch = useSelector(getVenturePitchSelector);
  const dataRoom = useSelector(getDataRoomSetupSelector);

  const { ventureId, offeringId } = query;

  const disabled = useMemo(() => {
    const completedStates = steps.filter((el) => el.complete);
    if (isVenturePitch) {
      return completedStates?.length === pitchSetupSteps.length - 1;
    }
    return completedStates.length === dataRoomSetupSteps.length - 1;
  }, [isVenturePitch, steps]);

  const goToDealRoom = useCallback(() => {
    push({
      pathname: VENTURE_DEAL_ROOM_PAGE,
      query: { ventureId: Number(ventureId), offeringId: Number(offeringId) },
    });
  }, [offeringId, push, ventureId]);

  const onSubmit = useCallback(async () => {
    if (venturePitch) {
      await saveEquityOfferingStage({
        equityOfferingId: venturePitch.id,
        task: EquityOfferingTaskEnum.VENTURE_PITCH_SETUP,
        status: EquityOfferingTaskStatusEnum.COMPLETED,
      });

      goToDealRoom();
    }
  }, [goToDealRoom, saveEquityOfferingStage, venturePitch]);

  const onSubmitDataRoom = useCallback(async () => {
    if (dataRoom) {
      await saveEquityOfferingStage({
        equityOfferingId: dataRoom.id,
        task: EquityOfferingTaskEnum.DATA_ROOM_SETUP,
        status: EquityOfferingTaskStatusEnum.COMPLETED,
      });

      goToDealRoom();
    }
  }, [dataRoom, goToDealRoom, saveEquityOfferingStage]);

  if (isVenturePitch) {
    return (
      <div className="grid-row">
        <ServerSharedButton
          disabled={!disabled || loading}
          onClick={onSubmit}
          label={
            <AQString componentId="aquaty-app.equity-actions.equity-offering.venture-pitch.submit-pitch" />
          }
        />
      </div>
    );
  }

  return (
    <div className="grid-row">
      <ServerSharedButton
        disabled={!disabled || loading}
        onClick={onSubmitDataRoom}
        label={<AQString componentId="aquaty-generic.cta.complete-data-room" />}
      />
    </div>
  );
};
