import { SVGProps, memo } from 'react';

const SvgVentureSelected24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#venture-selected-24px_svg__a)">
      <path fill="#1F2937" d="M5 5h14v16H5zM18.875 5.125v16.25Z" />
      <path d="M18.875 5.125v16.25" stroke="#1F2937" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.125 5.125h13.75" stroke="#1F2937" strokeWidth={1.25} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M5.125 21.375V5.125Z" fill="#1F2937" />
      <path d="M5.125 21.375V5.125" stroke="#1F2937" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path d="M18.875 21.375H5.125" stroke="#1F2937" strokeWidth={1.25} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M18.875 5.125H5.125l1.25-2.5h11.25l1.25 2.5Z"
        fill="#1F2937"
        stroke="#1F2937"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M2.625 21.375h18.75" stroke="#1F2937" strokeWidth={1.5} strokeLinecap="round" strokeLinejoin="round" />
      <path
        d="M13.875 19.5a1.875 1.875 0 0 0-3.75 0v1.875h3.75V19.5Z"
        fill="#fff"
        stroke="#1F2937"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.25 7.938a.312.312 0 1 1 0-.625M8.25 7.938a.312.312 0 1 0 0-.625M12 7.938a.312.312 0 1 1 0-.625M12 7.938a.312.312 0 1 0 0-.625M15.75 7.938a.312.312 0 1 1 0-.625M15.75 7.938a.312.312 0 1 0 0-.625M8.25 11.688a.312.312 0 1 1 0-.626M8.25 11.688a.312.312 0 1 0 0-.626M12 11.688a.312.312 0 1 1 0-.626M12 11.688a.312.312 0 1 0 0-.626M15.75 11.688a.312.312 0 1 1 0-.626M15.75 11.688a.312.312 0 1 0 0-.626M8.25 15.438a.312.312 0 1 1 0-.626M8.25 15.438a.312.312 0 1 0 0-.626M12 15.438a.312.312 0 1 1 0-.626M12 15.438a.312.312 0 1 0 0-.626M15.75 15.438a.312.312 0 1 1 0-.626M15.75 15.438a.312.312 0 1 0 0-.626"
        stroke="#fff"
        strokeWidth={1.5}
      />
    </g>
    <defs>
      <clipPath id="venture-selected-24px_svg__a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgVentureSelected24Px);
export default Memo;
