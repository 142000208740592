import { AQString } from 'src/shared/AQString';
import { ServerSharedFeedbackModal } from 'src/server-shared/modals/feedback';
import { GeneralModalProps } from 'src/server-shared/modals/modal/types';

interface ConfirmationModalProps extends GeneralModalProps {
  onConfirm: () => void;
}

export const ConfirmationModal = ({ show, closeEvent, onConfirm }: ConfirmationModalProps) => {
  return (
    <ServerSharedFeedbackModal
      type="alert"
      show={show}
      closeEvent={closeEvent}
      title={<AQString componentId="aquaty-generic.confirm-modal.title" />}
      subtitle={<AQString componentId="aquaty-generic.confirm-modal.description" />}
      footer={{
        onConfirm,
        hasLeftButton: true,
        buttonVariant: 'disapprove',
        leftBtnText: 'aquaty-generic.cta.no',
        buttonText: 'aquaty-generic.confirm-modal.button-yes',
      }}
    />
  );
};
