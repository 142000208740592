export const fileFields = `
fileName
fileSize
id
mediaType
previews
s3FileKey
s3Region
s3Uri
downloadUrl
`;

export const linkFields = `
index
title
url
`;

export const ventureDocument = `
description
document {
  ${fileFields}
}
id
title
uploadDate
`;

export const investmentOfferingOpex = `
amount
id
name
`;

export const offeringTask = `
task
taskTitleStringId
relatedEquityOfferingStage
status
statusDescriptionStringId
relatedEquityOfferingStageTitleStringId
statusArgumentsJson
executable
statusCtaTitleStringId
statusTitleStringId
userId
transitionDate
`;

export const equityOfferingGQLString = `
id
activeStage
name
tasks {
  ${offeringTask}
}
status
closingDate
committedFundsAmount
maxInvestmentAmount
investmentCommitments {
  amount
  comment
  id
  investorImage {
    ${fileFields}
  }
  investorName
  linkedInUrl
  websiteUrl
  signingDate
  investedViaPlatform
  paymentConfirmed
}
tokenSymbol
preMoneyValuationFounderIndication
requiredInvestmentAmount
targetsCount
type
createdAt
releaseDate
clubId
coverImage {
  ${fileFields}
}
dealSpvId
venture {
  id
}
`;

export const industriesGQLFields = `
id
nameStringId
popularity
`;

export const beneficialOwnerQueryFields = `
  birthDate
  birthPlace
  capitalMarketExperience
  capitalMarketExperienceType
  city
  country
  email
  firstName
  holdingRole
  id
  identificationStatus
  identId
  identificationLink
  lastName
  nationality
  otherCapitalMarketExperienceType
  phoneNumber
  phoneNumberCountryCode
  previousTokenizationExperience
  regulatoryConfirmations
  street
  tokenizationExperiencePeriod
  tokenizationInvestmentAmount
  tokenizationInvestmentFrequency
  updatedAt
  zip
  middleName
`;

export const transactionGQLFields = `
  amount
  contractId
  createdAt
  equityTransactionType
  fromAddress
  id
  processingState
  toAddress
  txn
  unitPrice
  unitPriceCurrency
  ventureId
`;
