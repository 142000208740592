/* eslint-disable prefer-rest-params */
import * as React from 'react';

function useLocalStorage(key: string) {
  const [storedValue, setValue] = React.useState(
    process.browser ? localStorage.getItem(key) : null,
  );

  const setLocalItem = React.useCallback(() => {
    /** local storage update is not that fast */
    /** it makes sure that we are getting the new value  */
    setTimeout(() => {
      const itemValueFromStorage = localStorage.getItem(key);
      setValue(itemValueFromStorage);
    }, 50);
  }, [key]);

  const setStoredValue = (value: string | object) => {
    const parsedValue = typeof value === 'object' ? JSON.stringify(value) : value;
    localStorage.setItem(key, parsedValue);
  };

  React.useEffect(() => {
    const originalSetItem = localStorage.setItem;
    localStorage.setItem = function (key, value) {
      const event = new Event('storageChange');
      document.dispatchEvent(event);
      originalSetItem.apply(this, [key, value]);
    };
    const originalRemoveItem = localStorage.removeItem;
    localStorage.removeItem = function (key) {
      const event = new Event('storageChange');
      document.dispatchEvent(event);
      originalRemoveItem.apply(this, [key]);
    };

    document.addEventListener('storageChange', setLocalItem, false);

    return () => document.removeEventListener('storageChange', setLocalItem);
  }, [setLocalItem]);

  return { storedValue, setStoredValue };
}

export default useLocalStorage;
