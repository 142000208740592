import { SVGProps, memo } from 'react';

const SvgSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.227 13.13a7.552 7.552 0 1 0 13.9-5.907 7.552 7.552 0 0 0-13.9 5.908v0ZM15.518 15.517l5.857 5.858"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgSearch);
export default Memo;
