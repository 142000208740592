import { useCallback } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { fileFields } from '../../common.strings';
import { VentureElevatorPitch, VentureElevatorPitchRequest } from 'src/interfaces/venture-pitch';
import { venturePitchTeamGQLString } from './team';

export const venturePitchElevatorGQLString = `
speakers {
  ${venturePitchTeamGQLString}
}
video {
  ${fileFields}
}
`;

const venturePitchElevatorQuery = gql`
  query venturePitchElevatorPitch($equityOfferingId: Long!) {
    venturePitchElevatorPitch(equityOfferingId: $equityOfferingId) {
      ${venturePitchElevatorGQLString}
    }
  }
`;

const venturePitchElevatorMutation = gql`
  mutation saveVenturePitchElevatorPitch(
    $venturePitchElevatorPitch: VenturePitchElevatorPitchInput
  ) {
    saveVenturePitchElevatorPitch(venturePitchElevatorPitch: $venturePitchElevatorPitch) {
      ${venturePitchElevatorGQLString}
    }
  }
`;

type UseGetVenturePitchElevatorPitch = { venturePitchElevatorPitch: VentureElevatorPitch };

export const useGetVenturePitchElevatorPitch = (
  id?: number,
  onCompleted?: (data: UseGetVenturePitchElevatorPitch) => void,
  params?: QueryHookOptions,
) => {
  const { data, loading } = useQuery<UseGetVenturePitchElevatorPitch>(venturePitchElevatorQuery, {
    variables: { equityOfferingId: id },
    onCompleted,
    fetchPolicy: 'no-cache',
    ...params,
  });
  return { venturePitchElevatorPitch: data?.venturePitchElevatorPitch, loading };
};

type UseSaveVenturePitchElevatorPitchMutation = {
  venturePitchElevatorPitch: VentureElevatorPitchRequest;
};

export const useSaveVenturePitchElevatorPitch = () => {
  const [mutateVenturePitchElevatorPitch, { loading, data }] = useMutation<
    { saveVenturePitchElevatorPitch: VentureElevatorPitch },
    UseSaveVenturePitchElevatorPitchMutation
  >(venturePitchElevatorMutation);

  const saveVenturePitchElevatorPitch = useCallback(
    async (variables: UseSaveVenturePitchElevatorPitchMutation) => {
      await mutateVenturePitchElevatorPitch({ variables });
    },
    [mutateVenturePitchElevatorPitch],
  );
  return {
    saveVenturePitchElevatorPitch,
    loading,
    venturePitchElevatorPitch: data?.saveVenturePitchElevatorPitch,
  };
};
