import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { InboundMessage } from 'src/interfaces/common';
import { inboundMessagesGQLString } from '../users/get-user-inbound-messages';

export const clubInboundMessagesQuery = gql`
  query ($statuses: [InboundMessageStatus]) {
    clubInboundMessages(statuses: $statuses) {
      ${inboundMessagesGQLString}
    }
  }
`;

export const useGetClubInboundMessages = (params?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useQuery<{ clubInboundMessages: InboundMessage[] }>(
    clubInboundMessagesQuery,
    params,
  );

  return {
    clubInboundMessages: data?.clubInboundMessages || [],
    loading,
    error,
    refetch,
  };
};
