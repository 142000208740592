import { useEffect, useState } from 'react';

export const getPercentage = (partial: number, total: number): number => {
  if (!total) return 0;
  const perS = (100 * partial) / total;
  return perS > 100 ? 100 : perS;
};

export const usePercentage = (current: number, expected: number) => {
  const [percentage, setPercentage] = useState(0);
  useEffect(() => {
    const res = getPercentage(current, expected);
    return setPercentage(res);
  }, [current, expected]);

  return {
    percentage,
  };
};
