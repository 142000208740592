import { gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { UserIdentification } from 'src/interfaces/user';
import { userQuery } from './get-user.query';

export const identificationDetailsGQLString = `
  status
  identId
  identificationLink
  updatedAt
`;

const identificationMutation = gql`
  mutation saveUserIdentificationDetails(
    $identificationDetails: UserIdentificationInput
  ) {
    saveUserIdentificationDetails(identificationDetails: $identificationDetails) {
      ${identificationDetailsGQLString}
    }
  }
`;

export const useSaveIdentificationDetails = () => {
  const [mutationHandler, { loading, data }] = useMutation<UserIdentification>(
    identificationMutation,
    {
      refetchQueries: [userQuery],
    },
  );

  const saveIdentification = useCallback(
    (values = {}) => {
      mutationHandler({ variables: values });
    },
    [mutationHandler],
  );

  return {
    saveIdentification,
    loading,
    userIdentification: data,
  };
};
