import { Ditto } from 'ditto-react';
import { DittoProps } from 'ditto-react/dist/components/Ditto';

const updateVariables = (values: { [key: string]: any }) => {
  return Object.keys(values).reduce(
    (attrs, key) => ({
      ...attrs,
      [key]: values[key] === 0 ? '0' : values[key],
    }),
    {},
  );
};

export const AQString = ({ ...props }: DittoProps) => {
  return <Ditto {...props} variables={updateVariables(props.variables || {})} />;
};
