import { SVGProps, memo } from 'react';

const SvgChevronRight = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#chevron-right_svg__a)">
      <path
        d="M11.75 8c0 .35-.15.65-.4.85L5.8 13.8c-.4.3-1 .25-1.3-.15-.3-.4-.3-.95.1-1.25l4.9-4.3c.05-.05.05-.1 0-.2L4.6 3.6c-.4-.35-.45-.9-.1-1.3s.9-.45 1.3-.1l.05.05 5.55 4.9c.2.2.35.55.35.85Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="chevron-right_svg__a">
        <path fill="#fff" transform="rotate(-90 8 6)" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgChevronRight);
export default Memo;
