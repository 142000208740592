import { SVGProps, memo } from 'react';

const SvgChevronUp16Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8.05 4.25c.35 0 .65.15.85.4l4.85 5.5c.35.35.3.95-.05 1.3-.35.35-.95.3-1.3-.05l-.05-.05-4.25-4.8c-.05-.05-.1-.05-.2 0l-4.25 4.8c-.35.4-.9.45-1.3.1s-.45-.9-.1-1.3l.05-.05 4.85-5.5c.25-.2.55-.35.9-.35Z"
      fill="#1F2937"
    />
  </svg>
);

const Memo = memo(SvgChevronUp16Px);
export default Memo;
