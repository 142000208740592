import { SVGProps, memo } from 'react';

const SvgSearchSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M10.125 17V5.437a2.187 2.187 0 0 0-4.297-.583L2.76 16.004" fill="#1F2937" />
    <path
      d="M10.125 17V5.437a2.187 2.187 0 0 0-4.297-.583L2.76 16.004"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path d="M13.875 17V5.437a2.187 2.187 0 0 1 4.297-.583l3.069 11.148" fill="#1F2937" />
    <path
      d="M13.875 17V5.437a2.187 2.187 0 0 1 4.297-.583l3.069 11.148"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.625 17a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 0 0-7.5 0v0ZM13.875 17a3.75 3.75 0 1 0 7.499 0 3.75 3.75 0 0 0-7.499 0v0Z"
      fill="#fff"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.125 9.5h3.75V12h-3.75V9.5Z"
      fill="#1F2937"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgSearchSelected);
export default Memo;
