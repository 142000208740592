import { ReactNode, cloneElement, isValidElement, memo, useState } from 'react';
import { useGetUser } from 'src/queries/users/get-user.query';
import { GeneralModalProps, ServerSharedModal } from '../modal';
import { ServerSharedAQImageWithChildren } from 'src/server-shared/aq-image';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { AQString } from 'src/shared/AQString';
import { UserPlaceholderIcon } from 'src/server-shared/texts/user-placeholder-icon';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';
import { InvestorRegistrationSteps } from 'src/ui/profile-completion/investor/investor-registration';
import { useGetRoleAccreditation } from 'src/queries/role-accreditation';
import Warning24Px from 'public/svg-components/Warning24Px';
import Close12Px from 'public/svg-components/Close12Px';

export const AvatarBadge = ({ isBlack = false, isSmall = false }) => {
  return (
    <div
      className={classNames('flex', classes['avatar-badge'], {
        [classes.black]: isBlack,
        [classes.small]: isSmall,
      })}
    >
      <Warning24Px
        className={classNames(classes['avatar-badge__icon'], {
          [classes.black]: isBlack,
          [classes.small]: isSmall,
        })}
        width={isSmall ? 16 : 24}
        height={isSmall ? 16 : 24}
      />
    </div>
  );
};

export const CompleteProfileModal = ({ show, closeEvent }: GeneralModalProps) => {
  const { user, refetch } = useGetUser();
  const { userRoleAccreditationDetails } = useGetRoleAccreditation();

  return (
    <ServerSharedModal show={show} closeEvent={closeEvent}>
      <div className={classNames('flex', classes['close'])} onClick={closeEvent}>
        <Close12Px />
      </div>
      <div className="grid-row l">
        <div className={classNames('grid-row l justify-items-center', classes['top-section'])}>
          <div className={classNames('flex', classes['avatar-section'])}>
            <ServerSharedAQImageWithChildren
              height={96}
              width={96}
              placeholderIcon={
                user?.fullName ? <UserPlaceholderIcon fullName={user.fullName} /> : undefined
              }
              borderRadius="round"
              image={user?.image}
            />
            <AvatarBadge />
          </div>

          <div className="grid-row xs text-align-center">
            <ServerSharedText type="label-l">
              <AQString componentId="aquaty-app.complete-profile-modal.title" />
            </ServerSharedText>
            <ServerSharedText color="grey500">
              <AQString
                componentId="aquaty-app.complete-profile-modal.subtitle"
                variables={{ email: user?.email || '' }}
              />
            </ServerSharedText>
          </div>
        </div>
        <div className={classes['progress-section']}>
          {user && userRoleAccreditationDetails && (
            <InvestorRegistrationSteps
              user={user}
              refetch={refetch}
              userRoleAccreditationDetails={userRoleAccreditationDetails}
            />
          )}
        </div>
      </div>
    </ServerSharedModal>
  );
};

interface CompleteProfileModalWrapperProps {
  disabled?: boolean;
  children: ReactNode;
  clickHandler?: () => void;
}

export const CompleteProfileModalWrapper = memo(
  ({ children, disabled = false, clickHandler }: CompleteProfileModalWrapperProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);

    return (
      <>
        {isValidElement(children) &&
          cloneElement(children, { onClick: disabled ? () => setShowModal(true) : clickHandler })}
        {showModal && <CompleteProfileModal show closeEvent={() => setShowModal(false)} />}
      </>
    );
  },
);
