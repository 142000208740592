import { DocumentNode, gql, useMutation } from '@apollo/client';
import { useCallback } from 'react';

import { EquityOfferingTaskEnum, EquityOfferingTaskStatusEnum } from 'src/interfaces/offering';
import { venturePitchTargetsQuery } from '../venture-pitch/venture-pitch-targets.query';
import { venturesQuery } from '../ventures.query';
import { offeringQuery } from './get-offering.query';

const completeLegalVentureData = gql`
  mutation (
    $equityOfferingId: Long
    $task: EquityOfferingTask
    $status: EquityOfferingTaskStatus
    $statusArgumentsJson: String
    $userId: Long
  ) {
    saveEquityOfferingStage(
      stage: {
        equityOfferingId: $equityOfferingId
        task: $task
        status: $status
        statusArgumentsJson: $statusArgumentsJson
        userId: $userId
      }
    )
  }
`;

interface OfferingStageMutation {
  equityOfferingId: number;
  task: EquityOfferingTaskEnum;
  status: EquityOfferingTaskStatusEnum;
  statusArgumentsJson?: string;
  userId?: number;
}

export const useSaveEquityOfferingStage = (
  refetchQueries: DocumentNode[] = [venturesQuery, venturePitchTargetsQuery, offeringQuery],
) => {
  const [mutateEquityOfferingStage, { loading }] = useMutation<any, OfferingStageMutation>(
    completeLegalVentureData,
    {
      refetchQueries: refetchQueries,
    },
  );

  const saveEquityOfferingStage = useCallback(
    async (values: OfferingStageMutation) => {
      await mutateEquityOfferingStage({
        variables: values,
      });
    },
    [mutateEquityOfferingStage],
  );
  return { saveEquityOfferingStage, loading };
};
