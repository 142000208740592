import { SVGProps, memo } from 'react';

const SvgPen20Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={20} height={20} viewBox="0 0 20 20" fill="none" {...props}>
    <g clipPath="url(#clip0_27543_107553)">
      <path
        d="M10 0C8.02219 0 6.08879 0.58649 4.4443 1.6853C2.79981 2.78412 1.51809 4.3459 0.761209 6.17317C0.00433286 8.00043 -0.193701 10.0111 0.192152 11.9509C0.578004 13.8907 1.53041 15.6725 2.92894 17.0711C4.32746 18.4696 6.10929 19.422 8.0491 19.8079C9.98891 20.1937 11.9996 19.9957 13.8268 19.2388C15.6541 18.4819 17.2159 17.2002 18.3147 15.5557C19.4135 13.9112 20 11.9778 20 10C20 7.34783 18.9464 4.8043 17.0711 2.92893C15.1957 1.05357 12.6522 0 10 0ZM5 14.2333L5.925 11.9083C5.94863 11.857 5.9795 11.8093 6.01667 11.7667L10.925 6.85833C11.0043 6.78146 11.1104 6.73847 11.2208 6.73847C11.3313 6.73847 11.4374 6.78146 11.5167 6.85833L12.95 8.25C13.024 8.32921 13.0656 8.43327 13.0667 8.54167C13.0677 8.65294 13.026 8.76035 12.95 8.84167L8.04167 13.75C8.00203 13.7909 7.95358 13.8223 7.9 13.8417L5.575 14.775C5.4991 14.8044 5.41633 14.8114 5.33656 14.7952C5.25678 14.7789 5.18335 14.7401 5.125 14.6833C5.05966 14.6309 5.01244 14.5592 4.99001 14.4785C4.96758 14.3977 4.97108 14.312 5 14.2333ZM14.775 6.98333L14.075 7.675C14.0372 7.71424 13.9919 7.74552 13.9418 7.76699C13.8917 7.78846 13.8378 7.79968 13.7833 7.8C13.7287 7.80067 13.6745 7.78989 13.6243 7.76836C13.574 7.74683 13.5289 7.71503 13.4917 7.675L12.125 6.28333C12.086 6.2446 12.055 6.19851 12.0338 6.14774C12.0126 6.09697 12.0018 6.0425 12.0018 5.9875C12.0018 5.93249 12.0126 5.87803 12.0338 5.82726C12.055 5.77648 12.086 5.7304 12.125 5.69167L12.825 5C13.0898 4.73995 13.4456 4.59345 13.8167 4.59167C14.094 4.5933 14.3647 4.67678 14.5948 4.83164C14.8248 4.9865 15.0041 5.20584 15.11 5.46216C15.2159 5.71848 15.2437 6.00036 15.1901 6.27245C15.1364 6.54455 15.0036 6.79475 14.8083 6.99167L14.775 6.98333Z"
        fill="#6B7280"
      />
    </g>
    <defs>
      <clipPath id="clip0_27543_107553">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgPen20Px);
export default Memo;
