import { NextRequest, NextResponse } from 'next/server';
import { COGNITO_JWT } from 'src/constants/loggedIn';

import { NOT_AUTHORIZED_PAGES, DEMO_SIGN_IN_PAGE, SIGN_IN_PAGE } from 'src/constants/paths';

// Regex for public files
const PUBLIC_FILE = /\.(.*)$/;

export const isDemo = process.env.RUNTIME_ENVIRONMENT === 'demo';

export async function middleware(req: NextRequest) {
  if (!PUBLIC_FILE.test(req.nextUrl.pathname)) {
    if (NOT_AUTHORIZED_PAGES.includes(req.nextUrl.pathname)) {
      // that means we on public page
      return NextResponse.next();
    }

    const isLoggedIn = (req as any).cookies[COGNITO_JWT];
    if (isLoggedIn) return NextResponse.next();
    // TODO CREATE THIS page and make sure it available as a public page
    return NextResponse.redirect(isDemo ? DEMO_SIGN_IN_PAGE : SIGN_IN_PAGE);
  }

  return NextResponse.next();
}
