import { SVGProps, memo } from 'react';

const SvgHome = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M21.057 9.456 12 2.623 2.935 9.456a.98.98 0 0 0-.314.667v10.435a.818.818 0 0 0 .815.814H8.87V17a3.125 3.125 0 1 1 6.25 0v4.375h5.434a.818.818 0 0 0 .816-.815V10.123a.984.984 0 0 0-.314-.667Z"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgHome);
export default Memo;
