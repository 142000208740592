import { SVGProps, memo } from 'react';

const SvgSettings24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M10.788 4.032a1.63 1.63 0 0 0 2.424 0L14 3.167a1.632 1.632 0 0 1 2.842 1.177l-.06 1.167a1.63 1.63 0 0 0 1.71 1.712l1.166-.06a1.633 1.633 0 0 1 1.175 2.842l-.868.783a1.633 1.633 0 0 0 0 2.425l.868.783a1.633 1.633 0 0 1-1.177 2.841l-1.167-.059a1.63 1.63 0 0 0-1.713 1.714l.06 1.166A1.633 1.633 0 0 1 14 20.833l-.784-.867a1.631 1.631 0 0 0-2.423 0l-.788.867a1.633 1.633 0 0 1-2.838-1.173l.06-1.167a1.632 1.632 0 0 0-1.714-1.713l-1.166.06A1.632 1.632 0 0 1 3.167 14l.867-.783a1.633 1.633 0 0 0 0-2.425l-.867-.787A1.631 1.631 0 0 1 4.34 7.167l1.167.059a1.632 1.632 0 0 0 1.715-1.717l-.056-1.167a1.633 1.633 0 0 1 2.838-1.175l.783.865Z"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.25 12a3.75 3.75 0 1 0 7.5 0 3.75 3.75 0 0 0-7.5 0v0Z"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgSettings24Px);
export default Memo;
