import { useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Link from 'next/link';
import { useDispatch, useSelector } from 'react-redux';

import LockFilled16Px from 'public/svg-components/LockFilled16Px';
import ChevronRight from 'public/svg-components/ChevronRight';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { AQString } from 'src/shared/AQString';
import { getActiveNavigationItem, setActiveNavigationItem } from 'src/store/app/reducer';
import { PathItem } from 'src/interfaces/app-navigation';
import { ServerSharedAQImageWithChildren } from 'src/server-shared/aq-image';
import classes from './index.module.scss';
import Clubs24Px from 'public/svg-components/Clubs24Px';
import { HeaderInbox } from '../inbox';
import { classNames } from 'src/utils/classNames';
import { Roles } from 'src/interfaces/user';

const getQuery = (queries: Record<string, any>, q?: (number | string)[]): Record<string, any> => {
  if (!q || q.length === 0) return {};
  return q.reduce((acc, el) => {
    if (queries[el]) return { ...acc, [el]: queries[el] };
    return acc;
  }, {});
};

const getBreadcrumbText = ({ breadcrumb }: PathItem) => {
  return (
    <ServerSharedText type="label-s" color="grey500" style={{ padding: '4px 8px' }}>
      {breadcrumb.isDynamic ? breadcrumb.title || ' ' : <AQString componentId={breadcrumb.title} />}
    </ServerSharedText>
  );
};

interface ExtendedBreadcrumbsProps {
  showFounderVentureList: boolean;
  role?: Roles;
}

export const Breadcrumbs = () => {
  const router = useRouter();
  const dispatch = useDispatch();
  const activeNavigationItem = useSelector(getActiveNavigationItem);

  useEffect(() => {
    if (activeNavigationItem.path !== router.pathname) {
      dispatch(setActiveNavigationItem(router.pathname));
    }
  }, [router.pathname, dispatch, activeNavigationItem.path]);

  const breadcrumbsWithTitle = useMemo(() => {
    return activeNavigationItem.breadcrumbs.filter((el) => !!el.breadcrumb.title);
  }, [activeNavigationItem.breadcrumbs]);

  const tag = useMemo(() => activeNavigationItem.headerTag, [activeNavigationItem]);

  return (
    <ul className={classes.list}>
      {breadcrumbsWithTitle.map((bc, i) => {
        const breadcrumbText = getBreadcrumbText(bc);
        const isLast = activeNavigationItem.breadcrumbs.length === i + 1;
        return (
          <li className={classes.list__item} key={bc.path}>
            {i !== 0 && !!bc.breadcrumb.title && (
              <div className={classes.chevron}>
                <ChevronRight />
              </div>
            )}
            {isLast || bc.isNotLink ? (
              breadcrumbText
            ) : (
              <Link
                href={{
                  pathname: bc.path,
                  query: getQuery(router.query, bc.breadcrumb.query),
                }}
              >
                {breadcrumbText}
              </Link>
            )}
          </li>
        );
      })}

      {tag && tag.show && (
        <>
          {tag.type === 'public' && (
            <li className="tag-item flex xxs">
              <LockFilled16Px />
              <ServerSharedText type="label-xs">
                <AQString componentId={tag.text || ''} />
              </ServerSharedText>
            </li>
          )}
          {tag.type === 'private' && (
            <li className="tag-item flex xxs">
              <ServerSharedAQImageWithChildren
                placeholderIcon={<Clubs24Px width={16} height={16} />}
                image={tag.club?.logo}
                width={16}
                height={16}
                borderRadius="xs"
              />
              <ServerSharedText type="label-xs">
                <AQString
                  componentId={tag.text || ''}
                  variables={{ clubName: tag.club?.name || '' }}
                />
              </ServerSharedText>
            </li>
          )}
          {/* we can define a list of tags here  */}
        </>
      )}
    </ul>
  );
};

export const ExtendedBreadcrumbs = ({ showFounderVentureList, role }: ExtendedBreadcrumbsProps) => {
  return (
    <div className={classNames('flex justify-content-sp', classes.wrapper)}>
      <Breadcrumbs />
      <HeaderInbox showFounderVentureList={showFounderVentureList} role={role} />
    </div>
  );
};
