'use client';

import { ReactElement, MouseEventHandler, MouseEvent, CSSProperties } from 'react';

import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

type ButtonSize = 'medium' | 'large';

export type ButtonVariant =
  | 'text'
  | 'disapprove'
  | 'approve'
  | 'inform'
  | 'secondary'
  | 'dashed'
  | 'round-primary'
  | 'round-white'
  | 'delete'
  | 'black';

interface ButtonProps {
  size?: ButtonSize;
  variant?: ButtonVariant;
  label: string | ReactElement;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onMouseLeave?: MouseEventHandler<HTMLButtonElement>;
  onMouseEnter?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  style?: CSSProperties;
  className?: string;
}

export const ServerSharedButton = ({
  size = 'large',
  label,
  variant = 'inform',
  onClick,
  disabled,
  className = '',
  ...others
}: ButtonProps) => {
  const onClickHandler = (e: MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    e.preventDefault();
    if (onClick) onClick(e);
  };

  return (
    <button
      className={classNames(
        classes.btn,
        {
          [classes[`btn__${size}`]]: true,
          [classes[`btn--${variant}`]]: true,
        },
        className,
      )}
      type="button"
      onClick={onClickHandler}
      disabled={disabled}
      {...others}
    >
      {label}
    </button>
  );
};
