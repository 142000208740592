import { components, OptionProps } from 'react-select';
import NextImage from 'next/image';

import flags from 'public/assets/flags.json';
import Venture24Px from 'public/svg-components/Venture24Px';
import Email24Px from 'public/svg-components/Email24Px';
import CheckCircleM24Px from 'public/svg-components/CheckCircleM24Px';
import { AQString } from 'src/shared/AQString';
import { ServerSharedCheckedItem } from 'src/server-shared/inputs/checkbox';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedAQImageWithChildren } from 'src/server-shared/aq-image';
import { UserPlaceholderIcon } from 'src/server-shared/texts/user-placeholder-icon';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { Option, OptionWithImageExpend } from '../types';
import { useMemo } from 'react';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';
import {
  roleAssignmentColors,
  roleAssignmentLabels,
} from 'src/ui/equity-offerings/deal-team-setup/shared';
import assignPartnerClasses from 'src/ui/equity-offerings/deal-team-setup/index.module.scss';
import { EquityOfferingTaskStatus } from 'src/interfaces/offering';
import { emailRegEx } from 'src/utils/validation';
import { rolesLabel } from 'src/constants/common';
import { getSingleUserRole } from 'src/queries/users/get-user.query';
import { User } from 'src/interfaces/user';
import { IVentureLocation } from 'src/interfaces/venture';
import { countriesLabels } from 'src/constants/common';

export const OptionComponentWithCheckbox = (props: OptionProps<Option>) => {
  const label = props.data.label;

  return (
    <components.Option {...props}>
      <ServerSharedCheckedItem
        isChecked={props.isSelected}
        value={''}
        onChange={() => null}
        background="primary600"
      />

      <ServerSharedText ellipsis style={{ marginLeft: 12 }}>
        {(props.selectProps as any).isResourceString ? <AQString componentId={label} /> : label}
      </ServerSharedText>

      {props.data?.data?.count !== undefined && (
        <ServerSharedText color="grey500" style={{ marginLeft: 12 }}>
          {props.data?.data?.count}
        </ServerSharedText>
      )}
    </components.Option>
  );
};

interface OptionComponentHeaderStepperProps extends OptionProps<StepIndicatorObject> {
  value: string;
}

export const OptionComponentHeaderStepper = (props: OptionComponentHeaderStepperProps) => {
  const [option, index] = useMemo(() => {
    const currentOption = props.options.find((el) => {
      if ('value' in el) {
        return el.value === props.value;
      }
      return false;
    }) as StepIndicatorObject;

    const currentIndex = props.options.findIndex((el) => {
      if ('value' in el) {
        return el.value === props.value;
      }
      return false;
    });
    return [currentOption, currentIndex];
  }, [props]);

  const { complete, active } = option;

  return (
    <components.Option {...props}>
      <div
        className={classNames('flex justify-content-sp', classes['header-stepper-option'], {
          [classes['active-option']]: active,
        })}
      >
        <ServerSharedText type={props.isSelected ? 'label' : 'text'} color="body" ellipsis>
          <AQString componentId={props.label} />
        </ServerSharedText>

        {complete && <CheckCircleM24Px />}
        {!complete && (
          <div
            className={classNames('flex step-number', {
              ['active']: active,
            })}
          >
            <ServerSharedText type="label-s" color="body">
              {index + 1}
            </ServerSharedText>
          </div>
        )}
      </div>
    </components.Option>
  );
};

export const ColoredCircle = ({ option, selected }: { option: Option; selected?: boolean }) => {
  return (
    <div
      className={classNames(classes['colored-circle'], {
        [classes['colored-circle__active']]: selected,
      })}
      style={
        option?.data?.custom
          ? { background: option.value, border: 'none' }
          : { background: option.value }
      }
    />
  );
};

export const ColorOption = (props: OptionProps<Option>) => {
  return (
    <components.Option {...props}>
      <ColoredCircle option={props.data} selected={props.isSelected} />
    </components.Option>
  );
};

export const FlagOption = (props: OptionProps<Option>) => {
  const flag: string = props.data?.data?.flag;
  const label = props.data.label;
  return (
    <components.Option {...props}>
      {flag && (
        <NextImage
          src={(flags as Record<string, string>)[flag]}
          alt={flag}
          width={24}
          height={24}
        />
      )}
      <ServerSharedText ellipsis style={{ marginLeft: 12 }}>
        {(props.selectProps as any).isResourceString ? <AQString componentId={label} /> : label}
      </ServerSharedText>
    </components.Option>
  );
};

export const HeaderCustomOptionExtended = (props: OptionProps<Option>) => {
  const svg = (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="20" height="20" rx="10" fill="white" />
      <path
        d="M12 2C10.0222 2 8.08879 2.58649 6.4443 3.6853C4.79981 4.78412 3.51809 6.3459 2.76121 8.17317C2.00433 10.0004 1.8063 12.0111 2.19215 13.9509C2.578 15.8907 3.53041 17.6725 4.92894 19.0711C6.32746 20.4696 8.10929 21.422 10.0491 21.8079C11.9889 22.1937 13.9996 21.9957 15.8268 21.2388C17.6541 20.4819 19.2159 19.2002 20.3147 17.5557C21.4135 15.9112 22 13.9778 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2V2ZM17.775 8.83333L12.0667 16.575C12.0002 16.6655 11.9162 16.7417 11.8196 16.7989C11.723 16.8562 11.6159 16.8933 11.5046 16.9081C11.3933 16.9229 11.2801 16.915 11.1719 16.885C11.0638 16.8549 10.9628 16.8034 10.875 16.7333L6.8 13.475C6.7144 13.4062 6.64323 13.3212 6.59058 13.2249C6.53794 13.1285 6.50486 13.0227 6.49324 12.9135C6.48163 12.8043 6.4917 12.6939 6.5229 12.5886C6.55409 12.4833 6.60578 12.3852 6.675 12.3C6.81348 12.132 7.01222 12.0248 7.2287 12.0014C7.44518 11.9781 7.6622 12.0404 7.83334 12.175L11.2333 14.8917L16.4333 7.83333C16.5645 7.65762 16.7597 7.54071 16.9766 7.50795C17.1934 7.47519 17.4144 7.52922 17.5917 7.65833C17.6817 7.7228 17.7579 7.80455 17.816 7.89882C17.8741 7.99309 17.9128 8.09798 17.9298 8.20737C17.9469 8.31676 17.942 8.42846 17.9154 8.53594C17.8888 8.64341 17.8411 8.74451 17.775 8.83333Z"
        fill="#2180CF"
      />
    </svg>
  );

  const isVentureOptionIsDisabled = props?.data && props.data.data && props.data.data.isDisabled;
  const showSelectedIcon = props.isSelected && !isVentureOptionIsDisabled;

  return (
    <components.Option {...props}>
      <div
        className={classNames('grid-column', {
          'template-mc-1fr-mc': showSelectedIcon,
          'template-mc-1fr': showSelectedIcon === false,
          [classes['venture-option']]: true,
        })}
        data-qa={props.label}
      >
        <ServerSharedAQImageWithChildren
          width={24}
          height={24}
          borderRadius="xs"
          image={props.data?.data?.item.logo}
          placeholderIcon={<Venture24Px />}
        />

        <div className="grid-row xxs">
          <ServerSharedText color="body" ellipsis>
            {props.label}
          </ServerSharedText>

          {isVentureOptionIsDisabled && (
            <ServerSharedText type="text-small" color="body">
              <AQString componentId="aquaty-app.profile-completion.founder.venture-selection.waiting.subtitle" />
            </ServerSharedText>
          )}
        </div>

        {showSelectedIcon && svg}
      </div>
    </components.Option>
  );
};

export const InviteUserOption = (props: OptionProps<Option>) => {
  const option = props.data;
  const isUser = !!props.data.data;
  const user = props.data?.data;
  const placeholderIcon = isUser ? (
    <UserPlaceholderIcon fullName={option?.data?.fullName} />
  ) : (
    <Email24Px />
  );
  return (
    <components.Option {...props}>
      <div className={classNames('grid-column template-mc-1fr', classes['invite-user-option'])}>
        <ServerSharedAQImageWithChildren
          width={48}
          height={48}
          borderRadius="round"
          image={option?.data?.image}
          placeholderIcon={placeholderIcon}
        />
        <div className="flex justify-content-sp">
          <div className="grid-row none align-items-center">
            <ServerSharedText type="label">
              {isUser ? user?.fullName : option.label}
            </ServerSharedText>
            {user && (
              <ServerSharedText type="text-small" color="grey500">
                {user.email}
              </ServerSharedText>
            )}
          </div>
          {option.isDisabled && user && (
            <div className="tag-item grey100 no-wrap">
              <ServerSharedText type="label-s">
                <AQString componentId="aquaty-generic.cta.invited" />
              </ServerSharedText>
            </div>
          )}

          {option.isDisabled && !user && (
            <div className="tag-item warning100 no-wrap" style={{ width: 'max-content' }}>
              <ServerSharedText type="label-s" color="warning600">
                <AQString componentId="aquaty-generic.role-status.pending" />
              </ServerSharedText>
            </div>
          )}
        </div>
      </div>
    </components.Option>
  );
};

export const CommonOption = (props: OptionProps<Option>) => {
  const { data, label } = props.data;
  return (
    <components.Option {...props}>
      <ServerSharedText
        type={data?.textType}
        color={data?.textColor || (props.isSelected ? 'primary600' : 'grey900')}
      >
        {(props.selectProps as any)?.isResourceString ? <AQString componentId={label} /> : label}
      </ServerSharedText>
    </components.Option>
  );
};

export const CustomOptionExtended = ({ ...props }: OptionProps<OptionWithImageExpend>) => {
  const status = useMemo(() => props.data.status as EquityOfferingTaskStatus, [props.data.status]);

  const isYourSelf = !!props.data.yourSelf;
  const isEmail = emailRegEx.test(props.data.value);

  return (
    <components.Option {...props}>
      <div className={classes['option-with-image']}>
        <div
          className={classNames(
            'grid-column template-mc-1fr-mc align-items-center',
            classes['option-with-image__info'],
          )}
        >
          <div className="image-container width-48 hide-border">
            <ServerSharedAQImageWithChildren
              width={48}
              height={48}
              image={props.data.image}
              borderRadius="round"
              placeholderIcon={
                isEmail ? <Email24Px /> : <UserPlaceholderIcon fullName={props.data.label} />
              }
            />
          </div>
          <div className="grid-row none">
            <ServerSharedText
              type="label"
              color={props.isSelected || isYourSelf ? 'primary600' : 'grey800'}
            >
              {isYourSelf ? <AQString componentId={props.data.yourSelf} /> : props.data.label}
            </ServerSharedText>
            <ServerSharedText type="text-small" color="grey500">
              {props.data.email}
            </ServerSharedText>
          </div>
          <div
            className={classNames(
              assignPartnerClasses['role-assignment-status'],
              assignPartnerClasses[status],
            )}
          >
            <ServerSharedText type="label-s" color={roleAssignmentColors[status]}>
              {roleAssignmentLabels[status]}
            </ServerSharedText>
          </div>
        </div>
      </div>
    </components.Option>
  );
};

export const SPVSelectOption = (props: OptionProps<Option>) => {
  const textColor = props.data.data?.textColor;
  const textType = props.data.data?.textType;

  return (
    <components.Option {...props}>
      <div className="grid-row xxs">
        <ServerSharedText type={textType} color={props.isDisabled ? 'grey500' : textColor}>
          {props.data.label}
        </ServerSharedText>
        {props.isDisabled && (
          <ServerSharedText color="grey500" type="text-small">
            <AQString componentId="aquaty-app.select-spv.waiting.subtitle" />
          </ServerSharedText>
        )}
      </div>
    </components.Option>
  );
};

export const TaxYearOption = (props: OptionProps<Option>) => {
  return (
    <components.Option {...props}>
      <div className="flex justify-content-sp">
        <ServerSharedText style={{ opacity: props.isDisabled ? 0.5 : 1 }}>
          {props.data.label}
        </ServerSharedText>
        {props.isDisabled && (
          <div className="tag-item">
            <ServerSharedText type="label-xs">
              <AQString componentId="aquaty-generic.info.already-requested" />
            </ServerSharedText>
          </div>
        )}
      </div>
    </components.Option>
  );
};

interface VentureSelectOption extends Option {
  isInThePortfolioList?: boolean;
}

export const VentureSelectsOption = (props: OptionProps<VentureSelectOption>) => {
  const locations = (props.data.data?.item?.locations || []).filter(
    (el: IVentureLocation) => el.city && el.country,
  );

  if (props.data.value === 'manually') {
    return null;
  }

  return (
    <components.Option {...props}>
      <div className="grid-row xxs">
        <div className="grid-row xxs">
          <ServerSharedText>{props.data.label}</ServerSharedText>
          {props.data.isInThePortfolioList && (
            <ServerSharedText color="grey500" type="text-small">
              <AQString componentId="aquaty-generic.form.you-already-added-this-venture-to-your-portfolio" />
            </ServerSharedText>
          )}
        </div>
        {locations.map((el: IVentureLocation, i: number) => {
          const street = el.street ? `${el.street}, ` : '';
          return (
            <ServerSharedText key={props.data.label + i} type="text-small" color="grey500">
              {street} {el.postalCode} {el.city},{' '}
              <AQString componentId={countriesLabels[el.country || 'DEU']} />
            </ServerSharedText>
          );
        })}
      </div>
    </components.Option>
  );
};

export const ImpersonateUserOption = (props: OptionProps<Option>) => {
  const option = props.data;
  const userRole = getSingleUserRole(option.data?.item as User);
  return (
    <components.Option {...props}>
      <div className={classNames('grid-column template-mc-mc')}>
        <ServerSharedAQImageWithChildren
          width={48}
          height={48}
          borderRadius="round"
          image={option?.data?.item.image}
          placeholderIcon={<UserPlaceholderIcon fullName={option?.data?.item.fullName} />}
        />
        <div className="grid-row xxs align-items-center">
          <ServerSharedText type="label">{option?.data?.item.fullName}</ServerSharedText>

          <ServerSharedText type="text-small" color="grey500">
            {option?.data?.item.email} • <AQString componentId={rolesLabel[userRole]} />
          </ServerSharedText>
        </div>
      </div>
    </components.Option>
  );
};
