import { useCallback } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { fileFields, linkFields } from '../../common.strings';
import { VentureProblem, VentureProblemRequest } from 'src/interfaces/venture-pitch';

export const venturePitchProblemGQLString = `
description
links {
  ${linkFields}
}
images {
  ${fileFields}
}
`;

const venturePitchProblemQuery = gql`
  query venturePitchProblem($equityOfferingId: Long!) {
    venturePitchProblem(equityOfferingId: $equityOfferingId) {
      ${venturePitchProblemGQLString}
    }
  }
`;

const venturePitchProblemMutation = gql`
  mutation saveVenturePitchProblem($venturePitchProblem: VenturePitchProblemInput) {
    saveVenturePitchProblem(venturePitchProblem: $venturePitchProblem) {
      ${venturePitchProblemGQLString}
    }
  }
`;

type UseGetVenturePitchProblemQuery = {
  venturePitchProblem: VentureProblem;
};

export const useGetVenturePitchProblem = (
  id?: number,
  onCompleted?: (data: UseGetVenturePitchProblemQuery) => void,
  params?: QueryHookOptions,
) => {
  const { data, loading } = useQuery<UseGetVenturePitchProblemQuery>(venturePitchProblemQuery, {
    variables: { equityOfferingId: id },
    onCompleted,
    fetchPolicy: 'no-cache',
    ...params,
  });

  return { venturePitchProblem: data?.venturePitchProblem, loading };
};

type UseSaveVenturePitchProblemMutation = { venturePitchProblem: VentureProblemRequest };

export const useSaveVenturePitchProblem = () => {
  const [mutateVenturePitchProblem, { loading, data }] = useMutation<
    { saveVenturePitchProblem: VentureProblem },
    UseSaveVenturePitchProblemMutation
  >(venturePitchProblemMutation);

  const saveVenturePitchProblem = useCallback(
    async (variables: UseSaveVenturePitchProblemMutation) => {
      await mutateVenturePitchProblem({ variables });
    },
    [mutateVenturePitchProblem],
  );
  return {
    saveVenturePitchProblem,
    loading,
    venturePitchProblem: data?.saveVenturePitchProblem,
  };
};
