import { inter } from 'src/styles/fonts';

export const GlobalStyles = () => {
  return (
    <style jsx global>{`
      html {
        font-family: ${inter.style.fontFamily};
      }
    `}</style>
  );
};
