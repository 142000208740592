import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedSelectProps, Option } from 'src/server-shared/inputs/select/types';

export const formatOptionLabel = (
  o: Option,
  { isResourceString, isDisabled }: Partial<ServerSharedSelectProps>,
) => {
  return (
    <ServerSharedText color={isDisabled ? 'grey500' : 'grey900'}>
      {isResourceString ? (
        <AQString componentId={o.label} />
      ) : (
        <>{o.data?.labelPreview || o.label}</>
      )}
    </ServerSharedText>
  );
};
