import { SVGProps, memo } from 'react';

const SvgClubsSelected24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4856_126817)">
      <path
        d="M17.0002 14.0859C16.2456 14.0851 15.5012 14.2591 14.8252 14.5943C14.7712 14.6185 14.7231 14.6541 14.6843 14.6987C14.6454 14.7433 14.6167 14.7958 14.6002 14.8526C14.5747 14.9661 14.5926 15.0849 14.6502 15.1859C15.3722 16.3585 15.7531 17.709 15.7502 19.0859C15.7523 19.1958 15.7969 19.3005 15.8746 19.3782C15.9523 19.4559 16.057 19.5005 16.1668 19.5026H21.5835C21.694 19.5026 21.8 19.4587 21.8781 19.3806C21.9563 19.3024 22.0002 19.1964 22.0002 19.0859C22.0002 17.7599 21.4734 16.4881 20.5357 15.5504C19.598 14.6127 18.3263 14.0859 17.0002 14.0859Z"
        fill="#1F2937"
      />
      <path
        d="M16.9502 13.6641C18.6761 13.6641 20.0752 12.265 20.0752 10.5391C20.0752 8.81317 18.6761 7.41406 16.9502 7.41406C15.2243 7.41406 13.8252 8.81317 13.8252 10.5391C13.8252 12.265 15.2243 13.6641 16.9502 13.6641Z"
        fill="#1F2937"
      />
      <path
        d="M8.24984 12.4167C10.436 12.4167 12.2082 10.6445 12.2082 8.45833C12.2082 6.27221 10.436 4.5 8.24984 4.5C6.06371 4.5 4.2915 6.27221 4.2915 8.45833C4.2915 10.6445 6.06371 12.4167 8.24984 12.4167Z"
        fill="#1F2937"
      />
      <path
        d="M14.5 19.0859C14.5 17.4283 13.8415 15.8386 12.6694 14.6665C11.4973 13.4944 9.9076 12.8359 8.25 12.8359C6.5924 12.8359 5.00268 13.4944 3.83058 14.6665C2.65848 15.8386 2 17.4283 2 19.0859C2 19.1964 2.0439 19.3024 2.12204 19.3806C2.20018 19.4587 2.30616 19.5026 2.41667 19.5026H14.0833C14.1938 19.5026 14.2998 19.4587 14.378 19.3806C14.4561 19.3024 14.5 19.1964 14.5 19.0859Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="clip0_4856_126817">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgClubsSelected24Px);
export default Memo;
