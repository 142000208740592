import { useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useRouter } from 'next/router';

import { AQString } from 'src/shared/AQString';
import { ServerSharedModal, GeneralModalProps } from 'src/server-shared/modals/modal';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { EquityOfferingTaskEnum, EquityOfferingTaskStatusEnum } from 'src/interfaces/offering';
import { useSaveEquityOfferingStage } from 'src/queries/offering/save-equity-offering';
import { equityOfferings } from 'src/queries/offering/get-offering-list';
import { HOME_PAGE, DEAL_ROOM_PAGE } from 'src/constants/paths';
import { addNotification } from 'src/store/app/reducer';

import { offeringQuery, useGetOfferingById } from 'src/queries/offering/get-offering.query';
import {
  FeedbackType,
  FeedbackCard,
} from 'src/ui/administration-equity-offerings/components/feedback-card';

interface ReviewModalProps extends GeneralModalProps {
  id: number;
}

export const ReviewModal = ({ show, closeEvent, id }: ReviewModalProps) => {
  const dispatch = useDispatch();
  const { saveEquityOfferingStage } = useSaveEquityOfferingStage([equityOfferings, offeringQuery]);
  const [feedback, setFeedback] = useState<null | FeedbackType>(null);
  const router = useRouter();

  const { equityOffering } = useGetOfferingById(Number(id));

  const task = useMemo(() => {
    return equityOffering?.tasks.find((el) => el.task === EquityOfferingTaskEnum.REVIEW_DATA_ROOM);
  }, [equityOffering]);

  const disableRightButton = useMemo(() => {
    if (!feedback || task?.status !== EquityOfferingTaskStatusEnum.TO_COMPLETE) {
      return true;
    }
    return false;
  }, [feedback, task?.status]);

  const handleConfirm = useCallback(async () => {
    if (!feedback) {
      return true;
    }

    const isApprove = feedback === 'approve';
    const status = isApprove
      ? EquityOfferingTaskStatusEnum.COMPLETED
      : EquityOfferingTaskStatusEnum.INSUFFICIENT;

    try {
      if (id) {
        await saveEquityOfferingStage({
          equityOfferingId: id,
          task: EquityOfferingTaskEnum.REVIEW_DATA_ROOM,
          status,
        });
        if (isApprove) {
          router.push({
            pathname: DEAL_ROOM_PAGE,
            query: {
              offeringId: id,
            },
          });
          return;
        }
        router.push(HOME_PAGE);
      }
    } catch (err) {
      dispatch(
        addNotification({
          title: 'aquaty-app.notification.error',
          message: 'aquaty-app.notifications.request-was-failed',
          type: 'error',
        }),
      );
    }
  }, [dispatch, feedback, id, router, saveEquityOfferingStage]);

  return (
    <ServerSharedModal
      show={show}
      closeEvent={closeEvent}
      header={{
        title: <AQString componentId="aquaty-app.equity-offering.data-room-review.title" />,
        subtitle: (
          <AQString componentId="aquaty-app.equity-offering.data-room-review.modal-review.subtitle" />
        ),
      }}
      footer={{
        buttonText: 'aquaty-generic.cta.complete',
        disabledRightButton: disableRightButton,
        onConfirm: handleConfirm,
      }}
    >
      <div className="grid-row">
        <div className="grid-row xs">
          <ServerSharedText type="header-s">
            <AQString componentId="aquaty-app.equity-offering.data-room-review.modal-review.description.title" />
          </ServerSharedText>
          <ServerSharedText as="pre">
            <AQString componentId="aquaty-app.equity-offering.data-room-review.modal-review.description.text" />
          </ServerSharedText>
        </div>

        <div className="grid-row xs">
          <FeedbackCard
            type="approve"
            selected={feedback === 'approve'}
            setFeedback={setFeedback}
          />
          <FeedbackCard
            type="disapprove"
            selected={feedback === 'disapprove'}
            setFeedback={setFeedback}
          />
        </div>
      </div>
    </ServerSharedModal>
  );
};
