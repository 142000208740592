'use client';
import { removeCookies } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { Auth } from 'aws-amplify';
import * as Sentry from '@sentry/browser';

import { COGNITO_JWT } from 'src/constants/loggedIn';
import { DEMO_SIGN_IN_PAGE, SIGN_IN_PAGE } from 'src/constants/paths';
import { COGNITO_USER_ID, EMAIL } from 'src/constants/registration';
import client from 'src/apollo-client';
import { isDemo } from 'src/_middleware';
import { useMixpanel } from './useMixpanel';

export const logOutFunction = () => {
  Sentry.setUser(null);
  localStorage.clear();
  const cookiesForRemove = [COGNITO_JWT, COGNITO_USER_ID, EMAIL];
  sessionStorage.removeItem(EMAIL);
  cookiesForRemove.forEach((key) => removeCookies(key));
  client.cache.reset();
  Auth.signOut();
};

export const useLogout = () => {
  const { mixpanelLogout } = useMixpanel();
  const { push } = useRouter();

  const logout = () => {
    logOutFunction();
    mixpanelLogout();
    push(isDemo ? DEMO_SIGN_IN_PAGE : SIGN_IN_PAGE);
  };
  return { logout };
};

export const useSoftRegisteredUserLogout = () => {
  const { mixpanelLogout } = useMixpanel();
  const logout = () => {
    logOutFunction();
    mixpanelLogout();
  };
  return { logout };
};
