import { SVGProps, memo } from 'react';

const SvgLogo = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M0 24C0 12.686 0 7.03 3.515 3.515 7.029 0 12.686 0 24 0s16.97 0 20.485 3.515C48 7.029 48 12.686 48 24s0 16.97-3.515 20.485C40.971 48 35.314 48 24 48S7.03 48 3.515 44.485C0 40.971 0 35.314 0 24Z"
      fill="url(#logo_svg__a)"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M29.554 29.83 32.253 36H36.5L24.225 9 11.5 36h4.18l2.865-6.17h.012l1.625-3.643h-.003l3.98-8.598 3.813 8.638"
      fill="#fff"
    />
    <defs>
      <linearGradient id="logo_svg__a" x1={0} y1={0} x2={48} y2={48} gradientUnits="userSpaceOnUse">
        <stop stopColor="#565759" />
        <stop offset={1} stopColor="#252223" />
      </linearGradient>
    </defs>
  </svg>
);

const Memo = memo(SvgLogo);
export default Memo;
