import { Storage } from '@aws-amplify/storage';

import { CANCEL_BY_USER } from './utils';
import { IFile, EntityProgress } from './types';

type FileTypes = IFile | File | Blob;

interface UploadToS3Config {
  fileId?: string;
  updateProgress?: (data: EntityProgress) => void;
}

interface UploadToS3Data {
  fileName: string;
  file: FileTypes;
}

export const uploadToS3 = (data: UploadToS3Data, config?: UploadToS3Config) => {
  return Storage.put(data.fileName, data.file, {
    bucket: process.env.AWS_BUCKET_NAME,
    level: 'protected',
    contentType: data.file.type,
    progressCallback(progress) {
      if (config && config.updateProgress) {
        config.updateProgress({
          id: config.fileId || '',
          progress: Math.round((progress.loaded * 100) / progress.total),
        });
      }
    },
    // completeCallback: (event: any) => {
    //   console.log(`Successfully uploaded ${event.key}`);
    // },
    // errorCallback: (err: any) => {
    //   console.error('Unexpected error while uploading', err);
    // },
  });
};

export const cancelRequest = (request: any) => Storage.cancel(request, CANCEL_BY_USER);

// export const removeFileFromS3 = (key: string) => Storage.remove(key, { level: 'protected' });

interface GetFileConfig {
  download?: boolean;
}

export const getFileS3URL = (fileName: string, config?: GetFileConfig) => {
  if (!fileName) return new Promise<string>((req) => req(fileName));

  return Storage.get(fileName, {
    bucket: process.env.AWS_BUCKET_NAME,
    level: 'protected',
    ...config,
  });
};
