import { PropsValue } from 'react-select';

import { Option } from 'src/server-shared/inputs/select/types';

export const getValue = (value: PropsValue<Option> | string = '', options: Option[]) => {
  if (typeof value === 'string') {
    return options.find((o) => o.value === value) || null;
  }
  return value;
};

export const getSingleValue = (o: Option | string) => (typeof o === 'string' ? o : o.value);
