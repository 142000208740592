import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { DataRoomVentureAsset } from 'src/interfaces/data-room';
import { fileFields } from '../common.strings';

export const intellectualPropertyGQLString = `
assets {
creatorName
definitionAttachments {
  ${fileFields}
}
description
id
maintenanceAttachments {
  ${fileFields}
}
name
protectionAttachments {
  ${fileFields}
}
protectionDescription
qualityMaintenanceDescription
type
}
furtherIntellectualPropertiesInformation
`;

const dataRoomAssetsMutation = gql`
  mutation saveDataRoomIntellectualPropertiesAndAssets(
    $equityOfferingId: Long
    $intellectualProperty: DataRoomIntellectualPropertyInput
  ) {
    saveDataRoomIntellectualPropertiesAndAssets(
      equityOfferingId: $equityOfferingId
      intellectualProperty: $intellectualProperty
    ) {
      ${intellectualPropertyGQLString}
    }
  }
`;

interface UseSaveDataRoomAssetsMutation {
  equityOfferingId: number;
  intellectualProperty: {
    assets: DataRoomVentureAsset[];
    furtherIntellectualPropertiesInformation: string;
  };
}

export const useSaveDataRoomAssets = () => {
  const [mutateDataRoomAssets, { loading, data }] = useMutation(dataRoomAssetsMutation);

  const saveDataRoomIntellectualPropertiesAndAssets = useCallback(
    async (variables: UseSaveDataRoomAssetsMutation) => {
      await mutateDataRoomAssets({ variables });
    },
    [mutateDataRoomAssets],
  );
  return {
    saveDataRoomIntellectualPropertiesAndAssets,
    loading,
    savedDataRoomIntellectualPropertiesAndAssets: data?.saveDataRoomIntellectualPropertiesAndAssets,
  };
};
