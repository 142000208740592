import { components, SingleValueProps } from 'react-select';

import { ColoredCircle } from './option';
import { Option, OptionWithImage } from '../types';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedAQImageWithChildren } from 'src/server-shared/aq-image';
import { UserPlaceholderIcon } from 'src/server-shared/texts/user-placeholder-icon';
import classes from './index.module.scss';
import { emailRegEx } from 'src/utils/validation';
import User16Px from 'public/svg-components/User16Px';

export const SingleValueWithColor = ({ children, ...props }: SingleValueProps<Option>) => {
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex', gap: 16 }}>
        <ColoredCircle option={props.data} />

        {children}
      </div>
    </components.SingleValue>
  );
};

export const SingleValueFounderHeader = (props: SingleValueProps<Option>) => {
  const label =
    props.selectProps.value && 'label' in props.selectProps.value && props.selectProps.value.label;

  return (
    <components.SingleValue {...props}>
      <ServerSharedText type="label-s" color="grey500">
        {label}
      </ServerSharedText>
    </components.SingleValue>
  );
};

export const SingleValueWithImage = ({ children, ...props }: SingleValueProps<OptionWithImage>) => {
  return (
    <components.SingleValue {...props}>
      <div className="flex justify-content-start m">
        <ServerSharedAQImageWithChildren
          width={24}
          height={24}
          image={props.data.image}
          borderRadius="round"
        />
        {children}
      </div>
    </components.SingleValue>
  );
};

export const SingleValueWithImageExtended = ({
  children,
  ...props
}: SingleValueProps<OptionWithImage>) => {
  const isEmail = emailRegEx.test(props.data.value);
  return (
    <components.SingleValue {...props}>
      <div style={{ display: 'flex' }}>
        <div className="image-container width-24 hide-border">
          <ServerSharedAQImageWithChildren
            width={24}
            height={24}
            image={props.data.image}
            borderRadius="round"
            placeholderIcon={
              isEmail ? <User16Px /> : <UserPlaceholderIcon fullName={props.data.label} />
            }
          />
        </div>
        <div className={classes['single-value-with-image-name']}>{children}</div>
      </div>
    </components.SingleValue>
  );
};
