import { Roles } from 'src/interfaces/user';
import { FileField } from 'src/interfaces/common';

export interface DataRoomSectionEvaluation {
  attachments: FileField[];
  attributeEvaluations: DataRoomSectionAttributeEvaluation[];
  dataRoomId: number;
  evaluationText: string;
  id?: number;
  reviewer?: DataRoomReviewer;
  section: DataRoomEvaluationSteps;
}

export interface DataRoomSectionAttributeEvaluation {
  dataRoomSectionAttribute: DataRoomSectionAttribute;
  id: number;
  score: number;
}

export enum DataRoomSectionAttribute {
  'LEGAL_ENTITY_STRUCTURE' = 'LEGAL_ENTITY_STRUCTURE',
  'ADDRESSABLE_MARKET' = 'ADDRESSABLE_MARKET',
  'CONCENTRATION_OF_KEY_KNOW_HOW_IN_THE_TEAM' = 'CONCENTRATION_OF_KEY_KNOW_HOW_IN_THE_TEAM',
  'CURRENTLY_OFFERED_PRODUCT_LINES' = 'CURRENTLY_OFFERED_PRODUCT_LINES',
  'CUSTOMER_ACQUISITION' = 'CUSTOMER_ACQUISITION',
  'DEFENSIBILITY' = 'DEFENSIBILITY',
  'EXPANSION_POTENTIAL' = 'EXPANSION_POTENTIAL',
  'FINANCING_NEED' = 'FINANCING_NEED',
  'INDUSTRY_DISRUPTION_POTENTIAL' = 'INDUSTRY_DISRUPTION_POTENTIAL',
  'INDUSTRY_LIFECYCLE' = 'INDUSTRY_LIFECYCLE',
  'INVESTOR_SHAREHOLDER_CONCENTRATION' = 'INVESTOR_SHAREHOLDER_CONCENTRATION',
  'MANAGEMENT_TEAM_HETEROGENEITY' = 'MANAGEMENT_TEAM_HETEROGENEITY',
  'MANAGEMENT_TEAM_TRACK_RECORD' = 'MANAGEMENT_TEAM_TRACK_RECORD',
  'MARKET_ENTRY_MOMENT' = 'MARKET_ENTRY_MOMENT',
  'PRODUCT_MATURITY' = 'PRODUCT_MATURITY',
  'PRODUCT_NEED_VALIDATION' = 'PRODUCT_NEED_VALIDATION',
  'PRODUCT_SCALABILITY' = 'PRODUCT_SCALABILITY',
  'PROFIT_MARGINS' = 'PROFIT_MARGINS',
  'REVENUE_SITUATION' = 'REVENUE_SITUATION',
  'TIME_TO_PROFITABILITY' = 'TIME_TO_PROFITABILITY',
  'TRACTION_VALIDATION' = 'TRACTION_VALIDATION',
}

interface DataRoomReviewer {
  cv: FileField;
  firstName: string;
  fullName: string;
  id: number;
  lastName: string;
  image: FileField;
  socialLinkUrl: string;
  role: Roles;
}

export enum DataRoomEvaluationSteps {
  'PITCH_DECK' = 'PITCH_DECK',
  'LEGAL_COMPANY_FOUNDATION' = 'LEGAL_COMPANY_FOUNDATION',
  'TEAM_AND_SHAREHOLDERS' = 'TEAM_AND_SHAREHOLDERS',
  'PREVIOUS_FINANCING' = 'PREVIOUS_FINANCING',
  'PRODUCT_AND_DEMO' = 'PRODUCT_AND_DEMO',
  'INTELLECTUAL_PROPERTY_AND_ASSETS' = 'INTELLECTUAL_PROPERTY_AND_ASSETS',
  'BUSINESS_MODEL' = 'BUSINESS_MODEL',
  'MARKET_OPPORTUNITY' = 'MARKET_OPPORTUNITY',
  'DISTRIBUTION_AND_GROWTH_STRATEGY' = 'DISTRIBUTION_AND_GROWTH_STRATEGY',
  'FINANCIALS' = 'FINANCIALS',
  'OTHERS' = 'OTHERS',
  'DEAL_CHARACTER' = 'DEAL_CHARACTER',
}
