import { memo } from 'react';
import { getCookie, setCookies } from 'cookies-next';

import pj from 'package.json';
import { STORED_PROJECT_VERSION } from 'src/constants/common';
import { useLogout } from 'src/hooks/useLogout';
import { useGetUser } from 'src/queries/users/get-user.query';

export const CheckProjectVersion = memo(() => {
  const { user } = useGetUser();
  const { logout } = useLogout();
  const currentVersion = pj.version;
  const storedVersion = getCookie(STORED_PROJECT_VERSION);

  if (user && ['prod', 'demo', 'integration'].includes(process.env.RUNTIME_ENVIRONMENT || '')) {
    if (!storedVersion || currentVersion > storedVersion) {
      setCookies(STORED_PROJECT_VERSION, currentVersion);
      logout();
    }
  } else {
    setCookies(STORED_PROJECT_VERSION, currentVersion);
  }

  return null;
});
