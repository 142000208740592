import { FC, CSSProperties, useMemo } from 'react';

import UserIcon from 'public/svg-components/UserIcon';
import { useAsyncImage } from 'src/server-shared/async-image';
import { FileField } from 'src/interfaces/common';
import { BorderRadiusKeys } from 'src/styles/border-radius';
import { borderRadius as BR } from 'src/styles/border-radius';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

interface AQImageV2Props {
  height: number;
  width?: number;
  image?: FileField | null;
  borderRadius?: BorderRadiusKeys;
  style?: CSSProperties;
  className?: string;
  onClick?: () => void;
  gradient?: string;
}

export const ServerSharedAQImageV2: FC<AQImageV2Props> = ({
  height = 144,
  image,
  children,
  width,
  borderRadius = 'm',
  style,
  className,
  onClick,
  gradient,
}) => {
  const { loadedSrc } = useAsyncImage(
    image?.s3FileKey || '',
    !image || !image.s3FileKey || typeof image.s3Uri === 'string',
  );

  const imageStyles = useMemo(() => {
    return {
      height: `${height}px`,
      width: width ? `${width}px` : '100%',
      backgroundImage: `${gradient ? `${gradient}, ` : ''}url('${image?.s3Uri || loadedSrc}')`,
      borderRadius: BR[borderRadius],
    };
  }, [borderRadius, height, image?.s3Uri, loadedSrc, width, gradient]);

  return (
    <div
      className={classNames(classes['aq-image'], className)}
      style={{ ...imageStyles, ...style }}
      onClick={onClick}
    >
      {children}
    </div>
  );
};

export interface AQImageWithChildrenProps extends AQImageV2Props {
  placeholderIcon?: React.ReactNode;
}

export const ServerSharedAQImageWithChildren = ({
  image,
  placeholderIcon = <UserIcon />,
  ...rest
}: AQImageWithChildrenProps) => {
  return (
    <ServerSharedAQImageV2 {...rest} image={image}>
      {!image && placeholderIcon}
    </ServerSharedAQImageV2>
  );
};
