import { FC, useCallback } from 'react';
import { useRouter } from 'next/router';
import { useDispatch, useSelector } from 'react-redux';

import Error from 'public/svg-components/Error';
import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { gerErrorCode, resetErrorHandler } from 'src/store/app/reducer';
import { HOME_PAGE, NOT_AUTHORIZED_PAGES } from 'src/constants/paths';
import classes from './index.module.scss';

interface ErrorPageProps {
  onReset?: () => void;
}

const getLineTexts = (code: number | null) => {
  switch (code) {
    case 403: {
      return {
        title: 'aquaty-app.error-unauthorized.title',
        subtitle: 'aquaty-app.error-unauthorized.subtitle',
      };
    }

    case 423: {
      return {
        title: 'aquaty-app.error-already-completed.title',
        subtitle: 'aquaty-app.error-already-completed.subtitle',
      };
    }

    default:
      return {
        title: 'aquaty-app.error-404.title',
        subtitle: 'aquaty-app.error-404.subtitle',
      };
  }
};

export const ErrorPage: FC<ErrorPageProps> = ({ onReset }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const code = useSelector(gerErrorCode);
  const { title, subtitle } = getLineTexts(code);

  const goToHomePage = useCallback(() => {
    if (onReset) {
      dispatch(resetErrorHandler());
      onReset();
    }

    const isPublicPage = NOT_AUTHORIZED_PAGES.includes(router.pathname);

    if (isPublicPage) {
      document.location.reload();
    } else {
      router.push(HOME_PAGE);
    }
  }, [onReset, router, dispatch]);

  return (
    <div className={classes.container}>
      <div className="grid-row xl justify-items-center" style={{ maxWidth: 478 }}>
        <Error />
        <div className="grid-row xs">
          <ServerSharedText type="header">
            <AQString componentId={title} />
          </ServerSharedText>
          <ServerSharedText>
            <AQString componentId={subtitle} />
          </ServerSharedText>
        </div>
        <div className="button-container">
          <ServerSharedButton
            label={<AQString componentId="aquaty-generic.cta.go-to-home" />}
            style={{ width: '234px' }}
            onClick={goToHomePage}
          />
        </div>
      </div>
    </div>
  );
};
