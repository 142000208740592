import { SVGProps, memo } from 'react';

const SvgRework24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2 12C2 14.6522 3.05357 17.1957 4.92893 19.0711C6.8043 20.9464 9.34784 22 12 22C14.6522 22 17.1957 20.9464 19.0711 19.0711C20.9464 17.1957 22 14.6522 22 12C22 9.34784 20.9464 6.8043 19.0711 4.92893C17.1957 3.05357 14.6522 2 12 2C9.34784 2 6.8043 3.05357 4.92893 4.92893C3.05357 6.8043 2 9.34784 2 12Z"
      fill="#EB7305"
      stroke="#EB7305"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <g clipPath="url(#clip0_34238_33389)">
      <path
        d="M16.5117 7.48438V10.492H13.5042"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M12.0001 16.508C11.1729 16.5084 10.3614 16.2814 9.65452 15.8517C8.94761 15.4221 8.37243 14.8063 7.99187 14.0718C7.61131 13.3373 7.44 12.5123 7.49669 11.687C7.55338 10.8617 7.83588 10.0679 8.3133 9.39233C8.79072 8.71675 9.44468 8.18544 10.2037 7.85647C10.9627 7.5275 11.7976 7.41354 12.617 7.52705C13.4364 7.64056 14.2089 7.97716 14.8499 8.50007C15.4909 9.02297 15.9758 9.71205 16.2516 10.492"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_34238_33389">
        <rect width="11" height="11" fill="white" transform="translate(6.5 6.5)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgRework24Px);
export default Memo;
