import { SVGProps, memo } from 'react';

const SvgEquityOffering24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 16.375v5M12 16.375l-5 5M12 16.375l5 5M19.5 2.625h-15c-.69 0-1.25.56-1.25 1.25v11.25c0 .69.56 1.25 1.25 1.25h15c.69 0 1.25-.56 1.25-1.25V3.875c0-.69-.56-1.25-1.25-1.25ZM3.25 6.375h17.5"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgEquityOffering24Px);
export default Memo;
