import { useCallback, useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { getHeaderStepper, resetHeaderStepper, setHeaderStepper } from 'src/store/app/reducer';
import { useListActions } from './useListActions';

export const usePageStepIndicatorBasics = <T>(
  initialSteps?: StepIndicatorObject[],
  title?: string,
): {
  activeStep: T;
  goToStep: (step: T) => void;
  goToNextStep: () => void;
  goToBack: () => void;
  setSteps: (v: StepIndicatorObject[]) => void;
  steps: StepIndicatorObject[];
  getNextElement: () => StepIndicatorObject | null;
  getPreviousElement: () => StepIndicatorObject | null;
  setStepAsActive: (step: T) => void;
} => {
  const dispatch = useDispatch();
  const { steps } = useSelector(getHeaderStepper);

  const { getNextElement, getPreviousElement } = useListActions({ steps });
  const activeStep = useMemo(() => steps.find((el) => el.active)?.value as T, [steps]);

  const setSteps = useCallback(
    (v: StepIndicatorObject[]) => {
      dispatch(setHeaderStepper({ steps: v }));
    },
    [dispatch],
  );

  const goToStep = useCallback(
    (step: T) => {
      setSteps(steps.map((el) => ({ ...el, active: el.value === step })));
    },
    [setSteps, steps],
  );

  const goToNextStep = useCallback(() => {
    const nextElement = getNextElement();
    if (nextElement) {
      setSteps(
        steps.map((el) => ({
          ...el,
          active: el.value === nextElement.value,
          available: el.value === nextElement.value ? true : el.available,
          complete: el.active ? true : el.complete,
        })),
      );
    }
  }, [getNextElement, setSteps, steps]);

  const goToBack = useCallback(() => {
    const prevElement = getPreviousElement();
    if (prevElement) {
      goToStep(prevElement.value);
    }
  }, [getPreviousElement, goToStep]);

  const setStepAsActive = useCallback(
    (step: T) => {
      if (!initialSteps) return;
      const index = initialSteps.findIndex((e) => e.value === step);
      if (!index || index === -1) return;

      const steps = initialSteps.map((el, i) => ({
        ...el,
        complete: i < index,
        active: i === index,
        available: i <= index,
      }));

      setSteps(steps);
    },
    [initialSteps, setSteps],
  );

  useEffect(() => {
    return () => {
      dispatch(resetHeaderStepper());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!steps.length && initialSteps?.length) {
      dispatch(setHeaderStepper({ steps: initialSteps, title: title || '' }));
    }
  }, [dispatch, initialSteps, steps, title]);

  return {
    activeStep,
    goToStep,
    goToNextStep,
    goToBack,
    setSteps,
    steps,
    getNextElement,
    getPreviousElement,
    setStepAsActive,
  };
};
