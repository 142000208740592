import { SVGProps, memo } from 'react';

const SvgViewOff = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m4.317 19.5 15.625-15M9.5 17.875c.81.253 1.652.379 2.5.375 3.417.058 6.883-2.342 9.017-4.7a1.375 1.375 0 0 0 0-1.85 16.716 16.716 0 0 0-2.559-2.3M16.242 8.058A9.425 9.425 0 0 0 12 7c-3.333-.058-6.833 2.292-9.017 4.7a1.375 1.375 0 0 0 0 1.85 16.666 16.666 0 0 0 4.109 3.333"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.5 14.558a3.208 3.208 0 0 1 4.583-4.4M15.125 12.625A3.124 3.124 0 0 1 12 15.75"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgViewOff);
export default Memo;
