import { AppPathTabs } from 'src/interfaces/app-navigation';
import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

interface AnchorHederTabsProps {
  items?: AppPathTabs[];
  changeActiveTab: (item: AppPathTabs) => void;
  active: string;
}

export const AnchorHederTabs = ({ items, active, changeActiveTab }: AnchorHederTabsProps) => {
  return (
    <ul className={classes.list}>
      {(items || []).map((el) => {
        const isActive = active === el.attribute;
        return (
          <li
            className={classNames('flex', classes.list__item)}
            key={el.attribute}
            onClick={() => changeActiveTab(el)}
          >
            <ServerSharedText
              type="label-s"
              color={isActive ? 'primary600' : 'grey500'}
              className={classNames(classes.text, { [classes['text--active']]: isActive })}
            >
              <AQString componentId={el.title} />
            </ServerSharedText>
          </li>
        );
      })}
    </ul>
  );
};
