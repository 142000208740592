import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { DataRoomGrowthPlan } from 'src/interfaces/data-room';
import { fileFields } from '../common.strings';

export const growthPlanGQLString = `
businessModelAttachments {
  ${fileFields}  
}
businessModelDescription
customerPersonasAttachments {
  ${fileFields}  
}
customerPersonasDescription
growthPlanAttachments {
  ${fileFields}  
}
growthPlanDescription
revenueStreamsDescription,
revenueStreamsAttachments {
  ${fileFields}  
},
unitEconomicsDescription,
unitEconomicsAttachments {
  ${fileFields}  
},
`;

const dataRoomGrowthPlanMutation = gql`
  mutation saveDataRoomGrowthPlan($equityOfferingId: Long!, $growthPlan: DataRoomGrowthPlanInput) {
    saveDataRoomGrowthPlan(equityOfferingId: $equityOfferingId, growthPlan: $growthPlan) {
      ${growthPlanGQLString}
    }
  }
`;

interface UseSaveDataRoomGrowthPlanMutation {
  equityOfferingId: number;
  growthPlan: Omit<
    DataRoomGrowthPlan,
    'customerPersonasDescription' | 'customerPersonasAttachments'
  >;
}

export const useSaveDataRoomGrowthPlan = () => {
  const [mutateDataRoomGrowthPlan, { loading, data }] = useMutation(dataRoomGrowthPlanMutation);

  const saveDataRoomGrowthPlan = useCallback(
    async (variables: UseSaveDataRoomGrowthPlanMutation) => {
      await mutateDataRoomGrowthPlan({ variables });
    },
    [mutateDataRoomGrowthPlan],
  );
  return { saveDataRoomGrowthPlan, loading, savedDataRoomGrowthPlan: data?.saveDataRoomGrowthPlan };
};
