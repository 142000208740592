export type BorderRadiusKeys = 'xs' | 's' | 'm' | 'l' | 'xxl' | 'round';

export const borderRadius: Record<BorderRadiusKeys, string> = {
  xs: '3px',
  s: '6px',
  m: '12px',
  l: '24px',
  xxl: '100px',
  round: '50%',
};
