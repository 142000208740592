import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { InvestmentOffering } from 'src/interfaces/offering';
import { fileFields, investmentOfferingOpex } from '../common.strings';

export const investmentOfferingGQLString = `
fundingCommitments {
  amount
  comment
  equityOfferingId
  id
  investorImage {
    ${fileFields}
  }
  investorName
  linkedInUrl
  websiteUrl
  signingDate
  investedViaPlatform
}
maxInvestmentAmount
preMoneyValuationFounderIndication
requiredInvestmentAmount
runaway
completedStates
fundingStage
equityOfferingId
opexes {
  ${investmentOfferingOpex} 
}
`;

export const investmentOfferingQuery = gql`
  query investmentOffering($equityOfferingId: Long!) {
    investmentOffering(equityOfferingId: $equityOfferingId) {
      ${investmentOfferingGQLString}
    }
  }
`;

export function useGetInvestmentOffering(id: number, params?: QueryHookOptions) {
  const { data, loading } = useQuery<{
    investmentOffering: InvestmentOffering;
  }>(investmentOfferingQuery, {
    variables: { equityOfferingId: id },
    fetchPolicy: 'no-cache',
    ...params,
  });

  return {
    investmentOffering: data?.investmentOffering || null,
    loading,
  };
}
