import { useCallback, useState, useMemo, useEffect } from 'react';
import { getCookie, removeCookies, setCookies } from 'cookies-next';
import { useRouter } from 'next/navigation';
import { Auth } from 'aws-amplify';

import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { Roles, UserRegistrationStatus } from 'src/interfaces/user';
import { VentureRoleInfoModal } from '../modals/ventureRolesInfoModal';
import { COGNITO_USER_ID, INVITATION_TOKEN } from 'src/constants/registration';
import { PROFILE_COMPLETION_PAGE } from 'src/constants/paths';
import { useSaveUser } from 'src/queries/registration.query';
import { COGNITO_JWT } from 'src/constants/loggedIn';
import { AmplifyAuthRefreshSession } from 'src/interfaces/auth';
import classes from '../index.module.scss';
import { classNames } from 'src/utils/classNames';
import cardClasses from 'src/server-shared/venture-parts/select-venture/index.module.scss';
import { StepProps } from '../shared';
import { RegistrationState } from 'src/app/p/[offeringId]/soft-auth/types';
import { mixpanelEvents, useMixpanel } from 'src/hooks/useMixpanel';

const roles = [
  {
    id: 1,
    role: Roles.VENTURE_FOUNDER,
    roleString: 'aquaty-app.registration.account-creation.founder.title',
    roleSubtitleString: 'aquaty-app.registration.account-creation.founder.subtitle',
  },
  {
    id: 2,
    role: Roles.SOFT_REGISTERED_VENTURE_INVESTOR,
    roleString: 'aquaty-app.registration.account-creation.investor.title',
    roleSubtitleString: 'aquaty-app.registration.account-creation.investor.subtitle',
  },
  {
    id: 3,
    role: Roles.INVESTMENT_MANAGER,
    roleString: 'aquaty-app.registration.account-creation.investment-manager.title',
    roleSubtitleString: 'aquaty-app.registration.account-creation.investment-manager.subtitle',
  },
];

export const roleLabel: Record<string, string> = {
  [Roles.VENTURE_FOUNDER]: 'venture_founder',
  [Roles.VENTURE_INVESTOR]: 'venture_investor',
  [Roles.INVESTMENT_MANAGER]: 'investment_manager',
};

export const refreshSession = async (callback?: () => void) => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = cognitoUser.signInUserSession;
    cognitoUser.refreshSession(
      currentSession.refreshToken,
      (_: any, session: AmplifyAuthRefreshSession) => {
        setCookies(COGNITO_JWT, session.accessToken.jwtToken);
        if (callback) callback();
      },
    );
  } catch (e) {
    console.log('error', e);
  }
};

export interface SetupVentureRoleProps extends StepProps {
  isWaitList?: boolean;
  registrationData?: RegistrationState;
}

export const SetupVentureRole = ({
  isWaitList = false,
  goToNextStep,
  registrationData,
  showError,
}: SetupVentureRoleProps) => {
  const { track, mixpanelLogin } = useMixpanel();
  const router = useRouter();
  const { saveUser, loading: isFetching, savedUser } = useSaveUser();
  const it = getCookie(INVITATION_TOKEN);
  const tokenInTheLink = window.location.search.split('it=')[1] || it;
  const [values, setValues] = useState<any>({
    roleAssignments: [{ role: tokenInTheLink ? Roles.SOFT_REGISTERED_VENTURE_INVESTOR : '' }],
  });

  const role = useMemo(() => values.roleAssignments[0].role, [values.roleAssignments]);

  const [showRoleInfoModal, setShowRoleInfoModal] = useState<boolean>(false);
  const openRoleInfoModal = useCallback(() => setShowRoleInfoModal(true), []);
  const closeRoleInfoModal = useCallback(() => setShowRoleInfoModal(false), []);

  const nextPage = useCallback(() => {
    if (isWaitList) {
      goToNextStep();
      return;
    }
    router.push(PROFILE_COMPLETION_PAGE);
  }, [goToNextStep, isWaitList, router]);

  const saveData = async () => {
    const { firstName = '', lastName = '', linkedInUrl = '' } = registrationData || {};

    const cognitoUserId = getCookie(COGNITO_USER_ID) as string;
    const userFirstName = firstName ? { firstName } : {};
    const userLastName = lastName ? { lastName } : {};
    const newValues = !values.cognitoUserId
      ? { ...values, ...userFirstName, ...userLastName, linkedInUrl, cognitoUserId }
      : { ...values, ...userFirstName, ...userLastName, linkedInUrl };
    const invitationToken = tokenInTheLink ? { invitationToken: tokenInTheLink } : {};
    const registrationStatus = isWaitList
      ? { registrationStatus: UserRegistrationStatus.WAITLIST }
      : {};

    try {
      await saveUser({ ...newValues, ...registrationStatus, ...invitationToken });
      removeCookies(INVITATION_TOKEN);
    } catch (err) {
      showError();
    }
  };

  useEffect(() => {
    if (savedUser) {
      const { firstName = '', lastName = '' } = registrationData || {};
      const userFirstName = firstName ? { firstName } : {};
      const userLastName = lastName ? { lastName } : {};
      const savedUserFirstName = savedUser.firstName ? { firstName: savedUser.firstName } : {};
      const savedUserLastName = savedUser.lastName ? { lastName: savedUser.lastName } : {};
      const userNames = {
        ...userFirstName,
        ...savedUserFirstName,
        ...userLastName,
        ...savedUserLastName,
      };
      const cognitoUserId = getCookie(COGNITO_USER_ID) as string;
      const SignUpWithLinkedIn = cognitoUserId.includes('linkedin_linkedin');
      if (!isWaitList) {
        mixpanelLogin(savedUser.id.toString());
        track(mixpanelEvents.userRegistration, {
          ['Role']: roleLabel[values.roleAssignments[0].role],
          ['SignUp Method']: [SignUpWithLinkedIn ? 'LinkedIn' : 'Email'],
          userId: savedUser.id,
          userEmail: savedUser.email,
          registrationStatus: savedUser.registrationStatus,
        });
      }
      if (isWaitList) {
        track(mixpanelEvents.waitlistInitialSignUp, {
          userId: savedUser.id,
          userEmail: savedUser.email,
          ...userNames,
          ['Role']: roleLabel[values.roleAssignments[0].role],
          ['SignUp Method']: [SignUpWithLinkedIn ? 'LinkedIn' : 'Email'],
        });
      }
      refreshSession(nextPage);
    }
  }, [
    mixpanelLogin,
    nextPage,
    savedUser,
    track,
    values.roleAssignments,
    isWaitList,
    registrationData,
  ]);

  return (
    <div className={classNames(classes['step-wrapper'])}>
      <div className="grid-row justify-items-center">
        <div className="grid-row xs justify-items-center">
          <ServerSharedText type="header" className="text-align-center">
            <AQString componentId="aquaty-app.registration.venture-role.title" />
          </ServerSharedText>

          <div className="grid-row none justify-items-center">
            <ServerSharedText color="grey500" className="text-align-center">
              <AQString componentId="aquaty-app.registration.venture-role.subtitle" />{' '}
            </ServerSharedText>
            <ServerSharedText
              type="label"
              color="primary500"
              className="text-align-center"
              onClick={openRoleInfoModal}
              clickable
            >
              <AQString componentId="aquaty-app.registration.venture-role.link" />.
            </ServerSharedText>
          </div>
        </div>
        <div className="grid-row xs">
          {roles.map((el) => {
            const isActive = role === el.role;
            return (
              <div
                key={el.id}
                onClick={() => setValues({ roleAssignments: [{ role: el.role }] })}
                className={classNames(cardClasses.card, {
                  [cardClasses['card--active']]: isActive,
                })}
              >
                <div
                  className={classNames(cardClasses.indicator, {
                    [cardClasses['indicator--active']]: isActive,
                  })}
                />
                <div className="grid-row xxs">
                  <ServerSharedText type="label" color={isActive ? 'primary600' : 'grey900'}>
                    <AQString componentId={el.roleString} />
                  </ServerSharedText>
                  <ServerSharedText color="grey500">
                    <AQString componentId={el.roleSubtitleString} />
                  </ServerSharedText>
                </div>
              </div>
            );
          })}
        </div>

        <ServerSharedButton
          variant="inform"
          label={<AQString componentId="aquaty-generic.cta.continue" />}
          disabled={!role || isFetching}
          style={{ width: '100%' }}
          onClick={saveData}
        />
      </div>
      <VentureRoleInfoModal show={showRoleInfoModal} closeEvent={closeRoleInfoModal} />
    </div>
  );
};
