import { useRouter } from 'next/router';

import Current24Px from 'public/svg-components/Current24Px';
import ToDo24Px from 'public/svg-components/ToDo24Px';
import CheckCircleM24Px from 'public/svg-components/CheckCircleM24Px';
import TimeClockCircleM24Px from 'public/svg-components/TimeClockCircleM24Px';
import AlertCircle24Px from 'public/svg-components/AlertCircle24Px';
import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import {
  HOME_PAGE,
  INVESTOR_PERSONAL_DATA_PAGE,
  INVESTOR_ACCREDITATION_PAGE,
  INVESTOR_INVESTMENT_ACCOUNT_IDENTIFICATION_PAGE,
  PORTFOLIO_PAGE,
} from 'src/constants/paths';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ERoleAccreditationStatus, User, UserIdentificationStatus } from 'src/interfaces/user';
import { useSaveIdentificationDetails } from 'src/queries/users/user-identification-details.query';
import { useGetUserInvestmentAccounts } from 'src/queries/users/investment-account';
import { useGetRoleAccreditation } from 'src/queries/role-accreditation';
import { ServerSharedLoader } from 'src/server-shared/loader';
import { UserInvestmentAccount, WalletVerificationStatus } from 'src/interfaces/profile-completion';
import {
  InvestorIdentificationStepCardProps,
  ProfileCompetitionContentProps,
  StepCardProps,
} from '../types';
import { fiveSeconds, tenMin } from '../shared';
import { RegistrationStep } from '../components/step';
import { identificationIsPassed } from '../founder';
import { IdentificationButtons } from '../components/identification-buttons';
import { RoleAccreditationDetails } from 'src/interfaces/roleAccreditation';
import { accreditationIsPassed } from '../investment-manager';
import { mixpanelEvents, useMixpanel } from 'src/hooks/useMixpanel';
import { useGetUserInvestmentSpecialPurposeVehicle } from 'src/queries/venture/get-user-investment-specialpurpose-vehicle';
import { refreshSession } from 'src/ui/auth/signup/nested-pages/registration/steps/venture-role';

const isPartnersInvestorPersonalDataIsFilled = (
  user: User,
  accreditationDetails: RoleAccreditationDetails | null,
): boolean => {
  const isPersonalDataIsFilled = [user.firstName, user.lastName, user.gender, user.title].every(
    Boolean,
  );

  const isAccreditationIsFinished = accreditationDetails?.industries
    ? accreditationDetails?.industries?.length >= 0
    : false;

  return isPersonalDataIsFilled && isAccreditationIsFinished;
};

export const hasVerifiedWallet = (userInvestmentAccounts: UserInvestmentAccount[]) =>
  userInvestmentAccounts.some(
    (el) => el.walletDetails?.verificationStatus === WalletVerificationStatus.VERIFIED,
  );

type InvestorRegistrationStage = 'PERSONAL_DATA' | 'IDENTIFICATION' | 'ACCREDITATION';
// | 'WALLET_SETUP';

const identificationIcons = {
  TODO: <ToDo24Px width={16} height={16} viewBox="0 0 24 24" />,
  INPROGRESS: <Current24Px width={16} height={16} viewBox="0 0 24 24" />,
  COMPLETED: <CheckCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
  WAITING: <TimeClockCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
  FAILED: <AlertCircle24Px width={16} height={16} viewBox="0 0 24 24" />,
};

interface InvestorRegistrationStepsProps {
  user: User;
  refetch: () => void;
  userRoleAccreditationDetails: RoleAccreditationDetails;
  showSkipButton?: boolean;
}

export const InvestorRegistrationSteps = ({
  user,
  refetch,
  userRoleAccreditationDetails,
  showSkipButton = false,
}: InvestorRegistrationStepsProps) => {
  const { track } = useMixpanel();
  const { saveIdentification } = useSaveIdentificationDetails();
  const { push } = useRouter();

  const [showRetryButton, setShowRetryButton] = useState(true);

  const { userInvestmentAccounts } = useGetUserInvestmentAccounts({ fetchPolicy: 'no-cache' });
  const initialRefetchRef = useRef(false);

  const ventureId = useMemo(() => {
    if (!userInvestmentAccounts.length) return undefined;
    const holding = userInvestmentAccounts.find((el) => el.holdingId);
    if (holding) return holding.holdingId;
    return undefined;
  }, [userInvestmentAccounts]);

  const { userInvestmentSpecialPurposeVehicle, refetch: refetchSPV } =
    useGetUserInvestmentSpecialPurposeVehicle(Number(ventureId));

  const goToPersonalData = useCallback(() => {
    push(INVESTOR_PERSONAL_DATA_PAGE);
  }, [push]);

  const goToAccreditation = useCallback(() => {
    push(INVESTOR_ACCREDITATION_PAGE);
  }, [push]);

  const goToPortfolioPage = useCallback(() => {
    push(PORTFOLIO_PAGE);
  }, [push]);

  const activeStage = useMemo<InvestorRegistrationStage>(() => {
    if (!isPartnersInvestorPersonalDataIsFilled(user, userRoleAccreditationDetails))
      return 'PERSONAL_DATA';

    const isIdentificationIsFinished =
      user?.identificationDetails?.status === UserIdentificationStatus.SUCCESS;
    if (!isIdentificationIsFinished) return 'IDENTIFICATION';

    const isRoleAccreditationIsFinished = accreditationIsPassed(user);
    if (!isRoleAccreditationIsFinished) return 'ACCREDITATION';
    return 'ACCREDITATION';
  }, [user, userRoleAccreditationDetails]);

  const personalDataStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'PERSONAL_DATA') {
      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.personal-data-preferences.subtitle',
      };
    }
    return { status: 'COMPLETED', showSubtitle: false };
  }, [activeStage]);

  const spvMembers = useMemo(() => {
    if (
      userInvestmentSpecialPurposeVehicle &&
      userInvestmentSpecialPurposeVehicle.beneficialOwners.length > 0
    ) {
      const statuses = userInvestmentSpecialPurposeVehicle.beneficialOwners.map((el) =>
        el.identificationStatus ? el.identificationStatus : UserIdentificationStatus.NOT_STARTED,
      );

      if (statuses.some((el) => el === UserIdentificationStatus.FAILED)) {
        return {
          status: 'FAILED',
          icon: <AlertCircle24Px width={16} height={16} viewBox="0 0 24 24" />,
        };
      }
      if (
        statuses.some((el) =>
          [UserIdentificationStatus.PENDING, UserIdentificationStatus.NOT_STARTED].includes(el),
        )
      ) {
        return {
          status: 'WAITING',
          icon: <TimeClockCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
        };
      }
      return {
        status: 'COMPLETED',
        icon: <CheckCircleM24Px width={16} height={16} viewBox="0 0 24 24" />,
      };
    }
    return null;
  }, [userInvestmentSpecialPurposeVehicle]);

  const identificationStep = useMemo<InvestorIdentificationStepCardProps>(() => {
    if (activeStage === 'ACCREDITATION')
      return {
        status: spvMembers?.status === 'WAITING' ? 'WAITING' : 'COMPLETED',
        personalStatus: 'COMPLETED',
        showSubtitle: !!spvMembers,
        subtitle: (
          <AQString
            componentId="aquaty-app.profile-completion.investment-accounting-identification.success.subtitle"
            variables={{ email: user.email || '' }}
          />
        ),
      };

    if (activeStage === 'IDENTIFICATION') {
      if (user.identificationDetails?.status === UserIdentificationStatus.FAILED) {
        return {
          status: 'FAILED',
          personalStatus: 'FAILED',
          subtitle: 'aquaty-app.profile-completion.founder.identification.failed.subtitle',
        };
      }

      if (user.identificationDetails?.status === UserIdentificationStatus.PENDING) {
        return {
          status: 'WAITING',
          personalStatus: 'WAITING',
          subtitle: (
            <AQString
              componentId="aquaty-app.profile-completion.investment-accounting-identification.pending.subtitle"
              variables={{ email: user.email || '' }}
            />
          ),
        };
      }

      return {
        status: 'INPROGRESS',
        personalStatus: 'INPROGRESS',
        subtitle:
          'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
      };
    }

    return {
      status: 'TODO',
      personalStatus: 'TODO',
      showSubtitle: false,
      subtitle:
        'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
    };
  }, [activeStage, spvMembers, user.email, user.identificationDetails?.status]);

  const investorAccreditation = useMemo(() => {
    return user.roleAssignments[0];
  }, [user]);

  const accreditationStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'ACCREDITATION') {
      if (investorAccreditation.verificationStatus === ERoleAccreditationStatus.REFUSED) {
        return {
          status: 'FAILED',
          subtitle: (
            <ServerSharedText color="grey500" type="text-small">
              {investorAccreditation.verificationNotes}
            </ServerSharedText>
          ),
        };
      }

      if (investorAccreditation.verificationStatus === ERoleAccreditationStatus.WAITING) {
        return {
          status: 'WAITING',
          subtitle: (
            <AQString
              componentId="aquaty-app.profile-completion.investor-accreditation.waiting.subtitle"
              variables={{ email: user.email || '' }}
            />
          ),
        };
      }

      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.investor-accreditation.not-started.subtitle',
      };
    }

    return {
      status: 'TODO',
      subtitle: 'aquaty-app.profile-completion.investor-accreditation.not-started.subtitle',
      showSubtitle: false,
    };
  }, [activeStage, investorAccreditation, user.email]);

  useEffect(() => {
    let identificationInterval: any = undefined;
    let userInterval: any = undefined;

    const refetchData = () => {
      saveIdentification();
      if (ventureId) {
        refetchSPV();
      }
    };

    if (user?.identificationDetails?.status === UserIdentificationStatus.PENDING) {
      setShowRetryButton(true);
      if (!initialRefetchRef.current) {
        initialRefetchRef.current = true;
        refetchData();
      }

      identificationInterval = setInterval(() => {
        refetchData();
      }, tenMin);
      userInterval = setInterval(() => refetch(), fiveSeconds);
    } else {
      clearInterval(identificationInterval);
      clearInterval(userInterval);
    }

    return () => {
      clearInterval(identificationInterval);
      clearInterval(userInterval);
    };
  }, [user, saveIdentification, refetch, refetchSPV, ventureId, showRetryButton]);

  useEffect(() => {
    if (
      isPartnersInvestorPersonalDataIsFilled(user, userRoleAccreditationDetails) &&
      identificationIsPassed(user) &&
      accreditationIsPassed(user)
    ) {
      track(mixpanelEvents.fullProfileCompletion, {
        ['Role']: 'venture_investor',
      });
      refreshSession();
      push(HOME_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userRoleAccreditationDetails, userInvestmentAccounts, push]);

  return (
    <div className="grid-row m">
      <RegistrationStep
        title="aquaty-app.profile-completion.personal-data-preferences.title"
        {...personalDataStep}
        index={1}
        button={
          activeStage === 'PERSONAL_DATA' ? (
            <>
              {showSkipButton && (
                <ServerSharedButton
                  label={<AQString componentId="aquaty-generic.cta.complete-profile-later" />}
                  variant="secondary"
                  onClick={goToPortfolioPage}
                />
              )}
              <ServerSharedButton
                onClick={goToPersonalData}
                label={
                  <AQString componentId="aquaty-app.profile-completion.start-personal-data-preferences" />
                }
                variant="inform"
              />
            </>
          ) : null
        }
      />

      <RegistrationStep
        title="aquaty-app.profile-completion.investment-accounting-identification.title"
        {...identificationStep}
        index={2}
        button={
          activeStage === 'IDENTIFICATION' ? (
            <>
              {showSkipButton && (
                <ServerSharedButton
                  label={<AQString componentId="aquaty-generic.cta.complete-profile-later" />}
                  variant="secondary"
                  onClick={goToPortfolioPage}
                />
              )}
              <IdentificationButtons
                path={INVESTOR_INVESTMENT_ACCOUNT_IDENTIFICATION_PAGE}
                showRetryButton={showRetryButton}
                status={user?.identificationDetails?.status}
                buttonLabel="aquaty-app.profile-completion.start-investment-account-identification"
              />
            </>
          ) : null
        }
      >
        {spvMembers && (
          <div className="grid-row xs" style={{ marginTop: '4px' }}>
            <div className="grid-column template-mc-1fr xs">
              {identificationIcons[identificationStep.personalStatus]}
              <ServerSharedText color="grey500" type="text-small">
                <AQString componentId="aquaty-app.profile-completion.investment-accounting-identification.personal" />
              </ServerSharedText>
            </div>

            <div className="grid-column template-mc-1fr xs">
              {spvMembers.icon}
              <ServerSharedText color="grey500" type="text-small">
                <AQString componentId="aquaty-app.profile-completion.investment-accounting-identification.spv" />
              </ServerSharedText>
            </div>
          </div>
        )}
      </RegistrationStep>

      <RegistrationStep
        title="aquaty-app.profile-completion.investor-accreditation.title"
        {...accreditationStep}
        index={3}
        button={
          activeStage === 'ACCREDITATION' ? (
            <>
              {showSkipButton && (
                <ServerSharedButton
                  label={<AQString componentId="aquaty-generic.cta.complete-profile-later" />}
                  variant="secondary"
                  onClick={goToPortfolioPage}
                />
              )}
              {(!investorAccreditation.verificationStatus ||
                investorAccreditation.verificationStatus ===
                  ERoleAccreditationStatus.NOT_STARTED) && (
                <ServerSharedButton
                  onClick={goToAccreditation}
                  label={
                    <AQString componentId="aquaty-app.profile-completion.start-investor-accreditation" />
                  }
                  variant="inform"
                />
              )}
            </>
          ) : null
        }
      />
    </div>
  );
};

export const InvestorRegistration = ({ user, refetch }: ProfileCompetitionContentProps) => {
  const { userRoleAccreditationDetails } = useGetRoleAccreditation();

  if (!user || !userRoleAccreditationDetails) {
    return <ServerSharedLoader />;
  }

  return (
    <div className="grid-row">
      <div className="grid-row xs">
        <ServerSharedText type="header">
          <AQString componentId="aquaty-app.profile-completion.founder.title" />
        </ServerSharedText>

        <ServerSharedText color="grey500" as="pre">
          <AQString
            componentId="aquaty-app.profile-completion.founder.subtitle"
            variables={{ email: user.email || '' }}
          />
        </ServerSharedText>
      </div>

      <InvestorRegistrationSteps
        user={user}
        refetch={refetch}
        userRoleAccreditationDetails={userRoleAccreditationDetails}
        showSkipButton
      />
    </div>
  );
};
