import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedModal } from 'src/server-shared/modals/modal';

interface IVentureRoleInfoModalProps {
  show: boolean;
  closeEvent: () => void;
}

export const VentureRoleInfoModal = ({ show, closeEvent }: IVentureRoleInfoModalProps) => {
  return (
    <ServerSharedModal
      show={show}
      closeEvent={closeEvent}
      header={{
        title: (
          <AQString componentId="aquaty-app.registration.account-creation.venture-roles.title" />
        ),
      }}
    >
      <div className="grid-row">
        <div className="grid-row none">
          <ServerSharedText type="label">
            <AQString componentId="aquaty-app.registration.account-creation.founder.title" />
          </ServerSharedText>

          <ServerSharedText color="grey500">
            <AQString componentId="aquaty-app.registration.account-creation.founder.description" />
          </ServerSharedText>
        </div>

        <div className="grid-row none">
          <ServerSharedText type="label">
            <AQString componentId="aquaty-app.registration.account-creation.investor.title" />
          </ServerSharedText>

          <ServerSharedText color="grey500">
            <AQString componentId="aquaty-app.registration.account-creation.investor.description" />
          </ServerSharedText>
        </div>

        <div className="grid-row none">
          <ServerSharedText type="label">
            <AQString componentId="aquaty-generic.cta.investment-manager" />
          </ServerSharedText>

          <ServerSharedText color="grey500" as="pre">
            <AQString componentId="aquaty-app.registration.account-creation.investment-manager.description" />
          </ServerSharedText>
        </div>
      </div>
    </ServerSharedModal>
  );
};
