import { useState, useCallback, useMemo } from 'react';

import { AQString } from 'src/shared/AQString';
import { ServerSharedModal } from 'src/server-shared/modals/modal';
import { Venture } from 'src/interfaces/venture';
import { SelectVenture } from 'src/server-shared/venture-parts/select-venture';
import { useGetSuggestedVentures } from 'src/queries/ventures.query';

interface AddVentureModalProps {
  show: boolean;
  closeEvent: () => void;
  onSetVenture: (venture: { refId: string }) => void;
  userVenturesRefIds?: string[];
  userVentures?: Venture[];
}

export const AddVentureModal = ({
  show,
  userVentures,
  closeEvent,
  onSetVenture,
}: AddVentureModalProps) => {
  const [selectedVenture, setSelectedVenture] = useState<string | undefined>(undefined);

  const { ventures: suggestedVentures, loading } = useGetSuggestedVentures();

  const initVentures = useMemo(() => {
    // we don't want to show Ventures if user already has it
    return suggestedVentures.filter(({ refId }) => !userVentures?.find((e) => e.refId === refId));
  }, [suggestedVentures, userVentures]);

  const onCloseModal = useCallback(() => {
    closeEvent();
  }, [closeEvent]);

  const handleConfirm = useCallback(async () => {
    if (selectedVenture) {
      onSetVenture({ refId: selectedVenture });
    }
    onCloseModal();
  }, [onCloseModal, onSetVenture, selectedVenture]);

  const onVentureChecked = useCallback((venture) => {
    setSelectedVenture(venture.refId);
  }, []);

  return (
    <ServerSharedModal
      show={show}
      closeEvent={onCloseModal}
      header={{
        title: (
          <AQString componentId={'aquaty-app.my-ventures.register-another.select-venture.title'} />
        ),
        subtitle: (
          <AQString
            componentId={'aquaty-app.my-ventures.register-another.select-venture.subtitle'}
          />
        ),
      }}
      bodyInlineStyles={{ overflowY: 'unset' }}
      footer={{ onConfirm: handleConfirm, hasLeftButton: true }}
      loading={loading}
    >
      <SelectVenture
        suggestedVentures={initVentures}
        selectedId={selectedVenture}
        onChecked={onVentureChecked}
        userVentures={userVentures}
      />
    </ServerSharedModal>
  );
};
