import { useCallback, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';

import UserIcon from 'public/svg-components/UserIcon';
import Settings24Px from 'public/svg-components/Settings24Px';
import Logout24Px from 'public/svg-components/Logout24Px';
import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { useLogout } from 'src/hooks/useLogout';
import { Roles, User, UserIdentificationStatus } from 'src/interfaces/user';
import { ServerSharedAQImageV2 } from 'src/server-shared/aq-image';
import { MENU_PERSONAL_DATA_PAGE, PROFILE_COMPLETION_PAGE } from 'src/constants/paths';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';
import Warning24Px from 'public/svg-components/Warning24Px';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { ServerSharedSimpleProgress } from 'src/server-shared/funding-stats/progress-bar';
import { RoleAccreditationDetails } from 'src/interfaces/roleAccreditation';
import {
  accreditationIsPassed,
  isPartnersInvestorPersonalDataIsFilled,
} from 'src/ui/profile-completion/investment-manager';
import { refreshSession } from 'src/ui/auth/signup/nested-pages/registration/steps/venture-role';

interface SideBarUserMenuProps {
  user: User | null;
  onHide: () => void;
  role?: Roles;
  userRoleAccreditationDetails: RoleAccreditationDetails | null;
  refetchUser: () => void;
}

export const SidebarUserMenu = ({
  user,
  onHide,
  userRoleAccreditationDetails,
  role,
  refetchUser,
}: SideBarUserMenuProps) => {
  const { logout } = useLogout();
  const { push } = useRouter();

  const goToProfilePage = useCallback(() => {
    push(MENU_PERSONAL_DATA_PAGE);
  }, [push]);

  const goGoProfileCompletionPage = useCallback(() => {
    push(PROFILE_COMPLETION_PAGE);
  }, [push]);

  const registrationProgress = useMemo(() => {
    if (!user) return 0;
    const isRoleAccreditationIsFinished = accreditationIsPassed(user);
    const isIdentificationIsFinished =
      user?.identificationDetails?.status === UserIdentificationStatus.SUCCESS;
    if (isIdentificationIsFinished && isRoleAccreditationIsFinished) return 3;

    if (isIdentificationIsFinished) return 2;
    return isPartnersInvestorPersonalDataIsFilled(user, userRoleAccreditationDetails) ? 1 : 0;
  }, [user, userRoleAccreditationDetails]);

  useEffect(() => {
    if (role === Roles.SOFT_REGISTERED_VENTURE_INVESTOR && registrationProgress === 3) {
      refreshSession();
      refetchUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationProgress, role]);

  return (
    <div className={classes['user-menu']} onPointerLeave={onHide}>
      <div className={classes['user-menu__menu']}>
        {role === Roles.SOFT_REGISTERED_VENTURE_INVESTOR && (
          <div className={classNames(classes['user-menu__block'], classes['grey800'])}>
            <div
              className={classNames(
                'flex m align-items-start justify-content-start',
                classes['user-menu__complete-profile'],
              )}
            >
              <Warning24Px />
              <div className="grid-row m">
                <div className="grid-row xxs">
                  <ServerSharedText color="body" type="label">
                    <AQString componentId="aquaty-app.deal-room.complete-registration-modal.title" />
                  </ServerSharedText>
                  <ServerSharedText color="body" type="text-small">
                    <AQString
                      componentId="aquaty-app.complete-profile-modal.subtitle"
                      variables={{ email: user?.email || '' }}
                    />
                  </ServerSharedText>
                </div>

                <div className="grid-column template-mc-1fr align-items-center">
                  <ServerSharedText color="body">{registrationProgress}/3</ServerSharedText>

                  <ServerSharedSimpleProgress
                    current={registrationProgress}
                    expected={3}
                    color="warning600"
                    containerColor="bodyOpacity15"
                  />
                </div>
                <div>
                  <ServerSharedButton
                    label={<AQString componentId="aquaty-generic.cta.complete-profile" />}
                    variant="text"
                    onClick={goGoProfileCompletionPage}
                  />
                </div>
              </div>
            </div>
          </div>
        )}
        <div className={classes['user-menu__block']}>
          <div className="grid-row none">
            <div
              className={classNames(
                classes['user-menu__item'],
                classes['user-menu__item--soft-disabled'],
              )}
            >
              <ServerSharedAQImageV2
                image={user?.image}
                width={24}
                height={24}
                borderRadius="round"
              >
                {!user?.image && (
                  <ServerSharedText>
                    {(user?.firstName || '').charAt(0).toUpperCase()}
                  </ServerSharedText>
                )}
              </ServerSharedAQImageV2>

              <ServerSharedText color="body" ellipsis>
                {user?.email}
              </ServerSharedText>
            </div>

            <div onClick={goToProfilePage} className={classes['user-menu__item']}>
              <UserIcon className={classes['stroke-style']} />
              <ServerSharedText color="body">
                <AQString componentId="aquaty-generic.menu.personal-data.title" />
              </ServerSharedText>
            </div>

            <div
              className={classNames(
                classes['user-menu__item'],
                classes['user-menu__item--disabled'],
              )}
            >
              <Settings24Px className={classes['stroke-style']} />
              <ServerSharedText color="body">
                <AQString componentId="aquaty-generic.menu.settings.title" />
              </ServerSharedText>
            </div>
          </div>
        </div>

        <div className={classes['user-menu__footer']}>
          <div onClick={logout} className={classes['user-menu__item']}>
            <Logout24Px className={classes['stroke-style']} />
            <ServerSharedText color="body">
              <AQString componentId="aquaty-generic.cta.logout" />
            </ServerSharedText>
          </div>
        </div>
      </div>
    </div>
  );
};
