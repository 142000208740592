import { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Close24Px from 'public/svg-components/Close24Px';
import CheckCircleOutlineM24Px from 'public/svg-components/CheckCircleOutlineM24Px';
import CloseCircleOutline24Px from 'public/svg-components/CloseCircleOutline24Px';
import Alert from 'public/svg-components/Alert';
import InformationCircle24Px from 'public/svg-components/InformationCircle24Px';
import { getNotifications } from 'src/store/app/reducer';
import { removeLastNotification } from 'src/store/app/reducer';
import { AQString } from '../AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';

export type NotificationType = 'success' | 'error' | 'warning' | 'info';

export interface Notification {
  title: string;
  message: string;
  type: NotificationType;
}

const notificationTypes = {
  success: 'success',
  error: 'error',
  warning: 'warning',
  info: 'info',
};

const duration = 5000;
const NotificationIcon = ({ type }: { type: NotificationType }) => {
  const iconByType = {
    [notificationTypes.success]: <CheckCircleOutlineM24Px />,
    [notificationTypes.error]: <CloseCircleOutline24Px />,
    [notificationTypes.warning]: <Alert />,
    [notificationTypes.info]: <InformationCircle24Px />,
  };

  return (
    <div className={classNames(classes['icon'], { [classes[`icon-${type}`]]: true })}>
      {iconByType[type]}
    </div>
  );
};

interface ItemProps {
  notification: Notification;
}

const NotificationItem = ({ notification: { type, message, title } }: ItemProps) => {
  const dispatch = useDispatch();

  const onClose = useCallback(() => {
    dispatch(removeLastNotification());
  }, [dispatch]);

  useEffect(() => {
    setTimeout(onClose, duration);
  }, [onClose]);

  return (
    <div className={classes['notification__box']}>
      <NotificationIcon type={type} />
      <div className={classes['notification__box-content']}>
        <ServerSharedText type="label" as="p">
          {title.includes('aquaty-') ? <AQString componentId={title} /> : title}
        </ServerSharedText>
        <ServerSharedText color="grey500">
          {message.includes('aquaty-') ? <AQString componentId={message} /> : message}
        </ServerSharedText>
      </div>
      <div className={classes['notification__box-content-close-icon']}>
        <Close24Px onClick={onClose} />
      </div>
    </div>
  );
};

export const AqNotification = () => {
  const notifications = useSelector(getNotifications);

  return (
    <div className={classes.notification}>
      {notifications.map((notification, i) => (
        <NotificationItem key={i} notification={notification} />
      ))}{' '}
    </div>
  );
};
