import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { memo, useCallback, useMemo } from 'react';

import Email24Px from 'public/svg-components/Email24Px';
import EmailSelected24Px from 'public/svg-components/EmailSelected24Px';
import { INBOX_PAGE } from 'src/constants/paths';
import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';
import { getStaffImpersonate } from '@/store/staff-impersonate/reducer';
import { useGetUserInboundMessages } from 'src/queries/users/get-user-inbound-messages';
import { useGetClubInboundMessages } from 'src/queries/clubs/get-club-inbound-messages';
import { InboundMessageStatus } from 'src/interfaces/common';
import { Roles } from 'src/interfaces/user';
import { ServerSharedText } from 'src/server-shared/texts/text';

interface HeaderInboxProps {
  showFounderVentureList: boolean;
  role?: Roles;
}

export const HeaderInbox = memo(({ showFounderVentureList, role }: HeaderInboxProps) => {
  const { pathname, push } = useRouter();
  const { adminView } = useSelector(getStaffImpersonate);

  const { userInboundMessages } = useGetUserInboundMessages({
    variables: { statuses: [InboundMessageStatus.RECEIVED, InboundMessageStatus.READ] },
  });

  const { clubInboundMessages } = useGetClubInboundMessages({
    skip: !role || role !== Roles.INVESTMENT_MANAGER,
    variables: { statuses: [InboundMessageStatus.RECEIVED, InboundMessageStatus.READ] },
  });

  const isInbox = pathname === INBOX_PAGE;
  const icon = isInbox ? <EmailSelected24Px /> : <Email24Px />;

  const goToInbox = useCallback(() => {
    if (isInbox) {
      return;
    }
    push(INBOX_PAGE);
  }, [isInbox, push]);

  const count = useMemo(() => {
    const emailCount = [...userInboundMessages, ...clubInboundMessages].length;
    if (emailCount <= 9) return emailCount;
    return '9+';
  }, [clubInboundMessages, userInboundMessages]);

  if (showFounderVentureList || adminView) {
    return null;
  }

  return (
    <div onClick={goToInbox} className={classNames('flex', classes['inbox-container'])}>
      {icon}
      <div className={classNames('flex', classes['inbox-container__badge'])}>
        <ServerSharedText type="label-xs" color="body">
          {count}
        </ServerSharedText>
      </div>
    </div>
  );
});
