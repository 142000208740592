import { CSSProperties } from 'react';

import { borderRadius } from 'src/styles/border-radius';
import { inter } from 'src/styles/fonts';
import { palette } from 'src/styles/palette';
import { spacing } from 'src/styles/spacing';

const focusedStyles = {
  border: `2px solid ${palette.primary600}`,
  '&:hover': {
    border: `2px solid ${palette.primary600}`,
  },
};

export const selectStyles = {
  control: (styles: Record<string, any>, state: any) => {
    const isError = state.selectProps.error && state.selectProps.touched;
    return {
      ...styles,
      minHeight: 48,
      boxShadow: 'none',
      backgroundColor: state.isDisabled ? palette.grey50 : palette.body,
      border: `1px solid ${isError ? `${palette.alert600};` : `${palette.grey300}`};`,
      ...(state.isFocused ? focusedStyles : {}),
      padding: '0px 8px',
      borderRadius: 6,
    };
  },
  menu: (styles: Record<string, any>) => ({
    ...styles,
    zIndex: 5,
  }),
  menuPortal: (base: Record<string, any>) => ({ ...base, zIndex: 9999999 }),
  option: (styles: Record<string, any>, state: any) => {
    return {
      ...styles,
      opacity: state.isDisabled ? 0.5 : 1,
      backgroundColor: state.isSelected ? palette.primary50 : palette.body,
      ':hover': {
        backgroundColor: palette.primary50,
      },
    };
  },
};

export const selectStylesExtended = {
  option: (provided: any, state: any) => {
    let background = palette.body;

    if (state.isSelected) {
      background = palette.primary50;
    } else if (state.isDisabled) {
      background = palette.grey50;
    }

    return {
      ...provided,
      background,
      padding: '8px 16px',
    };
  },
};

export const getSelectWithCheckboxInlineStyles = (menuStyles: CSSProperties) => ({
  option: (base: any) => ({
    ...base,
    padding: `${spacing.xs} ${spacing.m}`,
    display: 'flex',
    ':hover': {
      backgroundColor: 'transparent',
    },
    backgroundColor: 'transparent',
    cursor: 'pointer',
  }),
  clearIndicator: (base: any) => ({
    ...base,
    padding: 0,
  }),
  placeholder: (base: any) => ({
    ...base,
    fontFamily: inter.style.fontFamily,
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: 16,
    lineHeight: '24px',
  }),
  control: (base: any, state: any) => {
    const isError = state.selectProps?.error && state.selectProps?.touched;
    const focusedStyles = {
      border: `2px solid ${palette.primary600}`,
      '&:hover': {
        border: `2px solid ${palette.primary600}`,
      },
    };
    return {
      ...base,
      minHeight: 48,
      boxShadow: 'none',
      border: `1px solid ${isError ? `${palette.alert600};` : `${palette.grey300}`};`,
      ...(state.isFocused ? focusedStyles : {}),
    };
  },
  menu: (base: any) => ({
    ...base,
    zIndex: 5,
    ...menuStyles,
  }),
});

export const multiSelectStyles = {
  control: (styles: Record<string, any>, state: any) => {
    const defControl = selectStyles.control(styles, state);
    const limit: number = state?.selectProps['data-limit'];
    return {
      ...defControl,
      paddingBottom: limit ? `calc(${spacing.l} - 4px)` : 0,
    };
  },
  menu: selectStyles.menu,
  menuPortal: selectStyles.menuPortal,
  multiValue: (styles: Record<string, any>) => {
    return {
      ...styles,
      backgroundColor: palette.grey800,
      borderRadius: borderRadius.s,
      margin: spacing.xxs,
      padding: `${spacing.xxs} ${spacing.xs}`,
    };
  },
  multiValueLabel: (styles: Record<string, any>) => {
    return {
      ...styles,
      borderRadius: borderRadius.s,
      padding: 0,
      paddingLeft: 0,
      marginRight: spacing.xs,
      color: palette.body,
      ...inter.style,
      fontWeight: 600,
      fontSize: 14,
      lineHeight: '20px',
    };
  },
  multiValueRemove: (styles: Record<string, any>) => ({
    ...styles,
    paddingRight: 0,
    paddingLeft: 0,
    path: {
      fill: 'rgba(255, 255, 255, 0.5)',
    },
    ':hover': {
      background: 'none',
      path: {
        fill: palette.body,
      },
    },
  }),
  valueContainer: (styles: Record<string, any>) => {
    return {
      ...styles,
      overflow: 'auto',
      maxHeight: 120,
      alignItems: 'center',
      display: 'flex',
      flex: 1,
      flexWrap: 'wrap' as const,
      padding: '2px 12px',
    };
  },
};
