import { useCallback } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { fileFields } from '../../common.strings';
import { VenturePositioning, VenturePositioningRequest } from 'src/interfaces/venture-pitch';

export const venturePitchPositioningGQLString = `
competitors {
  clusterColor
  clusterName
  companyImage {
    ${fileFields}
  }
  companyName
  id
  positioningX
  positioningY
  websiteUrl
}
labelX
labelY
maxLabelX
maxLabelY
minLabelX
minLabelY
venturePositioningX
venturePositioningY
`;

const venturePitchPositioningQuery = gql`
  query venturePitchPositioning($equityOfferingId: Long!) {
    venturePitchPositioning(equityOfferingId: $equityOfferingId) {
      ${venturePitchPositioningGQLString}
    }
  }
`;

const venturePitchPositionMutation = gql`
  mutation saveVenturePitchPositioning($venturePitchPositioning: VenturePitchPositioningInput) {
    saveVenturePitchPositioning(venturePitchPositioning: $venturePitchPositioning) {
      ${venturePitchPositioningGQLString}
    }
  }
`;

type UseGetVenturePositioningQuery = {
  venturePitchPositioning: VenturePositioning;
};

export const useGetVenturePitchPositioning = (
  id?: number,
  onCompleted?: (data: UseGetVenturePositioningQuery) => void,
  params?: QueryHookOptions,
) => {
  const { data, loading } = useQuery<UseGetVenturePositioningQuery>(venturePitchPositioningQuery, {
    variables: { equityOfferingId: id },
    onCompleted,
    fetchPolicy: 'no-cache',
    ...params,
  });

  return { venturePitchPositioning: data?.venturePitchPositioning, loading };
};

type UseSaveVenturePitchPositioningMutation = {
  venturePitchPositioning: VenturePositioningRequest;
};

export const useSaveVenturePitchPositioning = () => {
  const [mutateVenturePitchPosition, { loading, data }] = useMutation<
    { saveVenturePitchPositioning: VenturePositioning },
    UseSaveVenturePitchPositioningMutation
  >(venturePitchPositionMutation);

  const saveVenturePosition = useCallback(
    async (variables: UseSaveVenturePitchPositioningMutation) => {
      await mutateVenturePitchPosition({ variables });
    },
    [mutateVenturePitchPosition],
  );
  return {
    saveVenturePosition,
    loading,
    venturePitchPositioning: data?.saveVenturePitchPositioning,
  };
};
