import { combineReducers } from '@reduxjs/toolkit';
import appReducer from './app/reducer';
import founderSharedDataReducer from './founder-shared-data/reducer';
import staffImpersonate from './staff-impersonate/reducer';

const rootReducer = combineReducers({
  app: appReducer,
  founderSharedData: founderSharedDataReducer,
  staffImpersonate: staffImpersonate,
});

export default rootReducer;
