import { configureStore } from '@reduxjs/toolkit';
import { createLogger } from 'redux-logger';
import rootReducer from './root-reducer';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const middleWares: any[] = [];

if (process.env.NODE_ENV === 'development') {
  middleWares.push(createLogger({ collapsed: true }));
}

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(...middleWares),
});

export default store;

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch;
