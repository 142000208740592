import { VenturePitchSteps } from '../interfaces/venture-pitch';

export const pitchSetupSteps = [
  {
    value: VenturePitchSteps.PITCH_DESIGN,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.pitch-design.title',
    active: true,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.PROBLEM,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.problem.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.SOLUTION,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.solution.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.PRODUCT,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.product.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.TEAM,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.team.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.MARKET,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.market.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.POSITIONING,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.positioning.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.BUSINESS_MODEL_AND_TRACTION,
    label:
      'aquaty-app.equity-actions.equity-offering.venture-pitch.pitch-business-model-and-traction.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.ELEVATOR_PITCH,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.elevator-pitch.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.INVESTMENT_OFFERING,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.investment-offering.title',
    active: false,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.SUMMARY,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.summary.title',
    active: false,
    available: true,
    complete: false,
  },
];

export const uploadVenturePitchSteps = [
  {
    value: VenturePitchSteps.UPLOAD_PITCH_DECK,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.upload-pitch-deck.title',
    active: true,
    available: true,
    complete: false,
  },
  {
    value: VenturePitchSteps.ELEVATOR_PITCH,
    label: 'aquaty-app.equity-actions.equity-offering.venture-pitch.elevator-pitch.title',
    active: false,
    available: true,
    complete: false,
  },
];
