import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import Head from 'next/head';

import { VENTURE_DETAILS_PAGE, HOME_PAGE } from 'src/constants/paths';
import { Roles } from 'src/interfaces/user';
import { AppPathTabs } from 'src/interfaces/app-navigation';
import { useGetUser } from 'src/queries/users/get-user.query';
import { ExtendedBreadcrumbs } from './breadcrumbs';
import { FounderVentureList } from './founder-venture-list';
import { getActiveNavigationItem, getHeaderStepper } from 'src/store/app/reducer';
import { AnchorHederTabs } from './tabs';
import { HeaderStepper } from './stepper';
import { classNames } from 'src/utils/classNames';
import { getPlaceholder } from 'src/utils/getPlaceholder';
import classes from './index.module.scss';
import { ImpersonateUser } from './impersonate-user';

export type Modes = 'tabs' | 'breadcrumbs' | 'stepper';

const HEIGHT_FOR_SHOW_TABS = 312; // can be dynamic via redux
export const HEIGHT_FOR_SHOW_STEPPER = 48;

interface ApplicationHeaderProps {
  hideSidebar: boolean;
}

export const ApplicationHeader = ({ hideSidebar }: ApplicationHeaderProps) => {
  const cachedElements = useRef<Map<string, Element>>(new Map());
  const skipSetAnchor = useRef<boolean>(false);

  const headerStepper = useSelector(getHeaderStepper);

  const [mode, setMode] = useState<Modes>('breadcrumbs');
  const [active, setActive] = useState('');

  const activeNavigationItem = useSelector(getActiveNavigationItem);

  const { role } = useGetUser();
  const { pathname } = useRouter();

  const showFounderVentureList = useMemo(() => {
    if (role === Roles.VENTURE_FOUNDER) {
      return [HOME_PAGE, VENTURE_DETAILS_PAGE].includes(pathname) && mode === 'breadcrumbs';
    }
    return false;
  }, [pathname, role, mode]);

  const title = useMemo(() => {
    const filteredBreadcrumbs = activeNavigationItem.breadcrumbs.filter((el) => !el.isNotLink);
    const lastItem = filteredBreadcrumbs[filteredBreadcrumbs.length - 1];

    if (!lastItem) return null;

    return `${
      lastItem.breadcrumb.isDynamic
        ? lastItem.breadcrumb.title
        : getPlaceholder(lastItem.breadcrumb.title)
    } -`;
  }, [activeNavigationItem]);

  const scrollHandler = useCallback(() => {
    if (
      window.scrollY >= HEIGHT_FOR_SHOW_TABS &&
      activeNavigationItem.tabs &&
      activeNavigationItem.tabs.length !== 0
    ) {
      setMode('tabs');

      if (!skipSetAnchor.current) {
        activeNavigationItem.tabs.forEach((e) => {
          const elementInCache = cachedElements.current.get(e.attribute);

          const element =
            elementInCache || document.querySelector(`[data-anchor="${e.attribute}"]`);
          const top = element?.getBoundingClientRect().top;
          if (top && top > 0 && top < 144) {
            setActive(e.attribute);
          }

          if (!elementInCache && element) {
            cachedElements.current.set(e.attribute, element);
          }
        });
      }
    } else if (window.scrollY >= HEIGHT_FOR_SHOW_STEPPER && !!headerStepper.title.length) {
      setMode('stepper');
    } else {
      setMode('breadcrumbs');
    }
  }, [activeNavigationItem.tabs, headerStepper.title.length]);

  const changeActiveTab = useCallback((item: AppPathTabs) => {
    const el = document.querySelector(`[data-anchor="${item.attribute}"]`);
    skipSetAnchor.current = true;
    if (el) {
      setTimeout(() => (skipSetAnchor.current = false), 1000); // dirty hack but it work 🤷
      window.scrollTo({
        behavior: 'smooth',
        top: (el as HTMLElement)?.offsetTop - (48 + 16),
      });
      setActive(item.attribute);
    }
  }, []);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true);
    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, [scrollHandler]);

  return (
    <>
      {title && (
        <Head>
          <title>{`${title} AQUATY`}</title>
        </Head>
      )}
      <header
        className={classNames(classes.header, {
          [classes['mode-stepper']]: mode === 'stepper',
          [classes['hide-sidebar']]: hideSidebar,
        })}
      >
        {mode === 'tabs' && (
          <AnchorHederTabs
            items={activeNavigationItem.tabs}
            active={active}
            changeActiveTab={changeActiveTab}
          />
        )}
        {['breadcrumbs', 'stepper'].includes(mode) && (
          <ExtendedBreadcrumbs showFounderVentureList={showFounderVentureList} role={role} />
        )}
        {showFounderVentureList && <FounderVentureList />}
        <HeaderStepper {...headerStepper} mode={mode} />
        <ImpersonateUser />
      </header>
    </>
  );
};
