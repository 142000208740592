import { SVGProps, memo } from 'react';

const SvgEmailSelected24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_39706_24973)">
      <path
        d="M21.9083 6.85829C21.8837 6.8418 21.8547 6.83301 21.825 6.83301C21.7953 6.83301 21.7663 6.8418 21.7417 6.85829L13.6167 14.9833C13.4048 15.1959 13.1527 15.3644 12.8752 15.4788C12.5977 15.5932 12.3002 15.6514 12 15.65C11.392 15.6488 10.8087 15.4094 10.375 14.9833L2.25833 6.89162C2.23367 6.87514 2.20467 6.86634 2.175 6.86634C2.14533 6.86634 2.11633 6.87514 2.09167 6.89162C2.06863 6.90071 2.04821 6.91536 2.03221 6.93426C2.01622 6.95316 2.00515 6.97573 2 6.99995V17C2 17.442 2.17559 17.8659 2.48816 18.1785C2.80072 18.491 3.22464 18.6666 3.66667 18.6666H20.3333C20.7754 18.6666 21.1993 18.491 21.5118 18.1785C21.8244 17.8659 22 17.442 22 17V6.99995C22.0007 6.96987 21.9923 6.94027 21.976 6.91501C21.9596 6.88974 21.9361 6.86998 21.9083 6.85829Z"
        fill="#0D1117"
      />
      <path
        d="M11.2582 14.133C11.4557 14.3249 11.7203 14.4323 11.9957 14.4323C12.2711 14.4323 12.5357 14.3249 12.7332 14.133L20.8082 6.05801C20.8625 6.00335 20.9006 5.93469 20.9183 5.85965C20.936 5.78462 20.9325 5.70617 20.9082 5.63301C20.8165 5.33301 20.5582 5.33301 20.3332 5.33301H3.66651C3.43318 5.33301 3.18318 5.33301 3.09151 5.63301C3.06724 5.70617 3.06373 5.78462 3.08139 5.85965C3.09904 5.93469 3.13716 6.00335 3.19151 6.05801L11.2582 14.133Z"
        fill="#0D1117"
      />
    </g>
    <defs>
      <clipPath id="clip0_39706_24973">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgEmailSelected24Px);
export default Memo;
