import { useCallback } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { venturePitchElevatorGQLString } from './setup/elevator-pitch';
import { investmentOfferingGQLString } from '../offering/get-investment-offering';
import { venturePitchMarketGQLString } from './setup/market';
import { venturePitchPositioningGQLString } from './setup/positioning.query';
import { venturePitchProblemGQLString } from './setup/problem';
import { venturePitchProductGQLString } from './setup/product';
import { venturePitchSolutionGQLString } from './setup/solution';
import { venturePitchTeamGQLString } from './setup/team';
import { venturePitchBusinessModelGQLString } from './setup/business-model';
import { fileFields } from '../common.strings';
import { VenturePitch, VenturePitchBasics } from 'src/interfaces/venture-pitch';

export const standardPitchFields = `
id
accentColor
backgroundColor
businessCategory
description
font
fundingStage
industries {
  id
  nameStringId
  popularity
}
completedStates
initializationType
userVenturePitchFile { 
  ${fileFields}
}
equityOffering {
  targetsCount
  closingDate
  releaseDate
  committedFundsAmount
  preMoneyValuationFounderIndication
  maxInvestmentAmount
  requiredInvestmentAmount
  name
  id
  clubId
  investmentCommitments {
    amount
    comment
    id
    investorImage {
      ${fileFields}
    }
    investorName
    linkedInUrl
    websiteUrl
  }
  createdAt
  venture {
    id
    name
    headerImage {
      ${fileFields}
    }
    industries {
      id
      nameStringId
      popularity
    }
  }
}
`;

export const ventureQueryByIdFullFields = gql`
  query venturePitch($equityOfferingId: Long!) {
    venturePitch(equityOfferingId: $equityOfferingId) {
      ${standardPitchFields}
      elevatorPitch {
        ${venturePitchElevatorGQLString}
      }
      investmentOffering {
        ${investmentOfferingGQLString}
      }
      market {
        ${venturePitchMarketGQLString}
      }
      positioning {
        ${venturePitchPositioningGQLString}
      }
      problem {
        ${venturePitchProblemGQLString}
      }
      product {
        ${venturePitchProductGQLString}
      }
      solution {
        ${venturePitchSolutionGQLString}
      }
      teamMembers {
        ${venturePitchTeamGQLString}
      }
      businessModel {
        ${venturePitchBusinessModelGQLString}
      }
    }
  }
`;

export const ventureQueryById = gql`
  query venturePitch($equityOfferingId: Long!) {
    venturePitch(equityOfferingId: $equityOfferingId) {
      ${standardPitchFields}
    }
  }
`;

export function useGetVenturePitch(id: number, params?: QueryHookOptions) {
  const { data, loading } = useQuery<{ venturePitch: VenturePitch }>(ventureQueryByIdFullFields, {
    variables: { equityOfferingId: id },
    ...params,
  });

  return {
    venturePitch: data?.venturePitch,
    loading,
  };
}

const venturePitchMutation = gql`
  mutation saveVenturePitchBasis($venturePitch: VenturePitchInput!) {
    saveVenturePitchBasis(venturePitch: $venturePitch) {
      ${standardPitchFields}
    }
  }
`;

interface VenturePitchBasicsWithoutId extends Omit<VenturePitchBasics, 'id'> {
  id?: number;
}

type UseGetVenturePitchMutation = { venturePitch: VenturePitchBasicsWithoutId };

export const useSaveVenturePitch = () => {
  const [mutateVenturePitch, { loading, data }] = useMutation<
    { saveVenturePitchBasis: VenturePitchBasics },
    UseGetVenturePitchMutation
  >(venturePitchMutation);

  const saveVenturePitch = useCallback(
    async (variables: UseGetVenturePitchMutation) => {
      await mutateVenturePitch({
        variables,
      });
    },
    [mutateVenturePitch],
  );
  return { saveVenturePitch, loading, venturePitchBasis: data?.saveVenturePitchBasis };
};
