import { SVGProps, memo } from 'react';

const SvgAlertCircle24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#alert-circle-24px_svg__a)">
      <path
        d="M12 2a10.2 10.2 0 0 0-7.108 3.042A9.875 9.875 0 0 0 2 12.175 9.817 9.817 0 0 0 11.833 22H12a10.092 10.092 0 0 0 10-10.175A9.807 9.807 0 0 0 12 2Zm-1.25 13.783A1.233 1.233 0 0 1 12 14.5a1.275 1.275 0 0 1 1.267 1.225A1.225 1.225 0 0 1 12.042 17a1.275 1.275 0 0 1-1.292-1.217Zm.417-3.333v-5a.833.833 0 1 1 1.666 0v5a.833.833 0 0 1-1.666 0Z"
        fill="#F23535"
      />
    </g>
    <defs>
      <clipPath id="alert-circle-24px_svg__a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgAlertCircle24Px);
export default Memo;
