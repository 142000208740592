import { SVGProps, memo } from 'react';

const SvgCheckCircle48Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={48} height={48} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#check-circle-48px_svg__a)">
      <path
        d="M24 4a20 20 0 1 0 0 40 20 20 0 0 0 0-40Zm11.55 13.667L24.133 33.15a1.666 1.666 0 0 1-2.383.317L13.6 26.95a1.667 1.667 0 0 1-.25-2.35 1.666 1.666 0 0 1 2.317-.25l6.8 5.433 10.4-14.116a1.666 1.666 0 0 1 2.316-.35 1.665 1.665 0 0 1 .367 2.35Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="check-circle-48px_svg__a">
        <path fill="#fff" transform="translate(4 4)" d="M0 0h40v40H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgCheckCircle48Px);
export default Memo;
