import { SVGProps, memo } from 'react';

const SvgStar24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="m12.607 3 2.789 5.524 5.366.532a.67.67 0 0 1 .417 1.145l-4.416 4.377 1.637 5.949a.677.677 0 0 1-.96.779L12 18.612l-5.433 2.69a.678.678 0 0 1-.96-.779l1.638-5.948-4.42-4.377a.671.671 0 0 1 .417-1.146l5.366-.531L11.392 3a.682.682 0 0 1 1.216 0v0Z"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgStar24Px);
export default Memo;
