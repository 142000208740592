import { useSelector } from 'react-redux';
import { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';

import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { AQString } from 'src/shared/AQString';
import { getHeaderStepper } from 'src/store/app/reducer';
import { useGetDataRoomSetup } from 'src/queries/data-room/data-room-stage.query';
import { DataRoomEvaluationSteps } from 'src/interfaces/data-room-evaluation';
import {
  CompleteEvaluationModal,
  CompleteModalTypes,
} from 'src/server-shared/modals/feedback/complete-evaluation';
import { EquityOfferingTaskEnum, EquityOfferingTaskStatusEnum } from 'src/interfaces/offering';
import { useGetOfferingById } from 'src/queries/offering/get-offering.query';
import { Roles } from 'src/interfaces/user';
import { useGetEquityOfferingParticipants } from 'src/queries/offering/participants/get-equity-offering-participants';

interface SubmitDueDiligenceProps {
  onlyButton?: boolean;
}

export const SubmitDueDiligence = ({ onlyButton = false }: SubmitDueDiligenceProps) => {
  const { steps } = useSelector(getHeaderStepper);
  const [modal, setModal] = useState({ open: false, type: CompleteModalTypes.EVALUATION });
  const router = useRouter();

  const { offeringId } = router.query as { offeringId: string };
  const { equityOffering } = useGetOfferingById(Number(offeringId), { fetchPolicy: 'no-cache' });
  const { equityOfferingParticipants } = useGetEquityOfferingParticipants(Number(offeringId));

  const { dataRoom } = useGetDataRoomSetup(Number(offeringId));

  const enableSubmitButton = useMemo(() => {
    const completedSteps = steps.filter((el) => el.complete);

    if (
      dataRoom?.additionalInformation.additionalInformationDescription ||
      (dataRoom && dataRoom.additionalInformation.otherFiles.length > 0)
    ) {
      return completedSteps.length === steps.length;
    }
    const filteredSteps = completedSteps.filter(
      (el) => el.value !== DataRoomEvaluationSteps.OTHERS,
    );
    return filteredSteps.length === steps.length - 1;
  }, [dataRoom, steps]);

  const dataRoomEvaluationTasks = useMemo(() => {
    return equityOffering?.tasks.find(
      (el) =>
        el.task === EquityOfferingTaskEnum.DUE_DILIGENCE_EXECUTE_GENERAL_DATA_ROOM_EVALUATION ||
        el.task === EquityOfferingTaskEnum.DUE_DILIGENCE_EXECUTE_EXPERT_DATA_ROOM_EVALUATION,
    );
  }, [equityOffering?.tasks]);

  const evaluationAndCompleteTask = useMemo(() => {
    return equityOffering?.tasks.find(
      (el) => el.task === EquityOfferingTaskEnum.DUE_DILIGENCE_REVIEW_EVALUATION_AND_COMPLETE,
    );
  }, [equityOffering?.tasks]);

  const hasExpert = useMemo(() => {
    return equityOfferingParticipants.some((el) => el.participationRole === Roles.EXPERT_PARTNER);
  }, [equityOfferingParticipants]);

  const openModal = useCallback(() => {
    if (
      evaluationAndCompleteTask?.status === EquityOfferingTaskStatusEnum.TO_COMPLETE ||
      !hasExpert
    ) {
      setModal({ open: true, type: CompleteModalTypes.DUE_DILIGENCE });
      return;
    }
    if (dataRoomEvaluationTasks?.status === EquityOfferingTaskStatusEnum.TO_COMPLETE) {
      setModal({ open: true, type: CompleteModalTypes.EVALUATION });
      return;
    }
  }, [dataRoomEvaluationTasks?.status, evaluationAndCompleteTask?.status, hasExpert]);

  const closeModal = useCallback(() => {
    setModal({ open: false, type: CompleteModalTypes.EVALUATION });
  }, []);

  const buttonLabel = useMemo(() => {
    if (onlyButton) {
      return dataRoomEvaluationTasks?.status === EquityOfferingTaskStatusEnum.COMPLETED ||
        !hasExpert
        ? 'aquaty-generic.cta.submit-due-diligence'
        : 'aquaty-generic.cta.complete';
    }
    return 'aquaty-generic.cta.submit-due-diligence';
  }, [dataRoomEvaluationTasks?.status, hasExpert, onlyButton]);

  return (
    <div className={onlyButton ? '' : 'grid-row'}>
      <ServerSharedButton
        disabled={!enableSubmitButton}
        label={<AQString componentId={buttonLabel} />}
        onClick={openModal}
      />
      {modal.open && (
        <CompleteEvaluationModal
          show
          closeEvent={closeModal}
          type={modal.type}
          id={Number(offeringId)}
        />
      )}
    </div>
  );
};
