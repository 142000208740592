import { FC, useEffect, useMemo } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { getCookie } from 'cookies-next';
import { useDispatch, useSelector } from 'react-redux';

import {
  HOME_PAGE,
  NOT_AUTHORIZED_PAGES,
  BENEFICIAL_OWNER_IDENTIFICATION_PAGE,
} from 'src/constants/paths';
import { COGNITO_JWT } from 'src/constants/loggedIn';
import { ApplicationSidebar } from './application-sidebar';
import { ApplicationHeader } from 'src/server-shared/header/application-header';
// import { SendEmail } from './send-email';
import { checkFinishedRegister } from './checkFinishedRegister';
import { useGetRoleAccreditation } from 'src/queries/role-accreditation';
import { useGetUserInvestmentAccounts } from 'src/queries/users/investment-account';
import { Roles } from 'src/interfaces/user';
import { useGetConfiguration } from 'src/queries/configuration';
import { ServerSharedLoader } from 'src/server-shared/loader';
import { setConfiguration } from 'src/store/app/reducer';
import { PDFViewerProvider } from 'src/shared/PDfViewerV2/provider';
import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';
import { getStaffImpersonate } from 'src/store/staff-impersonate/reducer';

export const AppLayout: FC = ({ children }) => {
  const router = useRouter();
  const dispatch = useDispatch();

  const { userId, adminView } = useSelector(getStaffImpersonate);

  const { userRoleAccreditationDetails, userRole } = useGetRoleAccreditation();
  const { userInvestmentAccounts } = useGetUserInvestmentAccounts();
  const isLoggedIn = getCookie(COGNITO_JWT);

  const { loading } = useGetConfiguration({
    skip: !isLoggedIn || [BENEFICIAL_OWNER_IDENTIFICATION_PAGE].includes(router.pathname),
    onCompleted: ({ configuration }) => {
      dispatch(setConfiguration(configuration));
    },
  });

  const hideSidebar = useMemo(() => !!userId && !!adminView, [adminView, userId]);

  const isPublicPage = useMemo(() => NOT_AUTHORIZED_PAGES.includes(router.pathname), [router]);

  useEffect(() => {
    const readyToCheckFinishedRegistration =
      userRole === Roles.VENTURE_INVESTOR
        ? userInvestmentAccounts.length > 0 && isLoggedIn
        : isLoggedIn;
    // TODO we can move all logic to getUser query
    if (userRoleAccreditationDetails && readyToCheckFinishedRegistration) {
      const instructions = checkFinishedRegister(
        userRoleAccreditationDetails,
        userInvestmentAccounts,
        router.pathname,
      );
      if (typeof instructions === 'string') {
        router.push(instructions);
        return;
      }
      if (
        typeof instructions === 'object' &&
        instructions !== null &&
        instructions.skipAllRedirects
      ) {
        return;
      }

      if (router.pathname.includes('auth') || router.pathname === '/') {
        router.push(HOME_PAGE);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userRoleAccreditationDetails, userInvestmentAccounts, userRole]);

  if (loading) {
    return <ServerSharedLoader />;
  }

  return isPublicPage ? (
    <>
      <Head>
        <title>AQUATY</title>
      </Head>
      <div className={classes.public}>
        {children}
        {/* <SendEmail /> */}
      </div>
    </>
  ) : (
    <div className={classNames(classes.app, { [classes['hide-sidebar']]: hideSidebar })}>
      {!hideSidebar && <ApplicationSidebar />}
      <ApplicationHeader hideSidebar={hideSidebar} />
      <PDFViewerProvider>
        <main className={classes.app__main}>{children}</main>
      </PDFViewerProvider>
    </div>
  );
};
