import { useRouter } from 'next/router';
import { useCallback } from 'react';

import { AQString } from 'src/shared/AQString';
import { HOME_PAGE } from 'src/constants/paths';
import { ServerSharedFeedbackModal } from 'src/server-shared/modals/feedback';
import { GeneralModalProps } from 'src/server-shared/modals/modal/types';
import { useSaveCompleteEvaluation } from 'src/queries/offering/save-complete-data-room-evaluation';

export enum CompleteModalTypes {
  'EVALUATION' = 'EVALUATION',
  'DUE_DILIGENCE' = 'DUE_DILIGENCE',
}

const titles = {
  [CompleteModalTypes.EVALUATION]:
    'aquaty-app.equity-offering.due-diligence.data-room-evaluation.modal.complete-evaluation.title',
  [CompleteModalTypes.DUE_DILIGENCE]:
    'aquaty-app.equity-offering.due-diligence.data-room-evaluation.modal.submit-due-diligence.title',
};

const subtitles = {
  [CompleteModalTypes.EVALUATION]:
    'aquaty-app.equity-offering.due-diligence.data-room-evaluation.modal.complete-evaluation.subtitle',
  [CompleteModalTypes.DUE_DILIGENCE]:
    'aquaty-app.equity-offering.due-diligence.data-room-evaluation.modal.submit-due-diligence.subtitle',
};

interface CompleteEvaluationModalProps extends GeneralModalProps {
  id: number;
  type: CompleteModalTypes;
}

export const CompleteEvaluationModal = ({
  show,
  closeEvent,
  id,
  type,
}: CompleteEvaluationModalProps) => {
  const router = useRouter();

  // for complete OfferingStage
  const { saveCompleteEvaluation } = useSaveCompleteEvaluation();

  const onConfirm = useCallback(() => {
    saveCompleteEvaluation({
      equityOfferingId: id,
    }).then(() => router.push(HOME_PAGE));
  }, [saveCompleteEvaluation, id, router]);

  return (
    <ServerSharedFeedbackModal
      show={show}
      closeEvent={closeEvent}
      title={<AQString componentId={titles[type]} />}
      subtitle={<AQString componentId={subtitles[type]} />}
      footer={{
        onConfirm,
        hasLeftButton: true,
        buttonText:
          type === CompleteModalTypes.DUE_DILIGENCE
            ? 'aquaty-generic.cta.submit-due-diligence'
            : 'aquaty-generic.cta.action',
      }}
    />
  );
};
