import { SVGProps, memo } from 'react';

export const Warning24PxSVG = (props: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M21.4929 10.7741L13.2162 2.4983C12.8929 2.17665 12.4554 1.99609 11.9993 1.99609C11.5432 1.99609 11.1057 2.17665 10.7824 2.4983L2.50571 10.7824C2.34543 10.9419 2.21824 11.1315 2.13146 11.3404C2.04468 11.5492 2 11.7731 2 11.9992C2 12.2253 2.04468 12.4492 2.13146 12.658C2.21824 12.8668 2.34543 13.0564 2.50571 13.216L10.7824 21.4834C10.9393 21.648 11.1281 21.7787 11.3374 21.8676C11.5467 21.9564 11.7719 22.0015 11.9993 22.0001C12.227 22.0004 12.4524 21.9556 12.6627 21.8684C12.873 21.7811 13.064 21.6531 13.2245 21.4918L21.4929 13.2243C21.8176 12.8993 22 12.4586 22 11.9992C22 11.5398 21.8176 11.0991 21.4929 10.7741ZM11.1658 7.34875C11.1658 7.12772 11.2536 6.91574 11.4099 6.75944C11.5662 6.60315 11.7782 6.51534 11.9993 6.51534C12.2203 6.51534 12.4324 6.60315 12.5887 6.75944C12.745 6.91574 12.8328 7.12772 12.8328 7.34875V12.3492C12.8328 12.5703 12.745 12.7822 12.5887 12.9385C12.4324 13.0948 12.2203 13.1826 11.9993 13.1826C11.7782 13.1826 11.5662 13.0948 11.4099 12.9385C11.2536 12.7822 11.1658 12.5703 11.1658 12.3492V7.34875ZM12.041 16.9413C11.7133 16.9374 11.3995 16.8083 11.164 16.5805C10.9284 16.3528 10.7889 16.0435 10.774 15.7162C10.7673 15.5512 10.794 15.3865 10.8526 15.232C10.9111 15.0775 11.0003 14.9365 11.1147 14.8174C11.2292 14.6983 11.3666 14.6036 11.5186 14.5389C11.6706 14.4742 11.8341 14.441 11.9993 14.4411C12.3247 14.4472 12.6354 14.5775 12.8678 14.8053C13.1003 15.033 13.2368 15.341 13.2495 15.6662C13.2551 15.8296 13.2281 15.9924 13.1702 16.1453C13.1122 16.2981 13.0245 16.4379 12.912 16.5566C12.7996 16.6752 12.6647 16.7703 12.5151 16.8364C12.3656 16.9024 12.2044 16.9381 12.041 16.9413Z"
        fill="#EBAA05"
      />
    </svg>
  );
};

const Memo = memo(Warning24PxSVG);
export default Memo;
