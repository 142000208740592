import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';

import { fileFields } from '../../common.strings';
import { VentureBusinessModel, VentureBusinessModelRequest } from 'src/interfaces/venture-pitch';

export const venturePitchBusinessModelGQLString = `
  businessModelDescription
  fullTimeEmployeesNumber
  images {
    ${fileFields}
  }
  marketIntegrationStage
  partTimeEmployeesNumber
  tractionDescription
`;

const venturePitchBusinessModelMutation = gql`
  mutation saveVenturePitchBusinessModel(
    $businessModel: VenturePitchBusinessModelInput
  ) {
    saveVenturePitchBusinessModel(businessModel: $businessModel) {
      ${venturePitchBusinessModelGQLString}
    }
  }
`;

type UseVenturePitchBusinessModelMutation = { businessModel: VentureBusinessModelRequest };

export const useVenturePitchBusinessModel = () => {
  const [mutateVenturePitchBusinessModel, { loading, data }] = useMutation<
    { saveVenturePitchBusinessModel: VentureBusinessModel },
    UseVenturePitchBusinessModelMutation
  >(venturePitchBusinessModelMutation);

  const saveVenturePitchBusinessModel = useCallback(
    async (variables: UseVenturePitchBusinessModelMutation) => {
      await mutateVenturePitchBusinessModel({ variables });
    },
    [mutateVenturePitchBusinessModel],
  );
  return {
    saveVenturePitchBusinessModel,
    loading,
    venturePitchBusinessModel: data?.saveVenturePitchBusinessModel,
  };
};
