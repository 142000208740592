import { SVGProps, memo } from 'react';

const SvgLockFilled16Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.75 6.75h-.375V5.375a3.375 3.375 0 0 0-6.75 0V6.75H4.25a1 1 0 0 0-1 1V13a1 1 0 0 0 1 1h7.5a1 1 0 0 0 1-1V7.75a1 1 0 0 0-1-1ZM8 11.25a1 1 0 1 1 0-2 1 1 0 0 1 0 2Zm2.125-4.75a.25.25 0 0 1-.25.25h-3.75a.25.25 0 0 1-.25-.25V5.375a2.125 2.125 0 1 1 4.25 0V6.5Z"
      fill="#1F2937"
    />
  </svg>
);

const Memo = memo(SvgLockFilled16Px);
export default Memo;
