import { useCallback, useMemo } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery, DocumentNode } from '@apollo/client';

import {
  EquityOffering,
  EquityOfferingState,
  EquityOfferingTaskEnum,
} from '../../interfaces/offering';
import { venturesQuery } from '../ventures.query';
import { equityOfferingGQLString, fileFields } from '../common.strings';

export const userQueryTasks = gql`
  query {
    equityOfferingTasksPolicy {
      relatedEquityOfferingStage
      task
      taskTitleStringId
      executable
    }
  }
`;

export function useGetOfferingTasks() {
  const { data, loading, error } = useQuery<{ equityOfferingTasksPolicy: EquityOfferingState[] }>(
    userQueryTasks,
  );
  return {
    equityOfferingTasks: data?.equityOfferingTasksPolicy || null,
    loading,
    error,
  };
}

const mutationEquityOfferingName = gql`
  mutation InitiateEquityOffering(
    $id: Long
    $name: String
    $closingDate: LocalDateTime
  ) {
    initiateEquityOffering(equityOffering: { id: $id, name: $name, closingDate: $closingDate }) {
      ${equityOfferingGQLString}
    }
  }
`;

type UseSaveEquityOfferingNameMutation = {
  id: number;
  name: string;
  closingDate: string;
};

export const offeringQuery = gql`
  query equityOffering($equityOfferingId: Long) {
    equityOffering(equityOfferingId: $equityOfferingId) {
      ${equityOfferingGQLString}
      venture {
        id
        name
        logo {
          ${fileFields}
        }
        name
      }
    }
  }
`;

export const useSaveEquityOfferingName = (refetchQueries: DocumentNode[] = [offeringQuery]) => {
  const [saveEquityOffering, { loading }] = useMutation<
    { initiateEquityOffering: EquityOffering },
    UseSaveEquityOfferingNameMutation
  >(mutationEquityOfferingName, {
    refetchQueries: refetchQueries,
  });

  const saveOfferingName = useCallback(
    async (variables: UseSaveEquityOfferingNameMutation) => {
      await saveEquityOffering({ variables });
    },
    [saveEquityOffering],
  );
  return { saveOfferingName, loading };
};

export const useGetOfferingById = (id?: number, params?: QueryHookOptions) => {
  const { data, loading, refetch } = useQuery<{ equityOffering: EquityOffering }>(offeringQuery, {
    variables: { equityOfferingId: id },
    ...params,
  });

  return { equityOffering: data?.equityOffering, loading, refetch };
};

const mutationCancelEquityOffering = gql`
  mutation cancelEquityOffering($equityOfferingId: Long) {
    cancelEquityOffering(equityOfferingId: $equityOfferingId)
  }
`;

type UseCancelEquityOfferingMutation = {
  equityOfferingId: number;
};

export const useCancelEquityOffering = () => {
  const [mutationEquityOffering, { loading }] = useMutation(mutationCancelEquityOffering, {
    refetchQueries: [venturesQuery],
  });

  const cancelEquityOffering = useCallback(
    async (variables: UseCancelEquityOfferingMutation) => {
      await mutationEquityOffering({ variables });
    },
    [mutationEquityOffering],
  );
  return { cancelEquityOffering, loading };
};

export const useGetDataRoomReviewTaskById = (id?: number, params?: QueryHookOptions) => {
  const { data, loading } = useQuery<{ equityOffering: EquityOffering }>(offeringQuery, {
    variables: { equityOfferingId: id },
    ...params,
  });

  const task = useMemo(() => {
    if (!data) {
      return undefined;
    }
    return data.equityOffering.tasks.find(
      (el) => el.task === EquityOfferingTaskEnum.REQUEST_EXTENDED_DATA_ROOM_ACCESS,
    );
  }, [data]);

  return { task, loading };
};
