import { abbreviateNumber } from 'src/utils/abbreviateNumber';
import { usePercentage } from 'src/hooks/usePercentage';
import { palette, PaletteKeys } from 'src/styles/palette';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

interface ProgressBarProps {
  current: number;
  expected: number;
  currency?: '€' | '$';
  isShowLabel?: boolean;
  height?: number;
  fillerBackground?: PaletteKeys;
  progressBackground?: PaletteKeys;
  withBorder?: boolean;
  colorBG?: string;
  leftBorderRadius?: boolean;
}

export const ServerSharedProgressBar = ({
  currency,
  current,
  expected,
  isShowLabel = true,
  height = 10,
  fillerBackground = 'grey800',
  progressBackground = 'grey50',
  withBorder = false,
  colorBG,
  leftBorderRadius = false,
}: ProgressBarProps) => {
  const { percentage } = usePercentage(current, expected);

  return (
    <div>
      <div
        className={classNames(classes['progress-container'], {
          [classes['without-margin']]: true,
          [classes['with-border']]: withBorder,
        })}
        style={{ height: `${height}px`, backgroundColor: palette[progressBackground] }}
      >
        <div
          className={classNames(classes['filler'], {
            [classes['left-border-radius']]: leftBorderRadius,
            [classes['border-radius']]: !!colorBG,
          })}
          style={{ width: `${percentage}%`, backgroundColor: colorBG || palette[fillerBackground] }}
        />
      </div>
      {isShowLabel && (
        <ServerSharedText
          type="text-small"
          style={{ float: 'right', textTransform: 'uppercase', fontWeight: 700 }}
        >
          {currency} {abbreviateNumber(current)} / {abbreviateNumber(expected)}
        </ServerSharedText>
      )}
    </div>
  );
};

interface SimpleProgressProps extends Pick<ProgressBarProps, 'current' | 'expected'> {
  color?: PaletteKeys;
  containerColor?: PaletteKeys;
}

export const ServerSharedSimpleProgress = ({
  current,
  expected,
  color = 'primary600',
  containerColor = 'grey50',
}: SimpleProgressProps) => {
  const { percentage } = usePercentage(current, expected);
  return (
    <div
      className={classes['simple-progress-container']}
      style={{ backgroundColor: palette[containerColor] }}
    >
      <div
        className={classNames(classes['filler'], classes['left-border-radius'])}
        style={{ width: `${percentage}%`, backgroundColor: palette[color] }}
        color={color}
      />
    </div>
  );
};
