import { SVGProps, memo } from 'react';

const SvgCheckCircleM24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm5.775 6.833-5.708 7.742a.834.834 0 0 1-1.192.158L6.8 13.475a.834.834 0 0 1-.125-1.175.834.834 0 0 1 1.158-.125l3.4 2.717 5.2-7.059a.833.833 0 0 1 1.159-.175.834.834 0 0 1 .183 1.175Z"
      fill="#389860"
    />
  </svg>
);

const Memo = memo(SvgCheckCircleM24Px);
export default Memo;
