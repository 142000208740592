export type SpacingKeys = 'xxs' | 'xs' | 's' | 'm' | 'l' | 'xl' | 'xxl';

export const spacing: Record<SpacingKeys, string> = {
  xxs: '4px',
  xs: '8px',
  s: '12px',
  m: '16px',
  l: '24px',
  xl: '32px',
  xxl: '48px',
};
