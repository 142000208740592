export const getHubspotToken = async (body: {
  email: string;
  firstName: string;
  lastName: string;
}) => {
  const result: any = await fetch('/api/get-hubspot-token', {
    method: 'POST',
    body: JSON.stringify({
      body,
    }),
  });

  const data: { token: string } = await result.json();
  return data.token;
};
