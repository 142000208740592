import { forwardRef, useEffect, useState } from 'react';

import { getFileS3URL } from 'src/server-shared/inputs/file-uploader/s3';
import classes from './index.module.scss';

export const ServerSharedImage = forwardRef<HTMLImageElement, React.ComponentProps<'img'>>(
  (props, ref) => {
    return <img className={classes['image']} {...props} ref={ref} />;
  },
);

interface AsyncImageProps extends Omit<React.ComponentProps<'img'>, 'ref' | 'src'> {
  imageKey: string;
}

export const useAsyncImage = (key: string, skip: boolean = false) => {
  const [loadedSrc, setLoadedSrc] = useState<string>('');

  const updateString = (str: string = '') => {
    if (str.includes('protected/')) return str.split('/')[2];
    return str;
  };

  useEffect(() => {
    let isCancelled = false;
    if (!skip) {
      getFileS3URL(updateString(key))
        .then((source) => {
          if (!isCancelled) {
            setLoadedSrc(source);
          }
        })
        .catch(() => setLoadedSrc(''));
    }

    return () => {
      isCancelled = true;
    };
  }, [key, skip]);

  return {
    loadedSrc,
  };
};

export const ServerSharedAsyncImage = forwardRef<HTMLImageElement, AsyncImageProps>(
  ({ imageKey, ...rest }, ref) => {
    const { loadedSrc } = useAsyncImage(imageKey);

    return loadedSrc ? <ServerSharedImage {...rest} src={loadedSrc} ref={ref} /> : null;
  },
);
