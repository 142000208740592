import { SVGProps, memo } from 'react';

const SvgVentureIndex24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.32812 19.5156H20.6719"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M7.375 11.4219H5.0625C4.90917 11.4219 4.76212 11.4828 4.6537 11.5912C4.54528 11.6996 4.48438 11.8467 4.48438 12V19.5156H7.95312V12C7.95312 11.8467 7.89222 11.6996 7.7838 11.5912C7.67538 11.4828 7.52833 11.4219 7.375 11.4219Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M13.1562 4.48438H10.8438C10.6904 4.48438 10.5434 4.54528 10.435 4.6537C10.3265 4.76212 10.2656 4.90917 10.2656 5.0625V19.5156H13.7344V5.0625C13.7344 4.90917 13.6735 4.76212 13.565 4.6537C13.4566 4.54528 13.3096 4.48438 13.1562 4.48438Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.9375 7.95313H16.625C16.4717 7.95312 16.3246 8.01403 16.2162 8.12245C16.1078 8.23087 16.0469 8.37792 16.0469 8.53125V19.5156H19.5156V8.53125C19.5156 8.37792 19.4547 8.23087 19.3463 8.12245C19.2379 8.01403 19.0908 7.95313 18.9375 7.95313Z"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgVentureIndex24Px);
export default Memo;
