import { useCallback } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { fileFields } from '../../common.strings';
import { VentureProduct, VentureProductRequest } from 'src/interfaces/venture-pitch';

export const venturePitchProductGQLString = `
demoLink
description
images {
  ${fileFields}
}
password
text
username
`;

const venturePitchProductQuery = gql`
  query venturePitchProduct($equityOfferingId: Long!) {
    venturePitchProduct(equityOfferingId: $equityOfferingId) {
      ${venturePitchProductGQLString}
    }
  }
`;

const venturePitchProductMutation = gql`
  mutation saveVenturePitchProduct($venturePitchProduct: VenturePitchProductInput) {
    saveVenturePitchProduct(venturePitchProduct: $venturePitchProduct) {
      ${venturePitchProductGQLString}
    }
  }
`;

type UseGetVenturePitchProductQuery = { venturePitchProduct: VentureProduct };

export const useGetVenturePitchProductQuery = (
  id?: number,
  onCompleted?: (data: UseGetVenturePitchProductQuery) => void,
  params?: QueryHookOptions,
) => {
  const { data, loading } = useQuery<UseGetVenturePitchProductQuery>(venturePitchProductQuery, {
    variables: { equityOfferingId: id },
    onCompleted,
    fetchPolicy: 'no-cache',
    ...params,
  });
  return { venturePitchProduct: data?.venturePitchProduct, loading };
};

type UseSaveVenturePitchProductMutation = { venturePitchProduct: VentureProductRequest };

export const useSaveVenturePitchProduct = () => {
  const [mutateVenturePitchSolution, { loading, data }] = useMutation<
    { saveVenturePitchProduct: VentureProduct },
    UseSaveVenturePitchProductMutation
  >(venturePitchProductMutation);

  const saveVenturePitchProduct = useCallback(
    async (variables: UseSaveVenturePitchProductMutation) => {
      await mutateVenturePitchSolution({ variables });
    },
    [mutateVenturePitchSolution],
  );
  return { saveVenturePitchProduct, loading, venturePitchProduct: data?.saveVenturePitchProduct };
};
