import { SVGProps, memo } from 'react';

const SvgUserIcon = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M7.625 10.125a4.375 4.375 0 1 0 8.75 0 4.375 4.375 0 0 0-8.75 0v0ZM17.76 19.397a8.12 8.12 0 0 0-11.52 0"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.625 12a9.375 9.375 0 1 0 18.75 0 9.375 9.375 0 0 0-18.75 0v0Z"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgUserIcon);
export default Memo;
