import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { DataRoomTeamMember } from 'src/interfaces/data-room';
import { venturePitchTeamGQLString } from '../venture-pitch/setup/team';
import { fileFields } from '../common.strings';

export const teamGQLString = `
${venturePitchTeamGQLString}
completed
contractType
cv {
  ${fileFields}
}
employmentContract {
  ${fileFields}
}
entranceDate
latestEmployerReferenceDocument {
  ${fileFields}
}
noEmploymentContract
noLatestEmployerReferenceDocument
noPreviousReferences
noPreviousReferencesExplanation
references {
  id
  name
  phoneNumber
  phoneNumberCountryCode
  professionalTitle
}
noEmploymentContractExplanation
noLatestEmployerReferenceDocumentExplanation
addedInVenturePitch
`;

const dataRoomTeamMembersMutation = gql`
  mutation saveDataRoomTeamMembers(
    $equityOfferingId: Long!
    $teamMembers: [DataRoomTeamMemberInput]
  ) {
    saveDataRoomTeamMembers(equityOfferingId: $equityOfferingId, teamMembers: $teamMembers) {
      ${teamGQLString}
    }
  }
`;

interface UseSaveDataRoomTeamMembersMutation {
  equityOfferingId: number;
  teamMembers: DataRoomTeamMember[];
}

export const useSaveDataRoomTeamMembers = () => {
  const [mutateDataRoomTeamMembers, { loading, data }] = useMutation(dataRoomTeamMembersMutation);

  const saveDataRoomTeamMembers = useCallback(
    async (variables: UseSaveDataRoomTeamMembersMutation) => {
      await mutateDataRoomTeamMembers({ variables });
    },
    [mutateDataRoomTeamMembers],
  );
  return {
    saveDataRoomTeamMembers,
    loading,
    savedDataRoomTeamMembers: data?.saveDataRoomTeamMembers,
  };
};

const dataRoomTeamMemberDeleteMutation = gql`
  mutation deleteDataRoomTeamMember($equityOfferingId: Long, $teamMemberId: Long) {
    deleteDataRoomTeamMember(equityOfferingId: $equityOfferingId, teamMemberId: $teamMemberId)
  }
`;

interface UseDeleteDataRoomTeamMembersMutation {
  equityOfferingId: number;
  teamMemberId: number;
}

export const useDeleteDataRoomTeamMembers = () => {
  const [mutateDataRoomTeamMembers, { loading }] = useMutation(dataRoomTeamMemberDeleteMutation);

  const deleteDataRoomTeamMembers = useCallback(
    async (variables: UseDeleteDataRoomTeamMembersMutation) => {
      await mutateDataRoomTeamMembers({ variables });
    },
    [mutateDataRoomTeamMembers],
  );
  return {
    deleteDataRoomTeamMembers,
    loading,
  };
};
