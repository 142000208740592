import { useCallback, useMemo } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { Venture } from 'src/interfaces/venture';
import { fileFields, industriesGQLFields } from '../common.strings';
import { IConvertibleLoan } from 'src/interfaces/cap-table';
import { FileField } from 'src/interfaces/common';

export const shareholderGQlString = `
address
fullName
gender
holdingCountry
holdingName
id
birthDate
legalEntity
ownedVentureShares {
  id
  sharesFromNumber
  sharesToNumber
}
`;

export const convertibleLoansGQLString = `
discount
id
investors {
  fullName
  id
  participationAmount
  shareholder {
    ${shareholderGQlString}
  }
}
maturityDate
valuationCap
valuationFloor
`;

export const locationsGQLFields = `
id
city
country
lat
lng
postalCode
street
locationType
`;

export const ventureGQLString = `
refId
id
name
externalLinks {
  id
  linkType
  url
}
businessCategory
description
industries {
  ${industriesGQLFields}
}
registrationCountry
registrationCity
legalForm
registrationId
locations {
  ${locationsGQLFields}
}
logo {
  ${fileFields}
}
headerImage {
  ${fileFields}
}
managingDirectors {
  firstName
  lastName
}
incorporationDate
officialSubject
status
userVentureRelations {
  id
  role
  userId
  ventureId
  rejectionReason
  verified
}
officiallyRegistered
legalVentureDocuments {
  ${fileFields}
}
`;

export const ventureQueryById = gql`
  query ($ventureId: Long) {
    venture(ventureId: $ventureId) {
      ${ventureGQLString}
    }
  }
`;

export const venturesQuery = gql`
  query {
    ventures {
      ${ventureGQLString}
    }
  }
`;

const ventureMutation = gql`
  mutation saveVenture(
    $businessCategory: VentureBusinessCategory
    $id: Long
    $externalLinks: [VentureExternalLinkInput]
    $description: String
    $logo: FileInput
    $headerImage: FileInput
    $incorporationDate: LocalDate
    $industries: [IndustryInput]
    $legalForm: LegalForm
    $locations: [LocationInput]
    $managingDirectors: [PersonInput]
    $name: String
    $officialSubject: String
    $status: VentureStatus
    $userVentureRelations: [UserVentureRelationInput]
    $registrationId: String
    $registrationCity: String
    $registrationCountry: String
    $officiallyRegistered: Boolean
    $legalVentureDocuments: [FileInput]
    $refId: String
  ) {
    saveVenture(
      venture: {
        businessCategory: $businessCategory
        id: $id
        description: $description
        logo: $logo
        externalLinks: $externalLinks
        headerImage: $headerImage
        incorporationDate: $incorporationDate
        legalForm: $legalForm
        locations: $locations
        managingDirectors: $managingDirectors
        name: $name
        officialSubject: $officialSubject
        status: $status
        userVentureRelations: $userVentureRelations
        registrationId: $registrationId
        registrationCity: $registrationCity
        registrationCountry: $registrationCountry
        industries: $industries
        officiallyRegistered: $officiallyRegistered
        legalVentureDocuments: $legalVentureDocuments
        refId: $refId
      }
    ) {
      ${ventureGQLString}
    }
  }
`;

export function useGetVentureForPortfolio(id: number | undefined, params?: QueryHookOptions) {
  const { data, loading, error, refetch } = useQuery<{ venture: Venture }>(ventureQueryById, {
    variables: { ventureId: id },
    ...params,
  });

  return {
    venture: data?.venture,
    loading,
    error,
    refetch,
  };
}

export function useGetVenture(id: number | undefined, params?: QueryHookOptions) {
  const { data, loading, error, refetch } = useQuery<{ venture: Venture }>(ventureQueryById, {
    variables: { ventureId: id },
    ...params,
  });

  return {
    venture: data?.venture,
    loading,
    error,
    refetch,
  };
}

interface VentureForAPI extends Partial<Omit<Venture, 'logo' | 'headerImage'>> {
  logo?: FileField | null | { id: number | null | undefined };
  headerImage?: FileField | null | { id: number | null | undefined };
}

export const useSaveVenture = () => {
  const [mutateUser, { loading, data }] = useMutation(ventureMutation, {
    refetchQueries: [ventureQueryById, venturesQuery],
  });

  const saveVenture = useCallback(
    async (values: VentureForAPI) => {
      const variables = {
        ...values,
        managingDirectors: values.managingDirectors
          ? values.managingDirectors.map((e) => ({ firstName: e.firstName, lastName: e.lastName }))
          : [],
      };

      await mutateUser({ variables });
    },
    [mutateUser],
  );
  return { saveVenture, loading, savedVenture: data?.saveVenture || null };
};

export const ventureConvertibleLoansQuery = gql`
  query($equityOfferingId: Long!) {
    convertibleLoans(equityOfferingId: $equityOfferingId) {
      ${convertibleLoansGQLString}
    }
  }
`;

export function useGetConvertibleLoans(
  equityOfferingId: number | undefined,
  params?: QueryHookOptions,
) {
  const { data, loading, error, refetch } = useQuery<{ convertibleLoans: IConvertibleLoan }>(
    ventureConvertibleLoansQuery,
    {
      variables: { equityOfferingId },
      ...params,
    },
  );

  return {
    convertibleLoans: data?.convertibleLoans || [],
    loading,
    error,
    refetch,
  };
}

export const ventureConvertibleLoanMutation = gql`
  mutation saveConvertibleLoan($equityOfferingId: Long, $convertibleLoan: ConvertibleLoanInput) {
    saveConvertibleLoan(equityOfferingId: $equityOfferingId, convertibleLoan: $convertibleLoan) {
      ${convertibleLoansGQLString}
    }
  }
`;

interface UseSaveConvertibleLoanMutation {
  equityOfferingId: number;
  convertibleLoan: IConvertibleLoan;
}

export function useSaveConvertibleLoan() {
  const [mutateConvertibleLoan, { loading, data }] = useMutation(ventureConvertibleLoanMutation);

  const saveConvertibleLoan = useCallback(
    async (values: UseSaveConvertibleLoanMutation) => {
      await mutateConvertibleLoan({
        variables: values,
      });
    },
    [mutateConvertibleLoan],
  );
  return { saveConvertibleLoan, loading, savedConvertibleLoan: data?.saveConvertibleLoan };
}

export const convertibleLoanInvestorsQuery = gql`
  query ($ventureId: Long) {
    convertibleLoanInvestors(ventureId: $ventureId)
  }
`;

export function useGetConvertibleLoanInvestors(
  ventureId: number | undefined,
  params?: QueryHookOptions,
) {
  const { data, loading, error, refetch } = useQuery<{
    convertibleLoanInvestors: string[];
  }>(convertibleLoanInvestorsQuery, {
    variables: { ventureId },
    ...params,
  });

  const convertibleLoanInvestorsOptions = useMemo(() => {
    if (data) {
      return data.convertibleLoanInvestors.map((el) => ({
        label: el,
        value: el,
      }));
    }
    return [];
  }, [data]);

  return {
    convertibleLoanInvestors: data?.convertibleLoanInvestors || [],
    convertibleLoanInvestorsOptions,
    loading,
    error,
    refetch,
  };
}
