import { AQString } from 'src/shared/AQString';
import CheckCircleM24Px from 'public/svg-components/CheckCircleM24Px';
import ChevronRight from 'public/svg-components/ChevronRight';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { ServerSharedText } from '../../texts/text';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';
import AlertCircle24Px from 'public/svg-components/AlertCircle24Px';

interface StepItemProps {
  step: StepIndicatorObject;
  index: number;
  onClick?: (v: StepIndicatorObject) => void;
}

export const StepListItem = ({ step, index, onClick }: StepItemProps) => {
  const { label, available, active, complete, fail } = step;

  const onClickHandler = () => {
    if (available && !active && onClick) onClick(step);
  };

  return (
    <>
      {index !== 0 && (
        <div className={classNames('flex', classes['item__chevron-wrapper'])}>
          <ChevronRight />
        </div>
      )}
      <div
        className={classNames('flex xs', classes['item'], {
          [classes['active']]: active && !fail,
          [classes['cursor-pointer']]: onClick && available,
        })}
        onClick={onClickHandler}
      >
        {complete && <CheckCircleM24Px />}
        {fail && <AlertCircle24Px />}
        {!fail && !complete && (
          <div
            className={classNames('flex step-number', {
              ['active']: active,
            })}
          >
            <ServerSharedText type="label-s" color="body">
              {index + 1}
            </ServerSharedText>
          </div>
        )}
        <ServerSharedText
          type={active && !fail ? 'label-s' : 'text-small'}
          color={step.active && !fail ? 'primary600' : 'grey500'}
        >
          <AQString componentId={label} />
        </ServerSharedText>
      </div>
    </>
  );
};
