import { DropdownIndicatorProps, SingleValueProps, components } from 'react-select';

import ChevronUp16Px from 'public/svg-components/ChevronUp16Px';
import ChevronDown16Px from 'public/svg-components/ChevronDown16Px';
import Clock from 'public/svg-components/Clock';
import Close24Px from 'public/svg-components/Close24Px';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';
import { OptionWithImageExpend } from '../types';

interface CustomDropdownIndicatorProps extends DropdownIndicatorProps {
  isSmall?: boolean;
}

export const DropdownIndicatorСlock = () => {
  return (
    <div className={classes['dropdown-indicator__clock']}>
      <Clock />
    </div>
  );
};

export const DropdownIndicatorWithClear = ({
  selectProps,
  hasValue,
  clearValue,
}: CustomDropdownIndicatorProps) => {
  const { isLoading, onMenuClose } = selectProps;

  if (isLoading) return null;

  const iconClassName = classNames(classes['dropdown-indicator-chevron-16px'], {
    [classes['dropdown-indicator-chevron-16px--open']]: selectProps.menuIsOpen,
  });

  return hasValue ? (
    <div
      className={classes.indicator}
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        clearValue();
        onMenuClose();
      }}
    >
      <Close24Px width={16} height={16} viewBox="0 0 22 22" />
    </div>
  ) : (
    <div className={classNames('flex')}>
      {selectProps.menuIsOpen ? (
        <ChevronUp16Px className={iconClassName} />
      ) : (
        <ChevronDown16Px className={iconClassName} />
      )}
    </div>
  );
};

export const DropdownIndicatorChevron16px = (props: DropdownIndicatorProps) => {
  const {
    selectProps: { menuIsOpen },
  } = props;

  const iconClassName = classNames(classes['dropdown-indicator-chevron-16px'], {
    [classes['dropdown-indicator-chevron-16px--open']]: menuIsOpen,
  });

  return (
    <components.DropdownIndicator {...props}>
      {menuIsOpen ? (
        <ChevronUp16Px className={iconClassName} />
      ) : (
        <ChevronDown16Px className={iconClassName} />
      )}
    </components.DropdownIndicator>
  );
};

interface DropdownIndicator16pxProps extends DropdownIndicatorProps {
  isBlackChevron?: boolean;
}

export const DropdownIndicator16px = ({
  selectProps,
  isBlackChevron = false,
}: DropdownIndicator16pxProps) => {
  return (
    <div
      className={classNames(classes['dropdown-indicator-chevron-16px__simple'], {
        [classes['black']]: isBlackChevron,
      })}
    >
      {selectProps.menuIsOpen ? <ChevronUp16Px /> : <ChevronDown16Px />}
    </div>
  );
};

export const DropdownIndicatorClear = ({ ...props }: SingleValueProps<OptionWithImageExpend>) => {
  if (props.hasValue === true) {
    return (
      <div className={classes['dropdown-indicator-clear']}>
        <Close24Px width={24} height={24} viewBox="0 0 24 24" onClick={props.clearValue} />
      </div>
    );
  }
  return null;
};
