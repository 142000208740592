import ArrowLeft24Px from 'public/svg-components/ArrowLeft24Px';
import Close24Px from 'public/svg-components/Close24Px';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ModalHeaderProps } from 'src/server-shared/modals/modal/types';
import { classNames } from 'src/utils/classNames';
import classes from '../index.module.scss';

export const ModalHeader = ({
  title,
  subtitle,
  closeEvent,
  headerSize = 'medium',
  isHeaderStyle = true,
  withBorder = true,
  withBackButton = false,
  headerCloseEvent,
  backButtonEvent,
}: ModalHeaderProps) => {
  const isMedium = headerSize === 'medium';
  return (
    <div
      role="modal-header"
      data-border={withBorder ? ' ' : 'none'}
      className={classNames(classes['modal-header'], {
        [classes[`modal-header__${headerSize}`]]: true,
        [classes['modal-header--border']]: withBorder,
        [classes['modal-header--back-btn']]: withBackButton,
      })}
    >
      {isHeaderStyle ? (
        <>
          {withBackButton && (
            <ArrowLeft24Px onClick={backButtonEvent} className="align-self-center" />
          )}
          <div
            className={classNames('grid-row', {
              xxs: isMedium,
              xs: !isMedium,
            })}
          >
            {title && (
              <ServerSharedText type={isMedium ? 'label-l' : 'header'} as="h4" color="grey800">
                {title}
              </ServerSharedText>
            )}
            {subtitle && (
              <ServerSharedText type={isMedium ? 'text-small' : 'text'} color="grey500" as="p">
                {subtitle}
              </ServerSharedText>
            )}
          </div>
        </>
      ) : (
        <>{title}</>
      )}
      <Close24Px
        className={classes['close-icon']}
        onClick={headerCloseEvent || closeEvent}
        width={12}
        height={12}
        viewBox="2 2 20 20"
      />
    </div>
  );
};
