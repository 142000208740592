import ReactDOMServer from 'react-dom/server';

import { DittoProvider } from 'src/providers/ditto-provider';
import { AQString } from 'src/shared/AQString';

export const getPlaceholder = (value?: string | JSX.Element, variables = {}): string => {
  if (!value) {
    return '';
  }

  return ReactDOMServer.renderToString(
    <DittoProvider>
      {typeof value === 'string' ? <AQString componentId={value} variables={variables} /> : value}
    </DittoProvider>,
  );
};
