import { SVGProps, memo } from 'react';

const SvgMenu = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M3.875 17.003h16.25M3.875 12.002h16.25M3.875 7.003h16.25"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgMenu);
export default Memo;
