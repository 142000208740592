import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRouter } from 'next/router';

import { AQString } from 'src/shared/AQString';
import { ProfileCompetitionContentProps, StepCardProps } from '../types';
import {
  HOME_PAGE,
  INVESTMENT_MANAGER_PERSONAL_DATA_PAGE,
  INVESTMENT_MANAGER_IDENTIFICATION_PAGE,
  INVESTMENT_MANAGER_ACCREDITATION_PAGE,
} from 'src/constants/paths';
import {
  ERoleAccreditationStatus,
  Roles,
  User,
  UserIdentificationStatus,
} from 'src/interfaces/user';
import { contactSupportHandler, fiveSeconds, tenMin } from '../shared';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { useSaveIdentificationDetails } from 'src/queries/users/user-identification-details.query';
import { RegistrationStep } from '../components/step';
import { useGetRoleAccreditation } from 'src/queries/role-accreditation';
import { RoleAccreditationDetails } from 'src/interfaces/roleAccreditation';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { IdentificationButtons } from '../components/identification-buttons';
import { identificationIsPassed, isPersonalDataIsFilled } from '../founder';
import { rolesLabel } from 'src/constants/common';
import CheckCircleM24Px from 'public/svg-components/CheckCircleM24Px';
import TimeClockCircleM24Px from 'public/svg-components/TimeClockCircleM24Px';
import AlertCircle24Px from 'public/svg-components/AlertCircle24Px';
import Pen20Px from 'public/svg-components/Pen20Px';
import { mixpanelEvents, useMixpanel } from 'src/hooks/useMixpanel';

const accreditationIcons = {
  [ERoleAccreditationStatus.NOT_STARTED]: <Pen20Px width={16} height={16} viewBox="0 0 20 20" />,
  [ERoleAccreditationStatus.ACCEPTED]: (
    <CheckCircleM24Px width={16} height={16} viewBox="0 0 24 24" />
  ),
  [ERoleAccreditationStatus.WAITING]: (
    <TimeClockCircleM24Px width={16} height={16} viewBox="0 0 24 24" />
  ),
  [ERoleAccreditationStatus.REFUSED]: (
    <AlertCircle24Px width={16} height={16} viewBox="0 0 24 24" />
  ),
};

export const isPartnersInvestorPersonalDataIsFilled = (
  user: User,
  accreditationDetails: RoleAccreditationDetails | null,
): boolean => {
  const isPersonalDataIsFilled = [user.firstName, user.lastName, user.gender, user.title].every(
    Boolean,
  );

  const isAccreditationIsFinished = accreditationDetails?.industries
    ? accreditationDetails?.industries?.length > 0
    : false;

  return isPersonalDataIsFilled && isAccreditationIsFinished;
};

export const accreditationIsPassed = (user: User) => {
  return user.roleAssignments
    .filter((el) => el.role !== Roles.INVESTMENT_MANAGER)
    .some((el) => el.verificationStatus === ERoleAccreditationStatus.ACCEPTED);
};

type InvestmentManagerRegistrationStage = 'PERSONAL_DATA' | 'IDENTIFICATION' | 'ACCREDITATION';

export const InvestmentManagerRegistration = ({
  user,
  refetch,
}: ProfileCompetitionContentProps) => {
  const { track } = useMixpanel();
  const { saveIdentification } = useSaveIdentificationDetails();
  const [showRetryButton, setShowRetryButton] = useState(false);

  const { userRoleAccreditationDetails } = useGetRoleAccreditation();
  const initialRefetchRef = useRef(false);

  const { push } = useRouter();

  const activeStage = useMemo<InvestmentManagerRegistrationStage>(() => {
    if (!isPersonalDataIsFilled(user)) return 'PERSONAL_DATA';

    const isIdentificationIsFinished =
      user?.identificationDetails?.status === UserIdentificationStatus.SUCCESS;
    if (!isIdentificationIsFinished) return 'IDENTIFICATION';

    return 'ACCREDITATION';
  }, [user]);

  const personalDataStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'PERSONAL_DATA') {
      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.registration.personal-data.subtitle',
      };
    }
    return { status: 'COMPLETED', showSubtitle: false };
  }, [activeStage]);

  const identificationStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'ACCREDITATION') return { status: 'COMPLETED', showSubtitle: false };

    if (activeStage === 'IDENTIFICATION') {
      if (user.identificationDetails?.status === UserIdentificationStatus.FAILED) {
        return {
          status: 'FAILED',
          subtitle: 'aquaty-app.profile-completion.founder.identification.failed.subtitle',
        };
      }

      if (user.identificationDetails?.status === UserIdentificationStatus.PENDING) {
        return {
          status: 'WAITING',
          subtitle: (
            <AQString
              componentId="aquaty-app.profile-completion.investment-accounting-identification.pending.subtitle"
              variables={{ email: user.email || '' }}
            />
          ),
        };
      }

      return {
        status: 'INPROGRESS',
        subtitle:
          'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
      };
    }

    return {
      status: 'TODO',
      subtitle:
        'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
      showSubtitle: false,
    };
  }, [activeStage, user]);

  const roleAssignments = useMemo(() => {
    return user.roleAssignments.filter((el) => el.role !== Roles.INVESTMENT_MANAGER);
  }, [user]);

  const accreditationStep = useMemo<StepCardProps>(() => {
    if (activeStage === 'ACCREDITATION') {
      const statuses = roleAssignments.map((el) => el.verificationStatus);
      if (statuses.includes(ERoleAccreditationStatus.REFUSED)) {
        return {
          status: 'FAILED',
        };
      }

      if (statuses.includes(ERoleAccreditationStatus.WAITING)) {
        return {
          status: 'WAITING',
          subtitle: (
            <AQString
              componentId="aquaty-app.profile-completion.investment-manager-accreditation.waiting.subtitle"
              variables={{ email: user.email || '' }}
            />
          ),
        };
      }

      return {
        status: 'INPROGRESS',
        subtitle: 'aquaty-app.profile-completion.investor-accreditation.not-started.subtitle',
      };
    }
    return { status: 'TODO' };
  }, [activeStage, roleAssignments, user.email]);

  const goToPersonalData = useCallback(() => {
    push(INVESTMENT_MANAGER_PERSONAL_DATA_PAGE);
  }, [push]);

  const goToAccreditation = useCallback(() => {
    push(INVESTMENT_MANAGER_ACCREDITATION_PAGE);
  }, [push]);

  useEffect(() => {
    let identificationInterval: any = undefined;
    let userInterval: any = undefined;

    if (user?.identificationDetails?.status === UserIdentificationStatus.PENDING) {
      setShowRetryButton(true);
      if (!initialRefetchRef.current) {
        initialRefetchRef.current = true;
        saveIdentification();
      }

      identificationInterval = setInterval(() => {
        saveIdentification();
      }, tenMin);
      userInterval = setInterval(() => refetch(), fiveSeconds);
    } else {
      clearInterval(identificationInterval);
      clearInterval(userInterval);
    }

    return () => {
      clearInterval(identificationInterval);
      clearInterval(userInterval);
    };
  }, [user, saveIdentification, refetch]);

  const finishRoleAccreditation = useMemo(() => {
    return userRoleAccreditationDetails?.regulatoryConfirmations?.length === 3;
  }, [userRoleAccreditationDetails?.regulatoryConfirmations?.length]);

  useEffect(() => {
    if (
      isPartnersInvestorPersonalDataIsFilled(user, userRoleAccreditationDetails) &&
      identificationIsPassed(user) &&
      accreditationIsPassed(user)
    ) {
      track(mixpanelEvents.fullProfileCompletion, {
        ['Role']: 'investment_manager',
      });
      push(HOME_PAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, userRoleAccreditationDetails, push]);

  const isAccreditationStage = useMemo(() => {
    return activeStage === 'ACCREDITATION';
  }, [activeStage]);

  return (
    <div className="grid-row">
      <div className="grid-row xs">
        <ServerSharedText type="header">
          <AQString componentId="aquaty-app.profile-completion.founder.title" />
        </ServerSharedText>

        <ServerSharedText color="grey500" as="pre">
          <AQString
            componentId="aquaty-app.profile-completion.founder.subtitle"
            variables={{ email: user.email || '' }}
          />
        </ServerSharedText>
      </div>

      <div className="grid-row m">
        <RegistrationStep
          title="aquaty-app.registration.personal-data.title"
          {...personalDataStep}
          index={1}
          button={
            activeStage === 'PERSONAL_DATA' ? (
              <ServerSharedButton
                onClick={goToPersonalData}
                label={<AQString componentId="aquaty-app.profile-completion.start-personal-data" />}
                variant="inform"
              />
            ) : null
          }
        />

        <RegistrationStep
          title="aquaty-app.profile-completion.account-identification"
          {...identificationStep}
          index={2}
          button={
            activeStage === 'IDENTIFICATION' ? (
              <IdentificationButtons
                path={INVESTMENT_MANAGER_IDENTIFICATION_PAGE}
                showRetryButton={showRetryButton}
                status={user?.identificationDetails?.status}
                buttonLabel="aquaty-app.profile-completion.start-account-identification"
              />
            ) : null
          }
        />

        <RegistrationStep
          title="aquaty-app.profile-completion.investment-manager-accreditation.title"
          {...accreditationStep}
          index={3}
          button={
            isAccreditationStage ? (
              <>
                {!finishRoleAccreditation && (
                  <ServerSharedButton
                    onClick={goToAccreditation}
                    label={
                      <AQString componentId="aquaty-app.profile-completion.start-investment-manager-accreditation" />
                    }
                    variant="inform"
                  />
                )}
              </>
            ) : null
          }
        >
          {isAccreditationStage && roleAssignments.length > 0 ? (
            <div className="grid-row m">
              <div className="grid-row xs" style={{ marginTop: '4px' }}>
                {roleAssignments.map((el) => {
                  return (
                    <div
                      key={el.role}
                      className="grid-column template-mc-1fr xs align-items-center"
                    >
                      {accreditationIcons[el.verificationStatus]}
                      <ServerSharedText color="grey500" type="text-small">
                        <AQString componentId={rolesLabel[el.role]} />
                      </ServerSharedText>
                    </div>
                  );
                })}
              </div>
              {finishRoleAccreditation && (
                <ServerSharedButton
                  style={{ width: 177, padding: '11px 0' }}
                  onClick={contactSupportHandler}
                  label={<AQString componentId="aquaty-generic.menu.contact-support.title" />}
                  variant="secondary"
                />
              )}
            </div>
          ) : null}
        </RegistrationStep>
      </div>
    </div>
  );
};
