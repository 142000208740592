'use client';

import { DittoProvider as DittoProviderContainer } from 'ditto-react';

import dittoData from 'ditto';

export function DittoProvider({ children }: { children: React.ReactNode }) {
  return (
    <DittoProviderContainer source={dittoData} variant="base" projectId="ditto_component_library">
      {children}
    </DittoProviderContainer>
  );
}
