import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { gerErrorCode } from 'src/store/app/reducer';

export const ErrorCatcher = () => {
  const code = useSelector(gerErrorCode);

  useEffect(() => {
    if (code) throw 'Please ignore that error message - http error.';
  }, [code]);

  return <></>;
};
