import { SVGProps, memo } from 'react';

const SvgWaitListSelected24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M11.375 16.376C11.375 17.7021 11.9018 18.9738 12.8395 19.9115C13.7771 20.8492 15.0489 21.376 16.375 21.376C17.7011 21.376 18.9729 20.8492 19.9105 19.9115C20.8482 18.9738 21.375 17.7021 21.375 16.376C21.375 15.0499 20.8482 13.7781 19.9105 12.8404C18.9729 11.9028 17.7011 11.376 16.375 11.376C15.0489 11.376 13.7771 11.9028 12.8395 12.8404C11.9018 13.7781 11.375 15.0499 11.375 16.376Z"
      fill="white"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.585 16.376H16.375V14.166"
      stroke="#0D1117"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3425 9.13681C20.2067 8.45181 21.375 7.47014 21.375 6.37598C21.375 4.30431 17.1775 2.62598 12 2.62598C6.8225 2.62598 2.625 4.30431 2.625 6.37598C2.625 8.44764 6.8225 10.126 12 10.126"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.625 11.376C2.625 13.0293 5.30083 14.4326 9.01417 14.931"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.625 6.37598V16.376C2.625 18.1468 5.69417 19.631 9.82083 20.0235"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.375 10.786V6.37598"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M18.3425 9.13681C20.2067 8.45181 21.375 7.47014 21.375 6.37598C21.375 4.30431 17.1775 2.62598 12 2.62598C6.8225 2.62598 2.625 4.30431 2.625 6.37598C2.625 8.44764 6.8225 10.126 12 10.126"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.625 11.376C2.625 13.0293 5.30083 14.4326 9.01417 14.931"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M2.625 6.37598V16.376C2.625 18.1468 5.69417 19.631 9.82083 20.0235"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M21.375 10.786V6.37598"
      stroke="white"
      strokeWidth="1.25"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgWaitListSelected24Px);
export default Memo;
