import { DataRoomSectionEvaluation } from 'src/interfaces/data-room-evaluation';
import { sectionKeys } from './shared';

export const defineSections = (
  oldSections: DataRoomSectionEvaluation[],
  newSection?: DataRoomSectionEvaluation,
): DataRoomSectionEvaluation[] => {
  if (!newSection) return oldSections;
  const isUpdateSection = oldSections.some((el) => el.id === newSection.id);
  if (isUpdateSection) {
    return oldSections.map((el) => (el.id === newSection.id ? newSection : el));
  }
  return oldSections.concat(newSection);
};

export const defaultValuesMapper = (
  data: DataRoomSectionEvaluation[],
  saved?: DataRoomSectionEvaluation,
) => {
  const sections = defineSections(data, saved);

  const values = sections.reduce((acc, curr) => {
    return {
      ...acc,
      [sectionKeys[curr.section].text]: curr.evaluationText,
      [sectionKeys[curr.section].attachments]:
        curr.attachments.length > 0
          ? [...curr.attachments].sort((a: any, b: any) => b.id - a.id)
          : [],
      [sectionKeys[curr.section].id]: curr.id || null,
      ...curr.attributeEvaluations?.reduce((acc, curr) => {
        return { ...acc, [curr.dataRoomSectionAttribute]: curr };
      }, {} as Record<string, any>),
    };
  }, {} as Record<string, any>);

  return values;
};
