import { ServerSharedText, TextTypes } from '../text';

interface UserPlaceholderIconProps {
  fullName?: string;
  textType?: TextTypes;
}

export const UserPlaceholderIcon = ({ fullName, textType = 'label' }: UserPlaceholderIconProps) => {
  const firstCharacter = (fullName || '').slice(0, 1).toUpperCase();
  return <ServerSharedText type={textType}>{firstCharacter}</ServerSharedText>;
};
