import { useCallback, useRef, useState } from 'react';
import * as PDFJS from 'pdfjs-dist';
import { uploadToS3 } from './s3';
import { generateUUID } from 'src/utils/uuid';
import { PixelCrop } from 'react-image-crop';

import { Progress, IFile } from './types';

export const MAX_PDF_SIZE = 5242880; // 5Mb
export const BIG_PDF_SIZE = 20971520; // 20Mb
export const MAX_FILE_SIZE = 26214400; // 25Mb
export const MAX_VIDEO_SIZE = 786432000; // 750Mb

export const CANCEL_BY_USER = 'cancel_by_user';

/**
 * Hook to control the progress bar of the FileInput modal during the upload process.
 *
 * @returns 2-tuple - [progress, { clearProgress, initializeProgress, updateProgress }]
 */
export const useProgress = () => {
  const ref = useRef<Progress>({});
  const [progress, setProgress] = useState<Progress | null>(null);

  const updateProgress = useCallback(
    ({ id, progress: newProgress }) => {
      ref.current = {
        ...ref.current,
        [id]: { progress: newProgress },
      };
      setProgress(ref.current);
    },
    [setProgress],
  );

  const clearProgress = useCallback(
    (id: string) => {
      delete ref.current[id];
      setProgress(ref.current);
    },
    [setProgress],
  );

  const initializeProgress = useCallback(
    (createFiles: IFile[]) => {
      setProgress(null);
      ref.current = createFiles.reduce((acc: Progress, obj: IFile) => {
        if (obj.id) {
          acc[obj.id] = { progress: 0, id: obj.id };
        }
        return acc;
      }, {});
    },
    [setProgress],
  );

  return [progress, { clearProgress, initializeProgress, updateProgress }];
};

export const getVideoCover = (file: File | string, seekTo = 0.0) =>
  new Promise<Blob | null>((resolve, reject) => {
    // load the file to a video player
    const videoPlayer = document.createElement('video');
    videoPlayer.setAttribute('src', typeof file === 'string' ? file : URL.createObjectURL(file));
    videoPlayer.load();
    videoPlayer.addEventListener('error', () => {
      reject('error when loading video file');
    });
    // load metadata of the video to get video duration and dimensions
    videoPlayer.addEventListener('loadedmetadata', () => {
      // seek to user defined timestamp (in seconds) if possible
      if (videoPlayer.duration < seekTo) {
        reject('video is too short.');
        return;
      }
      // delay seeking or else 'seeked' event won't fire on Safari
      setTimeout(() => {
        videoPlayer.currentTime = seekTo;
      }, 200);
      // extract video thumbnail once seeking is complete
      videoPlayer.addEventListener('seeked', () => {
        console.log('video is now paused at %ss.', seekTo);
        // define a canvas to have the same dimension as the video
        const canvas = document.createElement('canvas');
        canvas.width = videoPlayer.videoWidth;
        canvas.height = videoPlayer.videoHeight;
        // draw the video frame to canvas
        const ctx = canvas.getContext('2d');
        ctx?.drawImage(videoPlayer, 0, 0, canvas.width, canvas.height);
        // return the canvas image as a blob
        ctx?.canvas.toBlob(
          (blob) => {
            resolve(blob);
          },
          'image/jpeg',
          0.75 /* quality */,
        );
      });
    });
  });

export const bytesToMegaBytes = (bytes: number) => (bytes / 1024 ** 2).toFixed(2);

export const defaultFileExtensions = [
  '.pdf',
  '.csv',
  '.tsv',
  '.xlsx',
  '.xls',
  '.ods',
  '.ppt',
  '.pptx',
  '.odp',
  '.png',
  '.jpeg',
  '.jpg',
  '.tiff',
  '.svg',
  '.doc',
  '.docx',
  '.rtf',
  '.txt',
  '.odt',
  '.zip',
  '.gzip',
  '.mp4',
  '.mp3',
  '.wav',
  '.ogg',
  '.css',
];

export const previewFileExtensions = ['.png', '.jpeg', '.jpg', '.tiff', '.svg'];
export const videoFileExtensions = ['.mp4'];
export const pictureFileExtensions = ['.png', '.jpeg', '.jpg'];
export const pdfFileExtensions = ['.pdf', '.csv'];
export const onlyPdfExtensions = ['.pdf'];

export const filterBadFiles = (goodExtensions: string[]) => (fi: any) => {
  const ext = fi.name.split('.').pop();
  return !ext || !goodExtensions.includes(`.${ext.toLowerCase()}`);
};

export const formatSizeUnits = (bytes: number) => {
  let result: string;
  if (bytes >= 1073741824) {
    result = (bytes / 1073741824).toFixed(2) + ' GB';
  } else if (bytes >= 1048576) {
    result = (bytes / 1048576).toFixed(2) + ' MB';
  } else if (bytes >= 1024) {
    result = (bytes / 1024).toFixed(2) + ' KB';
  } else if (bytes > 1) {
    result = bytes + ' bytes';
  } else if (bytes === 1) {
    result = bytes + ' byte';
  } else {
    result = '0 bytes';
  }
  return result;
};

export const imageCompressOptions = {
  maxSizeMB: MAX_FILE_SIZE,
  maxWidthOrHeight: 1920,
  useWebWorker: true,
};

export const convertPdfStringToImages = async (file: string, isOnePreviewFile: boolean) => {
  const images: string[] = [];
  const pdf = await PDFJS.getDocument({ url: file }).promise;
  const canvas = document.createElement('canvas');
  const numPages = isOnePreviewFile ? 1 : pdf.numPages;
  const context = canvas.getContext('2d');
  for (let i = 0; i < numPages; i++) {
    const page = await pdf.getPage(i + 1);
    canvas.width = document.body.clientWidth;
    const scale = canvas.width / page.view[2];
    const viewport = page.getViewport({ scale: scale });
    canvas.height = page.view[3] * scale;
    if (context) {
      await page.render({ canvasContext: context, viewport: viewport }).promise;
    }
    images.push(canvas.toDataURL('image/png'));
  }
  canvas.remove();
  return images;
};

export const convertPdfToImages = async (file: File, isOnePreviewFile: boolean) => {
  const uri = URL.createObjectURL(file);
  return await convertPdfStringToImages(uri, isOnePreviewFile);
};

function dataURLtoFile(dataurl: any, filename: string) {
  const arr = dataurl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const uploadPreview = async (
  isVideo: boolean,
  isPDF: boolean,
  blob: any,
  id: string,
  uploadedFile: File,
  isOnePreviewFile: boolean,
): Promise<string[] | null> => {
  if (isVideo) {
    const result = await uploadToS3({ file: blob as Blob, fileName: `${id}.jpeg` });
    return [result.key];
  }
  if (isPDF) {
    const images = await convertPdfToImages(uploadedFile, isOnePreviewFile);
    const files = images.map((el) => {
      const id = generateUUID();
      return dataURLtoFile(el, `${id}.jpeg`);
    });

    const result = await Promise.all(
      files.map(async (el) => {
        return await uploadToS3({ fileName: el.name, file: el });
      }),
    );

    return result.map((el) => el.key);
  }
  return await new Promise((res) => res(null));
};

export const checkIsImage = (fileName: string) => {
  const ext = fileName.split('.').pop();
  return previewFileExtensions.includes(`.${ext && ext.toLowerCase()}`);
};

export async function reactCropCanvasPreview(image: HTMLImageElement, crop: PixelCrop, scale = 1) {
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d');

  if (!ctx) {
    throw new Error('No 2d context');
  }

  const scaleX = image.naturalWidth / image.width;
  const scaleY = image.naturalHeight / image.height;
  // devicePixelRatio slightly increases sharpness on retina devices
  // at the expense of slightly slower render times and needing to
  // size the image back down if you want to download/upload and be
  // true to the images natural size.
  const pixelRatio = window.devicePixelRatio;
  // const pixelRatio = 1

  canvas.width = Math.floor(crop.width * scaleX * pixelRatio);
  canvas.height = Math.floor(crop.height * scaleY * pixelRatio);

  ctx.scale(pixelRatio, pixelRatio);
  ctx.imageSmoothingQuality = 'high';

  const cropX = crop.x * scaleX;
  const cropY = crop.y * scaleY;

  const centerX = image.naturalWidth / 2;
  const centerY = image.naturalHeight / 2;

  ctx.save();

  // 5) Move the crop origin to the canvas origin (0,0)
  ctx.translate(-cropX, -cropY);
  // 4) Move the origin to the center of the original position
  ctx.translate(centerX, centerY);
  // 2) Scale the image
  ctx.scale(scale, scale);
  // 1) Move the center of the image to the origin (0,0)
  ctx.translate(-centerX, -centerY);
  ctx.drawImage(
    image,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
    0,
    0,
    image.naturalWidth,
    image.naturalHeight,
  );

  ctx.restore();

  const base64 = canvas.toDataURL('image/png');
  const r = await fetch(base64);
  return r.blob();
}
