import { useCallback } from 'react';
import { gql, QueryHookOptions, useMutation, useQuery } from '@apollo/client';

import { fileFields } from '../../common.strings';
import { VentureSolution, VentureSolutionRequest } from 'src/interfaces/venture-pitch';

export const venturePitchSolutionGQLString = `
description
images {
  ${fileFields}
}
`;

const venturePitchSolutionQuery = gql`
  query venturePitchSolution($equityOfferingId: Long!) {
    venturePitchSolution(equityOfferingId: $equityOfferingId) {
      ${venturePitchSolutionGQLString}
    }
  }
`;

const venturePitchSolutionMutation = gql`
  mutation saveVenturePitchSolution($venturePitchSolution: VenturePitchSolutionInput) {
    saveVenturePitchSolution(venturePitchSolution: $venturePitchSolution) {
      ${venturePitchSolutionGQLString}
    }
  }
`;

type UseGetVenturePitchSolution = { venturePitchSolution: VentureSolution };

export const useGetVenturePitchSolution = (
  id?: number,
  onCompleted?: (data: UseGetVenturePitchSolution) => void,
  params?: QueryHookOptions,
) => {
  const { data, loading } = useQuery<UseGetVenturePitchSolution>(venturePitchSolutionQuery, {
    variables: { equityOfferingId: id },
    onCompleted,
    fetchPolicy: 'no-cache',
    ...params,
  });
  return { venturePitchSolution: data?.venturePitchSolution, loading };
};

type UseSaveVenturePitchSolutionMutation = { venturePitchSolution: VentureSolutionRequest };

export const useSaveVenturePitchSolution = () => {
  const [mutateVenturePitchSolution, { loading, data }] = useMutation<
    { saveVenturePitchSolution: VentureSolution },
    UseSaveVenturePitchSolutionMutation
  >(venturePitchSolutionMutation);

  const saveVenturePitchSolution = useCallback(
    async (variables: UseSaveVenturePitchSolutionMutation) => {
      await mutateVenturePitchSolution({ variables });
    },
    [mutateVenturePitchSolution],
  );
  return {
    saveVenturePitchSolution,
    loading,
    venturePitchSolution: data?.saveVenturePitchSolution,
  };
};
