import { layers } from './layers';
import { palette } from './palette';
import { spacing } from './spacing';
import { breakpoints } from './breakpoints';
import { borderRadius } from './border-radius';

// TODO rm that constant from JS
export const HEADER_HEIGHT = 48;

export const theme = {
  palette,
  spacing,
  layers,
  breakpoints,
  borderRadius,
};
