import { FC, useState, useMemo, useCallback, createContext, useRef, useEffect } from 'react';
import { useRouter } from 'next/router';

import { ConfirmationModal } from 'src/server-shared/modals/feedback/confirmation-modal';

interface ConfirmationContextValue {
  updateIsDirty: (isDirty: boolean) => any;
}

export const ConfirmationContext = createContext<ConfirmationContextValue>({
  updateIsDirty: () => undefined,
});

const ConfirmationProvider: FC = ({ children }) => {
  const navigateAwayPath = useRef<string>('');
  const isDirty = useRef<boolean>(false);

  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const router = useRouter();

  const closeModal = useCallback(() => {
    setShowConfirmationModal(false);
  }, []);

  const openConfirmationModal = useCallback(() => {
    setShowConfirmationModal(true);
  }, []);

  const updateIsDirty = useCallback((idDirty: boolean) => {
    isDirty.current = idDirty;
  }, []);

  const onConfirm = useCallback(() => {
    isDirty.current = false;

    setShowConfirmationModal(false);

    if (navigateAwayPath.current) {
      router.push(navigateAwayPath.current);
    }
  }, [router]);

  const handleRouteChange = useCallback(
    (path: string) => {
      navigateAwayPath.current = path;
      if (isDirty.current) {
        openConfirmationModal();
        throw 'routeChange aborted.';
      }
    },
    [openConfirmationModal],
  );

  useEffect(() => {
    router.events.on('routeChangeStart', handleRouteChange);
    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, handleRouteChange]);

  const contextValue = useMemo(
    () => ({
      updateIsDirty,
    }),
    [updateIsDirty],
  );

  return (
    <ConfirmationContext.Provider value={contextValue}>
      {children}

      <ConfirmationModal
        show={showConfirmationModal}
        closeEvent={closeModal}
        onConfirm={onConfirm}
      />
    </ConfirmationContext.Provider>
  );
};

export default ConfirmationProvider;
