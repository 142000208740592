import localFont from '@next/font/local';
import { Inter } from '@next/font/google';

import { NextFont } from '@next/font/dist/types';
import { AppFonts } from 'src/interfaces/common';

export const inter = Inter({ subsets: ['latin'], display: 'swap' });

export const openSans = localFont({
  src: [
    {
      path: '../../public/fonts/OpenSans/OpenSans-Regular.ttf',
      style: 'normal',
    },
    {
      path: '../../public/fonts/OpenSans/OpenSans-Bold.ttf',
      style: 'normal',
    },
  ],
  display: 'swap',
  preload: false,
});

export const aeonik = localFont({
  src: [
    {
      path: '../../public/fonts/Aeonik/Aeonik-Regular.otf',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Aeonik/Aeonik-Bold.otf',
      style: 'normal',
    },
  ],
  display: 'swap',
  preload: false,
});

export const fredoka = localFont({
  src: [
    {
      path: '../../public/fonts/Fredoka/Fredoka-Regular.ttf',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Fredoka/Fredoka-Bold.ttf',
      style: 'normal',
    },
  ],
  display: 'swap',
  preload: false,
});

export const playfair = localFont({
  src: [
    {
      path: '../../public/fonts/Playfair/PlayfairDisplay-Regular.ttf',
      style: 'normal',
    },
    {
      path: '../../public/fonts/Playfair/PlayfairDisplay-Bold.ttf',
      style: 'normal',
    },
  ],
  display: 'swap',
  preload: false,
});

export const appFontsMap = new Map<AppFonts, NextFont>([
  ['INTER', inter],
  ['OPEN_SANS', openSans],
  ['FREDOKA', fredoka],
  ['PLAYFAIR', playfair],
  ['AEONIK', aeonik],
]);

export const getFont = (f?: null | AppFonts): NextFont => {
  return appFontsMap.get(f || 'INTER') as NextFont;
};
