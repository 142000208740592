import {
  CONGRATULATIONS_PAGE,
  PROFILE_COMPLETION_PAGE,
  REGISTRATION_PAGE,
  WAIT_LIST_STILL_WAITING_PAGE,
} from 'src/constants/paths';
import { isDemo } from 'src/_middleware';
import { Roles, UserIdentificationStatus, UserRegistrationStatus } from 'src/interfaces/user';
import { RoleAccreditationDetails } from 'src/interfaces/roleAccreditation';
import { UserInvestmentAccount } from 'src/interfaces/profile-completion';
import { getSingleUserRole } from 'src/queries/users/get-user.query';
import {
  isPersonalDataIsFilled,
  hasVerifiedVenture,
  identificationIsPassed,
} from 'src/ui/profile-completion/founder';
import { accreditationIsPassed } from 'src/ui/profile-completion/investment-manager';
// import { hasVerifiedWallet } from 'src/ui/profile-completion/investor/investor-registration';

interface Instructions {
  skipAllRedirects: boolean;
}

export const checkFinishedRegister = (
  { user, industries }: RoleAccreditationDetails,
  userInvestmentAccounts: UserInvestmentAccount[],
  pathname: string,
): string | Instructions | null => {
  const userIdentificationIsSuccess =
    user?.identificationDetails?.status === UserIdentificationStatus.SUCCESS;

  const role = user ? getSingleUserRole(user) : undefined;
  if (user?.registrationStatus === UserRegistrationStatus.WAITLIST) {
    return WAIT_LIST_STILL_WAITING_PAGE;
  }
  if (role === Roles.SOFT_REGISTERED_VENTURE_INVESTOR) {
    return { skipAllRedirects: true };
  }

  if (
    pathname.includes(CONGRATULATIONS_PAGE) ||
    pathname.includes(PROFILE_COMPLETION_PAGE) ||
    pathname.includes(REGISTRATION_PAGE)
  ) {
    return { skipAllRedirects: true };
  }

  if (!user || !role) {
    return REGISTRATION_PAGE;
  }

  if (role === Roles.VENTURE_FOUNDER) {
    if (
      !isPersonalDataIsFilled(user) ||
      !identificationIsPassed(user) ||
      !hasVerifiedVenture(user)
    ) {
      return PROFILE_COMPLETION_PAGE;
    }

    if (!user.identificationDetails || !userIdentificationIsSuccess) {
      return PROFILE_COMPLETION_PAGE;
    }
    return null;
  }

  if (role === Roles.VENTURE_INVESTOR) {
    if (isDemo) return { skipAllRedirects: true };

    // const verifiedWallet = hasVerifiedWallet(userInvestmentAccounts);
    if (!identificationIsPassed(user) || !accreditationIsPassed(user)) {
      return PROFILE_COMPLETION_PAGE;
    }

    return { skipAllRedirects: true };
  }

  if (role === Roles.INVESTMENT_MANAGER) {
    if (isDemo) return { skipAllRedirects: true };

    const hasVerifiedRole = accreditationIsPassed(user);
    if (!industries?.length || !userIdentificationIsSuccess || !hasVerifiedRole) {
      return PROFILE_COMPLETION_PAGE;
    }

    return { skipAllRedirects: true };
  }

  return null;
};
