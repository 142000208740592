import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { useMemo } from 'react';

import { VenturePitch } from 'src/interfaces/venture-pitch';
import { standardPitchFields } from './venture-pitch.query';

export const venturePitchTargetsQuery = gql`
  query {
    venturePitchTargets {
      ${standardPitchFields}
      investmentOffering {
        requiredInvestmentAmount
      }
    }
  }
`;

type UseGetVenturePitchTargetsQuery = { venturePitchTargets: VenturePitch[] };

export function useGetVentureTargets(params?: QueryHookOptions) {
  const { data, loading, error, refetch } = useQuery<UseGetVenturePitchTargetsQuery>(
    venturePitchTargetsQuery,
    params,
  );

  const onlyIds = useMemo<number[]>(
    () => (data && data.venturePitchTargets ? data.venturePitchTargets.map((i) => i.id) : []),
    [data],
  );

  return {
    venturePitchTargets: data?.venturePitchTargets || [],
    onlyIds,
    loading,
    error,
    refetch,
  };
}
