import { MouseEventHandler } from 'react';
import { FormArray, Hook } from 'hook-easy-form';

import { User } from 'src/interfaces/user';
import { FileField } from 'src/interfaces/common';
import { DataRoomSectionAttributeDTO } from 'src/interfaces/configuration';
import {
  DataRoomEvaluationSteps,
  DataRoomSectionAttribute,
  DataRoomSectionAttributeEvaluation,
  DataRoomSectionEvaluation,
} from 'src/interfaces/data-room-evaluation';
import { EquityOfferingParticipants } from 'src/interfaces/offering';

export interface IStepProps {
  id: string;
  goToNextStep: (d?: any) => void;
  goToBack: MouseEventHandler<HTMLButtonElement>;
  showError: () => void;
  formProps: Hook<FormData>;
  activeStep: DataRoomEvaluationSteps;
  sections: DataRoomSectionEvaluation[];
  dataRoomEvaluation: DataRoomSectionEvaluation[];
  dataRoomSectionAttributes: { [key: string]: DataRoomSectionAttributeDTO };
  user: User;
  isPartner: boolean;
  isSame: boolean;
  equityOfferingParticipants: EquityOfferingParticipants[];
  hasPermissions: boolean;
  setSavedEvaluation?: (v: DataRoomSectionEvaluation) => void;
  setLastStepCompleted: () => void;
}

export const rangeSliderInputOptions = {
  min: 1,
  max: 5,
};

interface MapperKeys {
  text: SectionText;
  attachments: SectionAttachments;
  id: SectionIds;
}

export enum SectionIds {
  'PITCH_DECK' = 'pitchDeckId',
  'LEGAL_COMPANY_FOUNDATION' = 'legalCompanyFoundationId',
  'TEAM_AND_SHAREHOLDERS' = 'teamId',
  'PREVIOUS_FINANCING' = 'previousFinancingsId',
  'PRODUCT_AND_DEMO' = 'productId',
  'INTELLECTUAL_PROPERTY_AND_ASSETS' = 'intellectualPropertyId',
  'BUSINESS_MODEL' = 'businessModelId',
  'MARKET_OPPORTUNITY' = 'marketOpportunityId',
  'DISTRIBUTION_AND_GROWTH_STRATEGY' = 'distributionId',
  'FINANCIALS' = 'financialsId',
  'OTHERS' = 'othersId',
  'DEAL_CHARACTER' = '',
}

export enum SectionText {
  'PITCH_DECK' = 'pitchDeckText',
  'LEGAL_COMPANY_FOUNDATION' = 'legalCompanyFoundationText',
  'TEAM_AND_SHAREHOLDERS' = 'teamText',
  'PREVIOUS_FINANCING' = 'previousFinancingsText',
  'PRODUCT_AND_DEMO' = 'productText',
  'INTELLECTUAL_PROPERTY_AND_ASSETS' = 'intellectualPropertyText',
  'BUSINESS_MODEL' = 'businessModelText',
  'MARKET_OPPORTUNITY' = 'marketOpportunityText',
  'DISTRIBUTION_AND_GROWTH_STRATEGY' = 'distributionText',
  'FINANCIALS' = 'financialsText',
  'OTHERS' = 'othersText',
  'DEAL_CHARACTER' = '',
}

export enum SectionAttachments {
  'PITCH_DECK' = 'pitchDeckAttachments',
  'LEGAL_COMPANY_FOUNDATION' = 'legalCompanyFoundationAttachments',
  'TEAM_AND_SHAREHOLDERS' = 'teamAttachments',
  'PREVIOUS_FINANCING' = 'previousFinancingsAttachments',
  'PRODUCT_AND_DEMO' = 'productAttachments',
  'INTELLECTUAL_PROPERTY_AND_ASSETS' = 'intellectualPropertyAttachments',
  'BUSINESS_MODEL' = 'businessModelAttachments',
  'MARKET_OPPORTUNITY' = 'marketOpportunityAttachments',
  'DISTRIBUTION_AND_GROWTH_STRATEGY' = 'distributionAttachments',
  'FINANCIALS' = 'financialsAttachments',
  'OTHERS' = 'othersAttachments',
  'DEAL_CHARACTER' = '',
}

export const sectionKeys: Record<DataRoomEvaluationSteps, MapperKeys> = {
  [DataRoomEvaluationSteps.PITCH_DECK]: {
    text: SectionText.PITCH_DECK,
    attachments: SectionAttachments.PITCH_DECK,
    id: SectionIds.PITCH_DECK,
  },
  [DataRoomEvaluationSteps.LEGAL_COMPANY_FOUNDATION]: {
    text: SectionText.LEGAL_COMPANY_FOUNDATION,
    attachments: SectionAttachments.LEGAL_COMPANY_FOUNDATION,
    id: SectionIds.LEGAL_COMPANY_FOUNDATION,
  },
  [DataRoomEvaluationSteps.PREVIOUS_FINANCING]: {
    text: SectionText.PREVIOUS_FINANCING,
    attachments: SectionAttachments.PREVIOUS_FINANCING,
    id: SectionIds.PREVIOUS_FINANCING,
  },
  [DataRoomEvaluationSteps.TEAM_AND_SHAREHOLDERS]: {
    text: SectionText.TEAM_AND_SHAREHOLDERS,
    attachments: SectionAttachments.TEAM_AND_SHAREHOLDERS,
    id: SectionIds.TEAM_AND_SHAREHOLDERS,
  },
  [DataRoomEvaluationSteps.PRODUCT_AND_DEMO]: {
    text: SectionText.PRODUCT_AND_DEMO,
    attachments: SectionAttachments.PRODUCT_AND_DEMO,
    id: SectionIds.PRODUCT_AND_DEMO,
  },
  [DataRoomEvaluationSteps.INTELLECTUAL_PROPERTY_AND_ASSETS]: {
    text: SectionText.INTELLECTUAL_PROPERTY_AND_ASSETS,
    attachments: SectionAttachments.INTELLECTUAL_PROPERTY_AND_ASSETS,
    id: SectionIds.INTELLECTUAL_PROPERTY_AND_ASSETS,
  },
  [DataRoomEvaluationSteps.MARKET_OPPORTUNITY]: {
    text: SectionText.MARKET_OPPORTUNITY,
    attachments: SectionAttachments.MARKET_OPPORTUNITY,
    id: SectionIds.MARKET_OPPORTUNITY,
  },
  [DataRoomEvaluationSteps.BUSINESS_MODEL]: {
    text: SectionText.BUSINESS_MODEL,
    attachments: SectionAttachments.BUSINESS_MODEL,
    id: SectionIds.BUSINESS_MODEL,
  },
  [DataRoomEvaluationSteps.DISTRIBUTION_AND_GROWTH_STRATEGY]: {
    text: SectionText.DISTRIBUTION_AND_GROWTH_STRATEGY,
    attachments: SectionAttachments.DISTRIBUTION_AND_GROWTH_STRATEGY,
    id: SectionIds.DISTRIBUTION_AND_GROWTH_STRATEGY,
  },
  [DataRoomEvaluationSteps.FINANCIALS]: {
    text: SectionText.FINANCIALS,
    attachments: SectionAttachments.FINANCIALS,
    id: SectionIds.FINANCIALS,
  },
  [DataRoomEvaluationSteps.OTHERS]: {
    text: SectionText.OTHERS,
    attachments: SectionAttachments.OTHERS,
    id: SectionIds.OTHERS,
  },
  [DataRoomEvaluationSteps.DEAL_CHARACTER]: {
    text: SectionText.DEAL_CHARACTER,
    attachments: SectionAttachments.DEAL_CHARACTER,
    id: SectionIds.DEAL_CHARACTER,
  },
};

export const form = [
  // pitch-deck
  {
    name: sectionKeys[DataRoomEvaluationSteps.PITCH_DECK].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.PITCH_DECK].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.PITCH_DECK].id,
    value: null,
  },
  // legal-company-foundation
  {
    name: DataRoomSectionAttribute.LEGAL_ENTITY_STRUCTURE,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.LEGAL_ENTITY_STRUCTURE,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.LEGAL_COMPANY_FOUNDATION].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.LEGAL_COMPANY_FOUNDATION].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.LEGAL_COMPANY_FOUNDATION].id,
    value: null,
  },
  // previous-financings
  {
    name: sectionKeys[DataRoomEvaluationSteps.PREVIOUS_FINANCING].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.PREVIOUS_FINANCING].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.PREVIOUS_FINANCING].id,
    value: null,
  },
  // team-shareholders
  {
    name: DataRoomSectionAttribute.MANAGEMENT_TEAM_HETEROGENEITY,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.MANAGEMENT_TEAM_HETEROGENEITY,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.MANAGEMENT_TEAM_TRACK_RECORD,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.MANAGEMENT_TEAM_TRACK_RECORD,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.INVESTOR_SHAREHOLDER_CONCENTRATION,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.INVESTOR_SHAREHOLDER_CONCENTRATION,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.TEAM_AND_SHAREHOLDERS].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.TEAM_AND_SHAREHOLDERS].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.TEAM_AND_SHAREHOLDERS].id,
    value: null,
  },
  // product-and-demo
  {
    name: DataRoomSectionAttribute.PRODUCT_NEED_VALIDATION,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.PRODUCT_NEED_VALIDATION,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.PRODUCT_MATURITY,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.PRODUCT_MATURITY,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.PRODUCT_SCALABILITY,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.PRODUCT_SCALABILITY,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.PRODUCT_AND_DEMO].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.PRODUCT_AND_DEMO].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.PRODUCT_AND_DEMO].id,
    value: null,
  },
  // intellectual-property-and-assets
  {
    name: DataRoomSectionAttribute.DEFENSIBILITY,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.DEFENSIBILITY,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.CONCENTRATION_OF_KEY_KNOW_HOW_IN_THE_TEAM,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.CONCENTRATION_OF_KEY_KNOW_HOW_IN_THE_TEAM,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.INTELLECTUAL_PROPERTY_AND_ASSETS].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.INTELLECTUAL_PROPERTY_AND_ASSETS].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.INTELLECTUAL_PROPERTY_AND_ASSETS].id,
    value: null,
  },
  // market-opportunity-current-traction
  {
    name: DataRoomSectionAttribute.ADDRESSABLE_MARKET,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.ADDRESSABLE_MARKET,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.MARKET_ENTRY_MOMENT,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.MARKET_ENTRY_MOMENT,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.INDUSTRY_LIFECYCLE,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.INDUSTRY_LIFECYCLE,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.INDUSTRY_DISRUPTION_POTENTIAL,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.INDUSTRY_DISRUPTION_POTENTIAL,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.TRACTION_VALIDATION,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.TRACTION_VALIDATION,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.MARKET_OPPORTUNITY].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.MARKET_OPPORTUNITY].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.MARKET_OPPORTUNITY].id,
    value: null,
  },
  // business-model
  {
    name: DataRoomSectionAttribute.TIME_TO_PROFITABILITY,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.TIME_TO_PROFITABILITY,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.CURRENTLY_OFFERED_PRODUCT_LINES,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.CURRENTLY_OFFERED_PRODUCT_LINES,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.BUSINESS_MODEL].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.BUSINESS_MODEL].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.BUSINESS_MODEL].id,
    value: null,
  },
  // distribution-and-growth-strategy
  {
    name: DataRoomSectionAttribute.EXPANSION_POTENTIAL,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.EXPANSION_POTENTIAL,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.CUSTOMER_ACQUISITION,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.CUSTOMER_ACQUISITION,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.DISTRIBUTION_AND_GROWTH_STRATEGY].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.DISTRIBUTION_AND_GROWTH_STRATEGY].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.DISTRIBUTION_AND_GROWTH_STRATEGY].id,
    value: null,
  },
  // financials
  {
    name: DataRoomSectionAttribute.FINANCING_NEED,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.FINANCING_NEED,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.REVENUE_SITUATION,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.REVENUE_SITUATION,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: DataRoomSectionAttribute.PROFIT_MARGINS,
    value: {
      dataRoomSectionAttribute: DataRoomSectionAttribute.PROFIT_MARGINS,
      score: 0,
    },
    options: {
      ...rangeSliderInputOptions,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.FINANCIALS].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.FINANCIALS].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.FINANCIALS].id,
    value: null,
  },
  // others
  {
    name: sectionKeys[DataRoomEvaluationSteps.OTHERS].text,
    value: '',
    options: {
      label: 'aquaty-generic.form.evaluation-text',
      placeholder: 'aquaty-generic.form.evaluation-text-placeholder',
      maxLength: 2000,
    },
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.OTHERS].attachments,
    value: [],
    options: {},
  },
  {
    name: sectionKeys[DataRoomEvaluationSteps.OTHERS].id,
    value: null,
  },
] as FormArray<FormData>;

export type FormData = {
  [SectionText.PITCH_DECK]: string;
  [SectionAttachments.PITCH_DECK]: FileField[];
  [SectionIds.PITCH_DECK]: number | null;
  [DataRoomSectionAttribute.LEGAL_ENTITY_STRUCTURE]: DataRoomSectionAttributeEvaluation;
  [SectionText.LEGAL_COMPANY_FOUNDATION]: string;
  [SectionAttachments.LEGAL_COMPANY_FOUNDATION]: FileField[];
  [SectionIds.LEGAL_COMPANY_FOUNDATION]: number | null;
  [SectionText.PREVIOUS_FINANCING]: string;
  [SectionAttachments.PREVIOUS_FINANCING]: FileField[];
  [SectionIds.PREVIOUS_FINANCING]: number | null;
  [DataRoomSectionAttribute.MANAGEMENT_TEAM_HETEROGENEITY]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.MANAGEMENT_TEAM_TRACK_RECORD]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.INVESTOR_SHAREHOLDER_CONCENTRATION]: DataRoomSectionAttributeEvaluation;
  [SectionText.TEAM_AND_SHAREHOLDERS]: string;
  [SectionAttachments.TEAM_AND_SHAREHOLDERS]: FileField[];
  [SectionIds.TEAM_AND_SHAREHOLDERS]: number | null;
  [DataRoomSectionAttribute.PRODUCT_NEED_VALIDATION]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.PRODUCT_MATURITY]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.PRODUCT_SCALABILITY]: DataRoomSectionAttributeEvaluation;
  [SectionText.PRODUCT_AND_DEMO]: string;
  [SectionAttachments.PRODUCT_AND_DEMO]: FileField[];
  [SectionIds.PRODUCT_AND_DEMO]: number | null;
  [DataRoomSectionAttribute.DEFENSIBILITY]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.CONCENTRATION_OF_KEY_KNOW_HOW_IN_THE_TEAM]: DataRoomSectionAttributeEvaluation;
  [SectionText.INTELLECTUAL_PROPERTY_AND_ASSETS]: string;
  [SectionAttachments.INTELLECTUAL_PROPERTY_AND_ASSETS]: FileField[];
  [SectionIds.INTELLECTUAL_PROPERTY_AND_ASSETS]: number | null;
  [DataRoomSectionAttribute.ADDRESSABLE_MARKET]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.MARKET_ENTRY_MOMENT]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.INDUSTRY_LIFECYCLE]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.INDUSTRY_DISRUPTION_POTENTIAL]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.TRACTION_VALIDATION]: DataRoomSectionAttributeEvaluation;
  [SectionText.MARKET_OPPORTUNITY]: string;
  [SectionAttachments.MARKET_OPPORTUNITY]: FileField[];
  [SectionIds.MARKET_OPPORTUNITY]: number | null;
  [DataRoomSectionAttribute.TIME_TO_PROFITABILITY]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.CURRENTLY_OFFERED_PRODUCT_LINES]: DataRoomSectionAttributeEvaluation;
  [SectionText.BUSINESS_MODEL]: string;
  [SectionAttachments.BUSINESS_MODEL]: FileField[];
  [SectionIds.BUSINESS_MODEL]: number | null;
  [DataRoomSectionAttribute.EXPANSION_POTENTIAL]: number;
  [DataRoomSectionAttribute.CUSTOMER_ACQUISITION]: number;
  [SectionText.DISTRIBUTION_AND_GROWTH_STRATEGY]: string;
  [SectionAttachments.DISTRIBUTION_AND_GROWTH_STRATEGY]: FileField[];
  [SectionIds.DISTRIBUTION_AND_GROWTH_STRATEGY]: number | null;
  [DataRoomSectionAttribute.FINANCING_NEED]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.REVENUE_SITUATION]: DataRoomSectionAttributeEvaluation;
  [DataRoomSectionAttribute.PROFIT_MARGINS]: DataRoomSectionAttributeEvaluation;
  [SectionText.FINANCIALS]: string;
  [SectionAttachments.FINANCIALS]: FileField[];
  [SectionIds.FINANCIALS]: number | null;
  [SectionText.OTHERS]: string;
  [SectionAttachments.OTHERS]: FileField[];
  [SectionIds.OTHERS]: number | null;
};
