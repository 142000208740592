import { ServerSharedModal } from 'src/server-shared/modals/modal';
import { FC, useState, useEffect, useCallback, useRef } from 'react';

import useLocalStorage from '../hooks/useLocalStorage';

const sentry_key = 'sentry';

/**
 * CUSTOM JS API IS NOT WORKING FOR LOCALHOST https://github.com/getsentry/sentry/issues/1461
 */

const SentryProvider: FC = ({ children }) => {
  const email = useRef<HTMLInputElement>(null);
  const name = useRef<HTMLInputElement>(null);
  const message = useRef<HTMLTextAreaElement>(null);

  const { storedValue } = useLocalStorage(sentry_key);
  const [modalIsOpen, setIsOpen] = useState(false);

  const closeModalHandler = useCallback(() => {
    setIsOpen(false);
    localStorage.removeItem(sentry_key);
  }, []);

  useEffect(() => {
    if (storedValue) {
      setIsOpen(true);
    }
  }, [storedValue]);

  const sendFeedback = async () => {
    const endpoint = 'https://sentry.io/api/0/projects/aquaty/aquaty-desktop-app/user-feedback/';

    const headers = {
      Authorization: `Bearer ${process.env.SENTRY_AUTH_TOKEN}`,
    };

    const body = JSON.stringify({
      name: name.current?.value,
      email: email.current?.value,
      comments: message.current?.value,
      event_id: storedValue,
    }); // ALL FIELDS ARE REQUIRED

    try {
      await fetch(endpoint, { method: 'POST', headers, body });
    } catch (error) {
      console.error((error as any).response);
    } finally {
      closeModalHandler();
    }
  };

  return (
    <>
      {children}

      <ServerSharedModal
        show={modalIsOpen}
        header={{ title: 'Send your feedback to us' }}
        footer={{ onConfirm: sendFeedback, buttonText: 'aquaty-generic.cta.feedback' }}
        closeEvent={closeModalHandler}
      >
        <form style={{ display: 'grid', rowGap: 40, marginBottom: 40 }}>
          <label htmlFor="name" style={{ display: 'grid', rowGap: 20 }}>
            Your Name:
            <input type="text" id="name" placeholder="Enter your name" ref={name} />
          </label>

          <label htmlFor="sentry-email" style={{ display: 'grid', rowGap: 20 }}>
            Your Email:
            <input type="text" id="sentry-email" placeholder="Enter your email" ref={email} />
          </label>

          <label htmlFor="message" style={{ display: 'grid', rowGap: 20 }}>
            Your Name:
            <textarea id="message" placeholder="Enter your message" ref={message} />
          </label>
        </form>
      </ServerSharedModal>
    </>
  );
};

export default SentryProvider;
