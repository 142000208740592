import { Dispatch, SetStateAction, useCallback, useMemo } from 'react';

import CheckCircleM24Px from 'public/svg-components/CheckCircleM24Px';
import CloseCircle24Px from 'public/svg-components/CloseCircle24Px';
import Rework24Px from 'public/svg-components/Rework24Px';
import { AQString } from 'src/shared/AQString';
import { ServerSharedText } from 'src/server-shared/texts/text';
import classes from './index.module.scss';
import { classNames } from 'src/utils/classNames';
import { EquityOfferingTaskStatusEnum } from 'src/interfaces/offering';

export type FeedbackType = 'approve' | 'disapprove' | 'rework';

export const feedbackStatus = {
  approve: EquityOfferingTaskStatusEnum.COMPLETED,
  disapprove: EquityOfferingTaskStatusEnum.INSUFFICIENT,
  rework: EquityOfferingTaskStatusEnum.TO_REWORK,
};

interface FeedbackCardProps {
  type: FeedbackType;
  selected: boolean;
  setFeedback: Dispatch<SetStateAction<FeedbackType | null>>;
  title?: string;
  disabled?: boolean;
}

const titles: Record<FeedbackType, string> = {
  approve: 'aquaty-app.aq-staff.equity-offerings.release-equity-offering.feedback.approve',
  disapprove: 'aquaty-app.equity-offering.data-room-review.modal-review.disapprove',
  rework: 'aquaty-app.equity-offering.data-room-review.modal-review.to-rework',
};

export const FeedbackCard = ({
  type,
  selected,
  setFeedback,
  title = '',
  disabled = false,
}: FeedbackCardProps) => {
  const selectFeedback = useCallback(() => setFeedback(type), [setFeedback, type]);

  const selectedType = useMemo(() => (selected ? type : undefined), [selected, type]);

  const Icon = useMemo(() => {
    if (selectedType === 'approve') {
      return <CheckCircleM24Px />;
    }
    if (selectedType === 'rework') {
      return <Rework24Px />;
    }
    return <CloseCircle24Px className={classes['close-icon']} />;
  }, [selectedType]);

  return (
    <div
      className={classNames(classes.card, {
        [classes[`card--${selectedType}`]]: !!selectedType,
        [classes['card--disabled']]: disabled,
      })}
      onClick={selectFeedback}
    >
      {selectedType ? Icon : <div className={classes.circle} />}
      <ServerSharedText>
        <AQString componentId={title || titles[type]} />
      </ServerSharedText>
    </div>
  );
};
