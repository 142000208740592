import { User } from 'src/interfaces/user';
import { RoleAccreditationDetails } from 'src/interfaces/roleAccreditation';

export interface IIndustryForAPI {
  id: number;
}

export type Value = string | Option | string[] | IIndustryForAPI[] | boolean;

export type Option = {
  id: number;
  nameStringId: string;
};

export type ValueKeys = keyof User | keyof RoleAccreditationDetails;

export enum EInvestmentAmount {
  'LESS_THAN_20K' = 'LESS_THAN_20K',
  'FROM_20_TO_100K' = 'FROM_20_TO_100K',
  'FROM_100_TO_250K' = 'FROM_100_TO_250K',
  'MORE_THAN_500K' = 'MORE_THAN_500K',
}

export enum PotentialInvestmentAmountRange {
  MIN = 'MIN',
  FROM_20_TO_50 = 'FROM_20_TO_50',
  FROM_50_TO_100 = 'FROM_50_TO_100',
  FROM_100_TO_250 = 'FROM_100_TO_250',
  MORE_THAN_250 = 'MORE_THAN_250',
  DONT_KNOW_YET = 'DONT_KNOW_YET',
}

export enum EInvestmentFrequency {
  'LESS_THAN_TWO_TIMES_PER_YEAR' = 'LESS_THAN_TWO_TIMES_PER_YEAR',
  'FROM_TWO_TO_FOUR_TIMES' = 'FROM_TWO_TO_FOUR_TIMES',
  'FROM_FOUR_TO_EIGHT_TIMES' = 'FROM_FOUR_TO_EIGHT_TIMES',
  'MORE_THAN_EIGHT_TIMES' = 'MORE_THAN_EIGHT_TIMES',
}

export enum EVentureProcessesParticipation {
  'LESS_THAN_TWO' = 'LESS_THAN_TWO',
  'FROM_TWO_TO_FIVE' = 'FROM_TWO_TO_FIVE',
  'FROM_FIVE_TO_FIFTEEN' = 'FROM_FIVE_TO_FIFTEEN',
  'MORA_THAN_FIFTEEN' = 'MORA_THAN_FIFTEEN',
}
