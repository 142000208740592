import mixpanel from 'mixpanel-browser';
import { mixpanelEvents } from 'src/hooks/useMixpanel';
import { ERoleAccreditationStatus, UserIdentificationStatus } from 'src/interfaces/user';

export const oneMin = 1000 * 60;
export const tenMin = 1000 * 60 * 10;
export const fiveSeconds = 1000 * 5;

export const investmentAccountIdentificationSubtitle = {
  [UserIdentificationStatus.NOT_STARTED]:
    'aquaty-app.profile-completion.investment-accounting-identification.not-started.subtitle',
  [UserIdentificationStatus.FAILED]:
    'aquaty-app.profile-completion.investment-accounting-identification.failed.subtitle',
  [UserIdentificationStatus.PENDING]:
    'aquaty-app.profile-completion.investment-accounting-identification.pending.subtitle',
  [UserIdentificationStatus.SUCCESS]:
    'aquaty-app.profile-completion.investment-accounting-identification.success.subtitle',
};

export const investorAccreditationSubtitle = {
  [ERoleAccreditationStatus.NOT_STARTED]:
    'aquaty-app.profile-completion.investor-accreditation.not-started.subtitle',
  [ERoleAccreditationStatus.WAITING]:
    'aquaty-app.profile-completion.investor-accreditation.waiting.subtitle',
  [ERoleAccreditationStatus.ACCEPTED]: '',
  [ERoleAccreditationStatus.REFUSED]: '',
};

export const contactSupportHandler = () => {
  if (['prod', 'staging'].includes(process.env.RUNTIME_ENVIRONMENT || '')) {
    window.HubSpotConversations.widget.open();
    return;
  }
  mixpanel.track(mixpanelEvents.contactSupport);
  const anchor = document.createElement('a');
  anchor.href = 'mailto:support@aquaty.vc?subject="User Issue"';
  anchor.click();
};
