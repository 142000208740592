import { gql, QueryHookOptions, useQuery } from '@apollo/client';

import { UserInvestmentSpecialPurposeVehicle } from 'src/interfaces/venture';
import { beneficialOwnerQueryFields } from '../common.strings';

export const userInvestmentSpecialPurposeVehicleDTOQuery = gql`
  query userInvestmentSpecialPurposeVehicleDTO($userSpvId: Long!, $userId: Long) {
    userInvestmentSpecialPurposeVehicleDTO(userSpvId: $userSpvId, userId: $userId) {
      beneficialOwners {
        ${beneficialOwnerQueryFields}
      }
      id
      taxId
      ventureId
    }
  }
`;

export function useGetUserInvestmentSpecialPurposeVehicle(
  userSpvId: number,
  userId?: number | null,
  params?: QueryHookOptions,
) {
  const { data, loading, refetch } = useQuery<{
    userInvestmentSpecialPurposeVehicleDTO: UserInvestmentSpecialPurposeVehicle;
  }>(userInvestmentSpecialPurposeVehicleDTOQuery, {
    variables: { userSpvId, userId },
    fetchPolicy: 'no-cache',
    skip: !userSpvId,
    ...params,
  });

  return {
    userInvestmentSpecialPurposeVehicle: data?.userInvestmentSpecialPurposeVehicleDTO || null,
    loading,
    refetch,
  };
}
