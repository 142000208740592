import { useMemo } from 'react';

import User16Px from 'public/svg-components/User16Px';
import { components, ControlProps } from 'react-select';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

export const ControlComponentHeaderStepper = ({
  children,
  ...props
}: ControlProps<StepIndicatorObject>) => {
  const currentStepIndex = useMemo(() => {
    return props.options.findIndex((el) => {
      if ('active' in el) {
        return el.active;
      }
      return false;
    });
  }, [props.options]);

  return (
    <components.Control {...props}>
      <div className={classes['control-step-progress']}>
        <ServerSharedText type="label-s" color="body">
          {currentStepIndex + 1}/{props.options.length}
        </ServerSharedText>
      </div>
      {children}
    </components.Control>
  );
};

export const MultiControlComponent = ({ children, ...props }: ControlProps) => {
  const { selectProps }: Record<string, any> = props;
  const limit: number = selectProps['data-limit'];
  return (
    <components.Control {...props}>
      {children}
      {limit && (
        <ServerSharedText
          type="label-xs"
          style={{ position: 'absolute', bottom: 8, right: 44 }}
          color="grey500"
        >{`${selectProps.value.length}/${limit}`}</ServerSharedText>
      )}
    </components.Control>
  );
};

export const WithIconControlComponent = (props: ControlProps) => {
  return (
    <components.Control {...props}>
      <div className={classNames('flex', classes['control-icon'])}>
        <User16Px />
      </div>

      {props.children}
    </components.Control>
  );
};

export const DefaultControlComponent = (props: ControlProps) => {
  return <components.Control {...props}>{props.children}</components.Control>;
};
