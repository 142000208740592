import { forwardRef, ForwardRefRenderFunction, useImperativeHandle, useMemo, useRef } from 'react';

import { PageStepIndicatorProps, HeaderStepIndicatorRefObject } from './types';
import { StepListItem } from './list-item';
import { HeaderStepsDropdown } from 'src/server-shared/inputs/select/header-steps';
import { usePageStepIndicatorBasics } from './hooks/usePageStepIndicatorBasics';
export { usePageStepIndicatorBasics };

export const HeaderStepIndicator: ForwardRefRenderFunction<
  HeaderStepIndicatorRefObject,
  PageStepIndicatorProps
> = (props, ref) => {
  const stepsListRef = useRef<HTMLDivElement>(null);
  const { steps, type, withOnClick = true, pageName, onChange, button = null } = props;

  useImperativeHandle(ref, () => ({
    stepsListRef,
  }));

  const value = useMemo(() => steps.find((el) => el.active), [steps]);

  if (type === 'dropdown') {
    return (
      <HeaderStepsDropdown
        options={steps.map((el) => ({ ...el, isDisabled: !el.available }))}
        onChange={onChange}
        value={value}
        isResourceString
        pageName={pageName}
        button={button}
      />
    );
  }

  return (
    <div className="flex none" ref={stepsListRef}>
      {steps.map((el, i) => {
        return (
          <StepListItem
            step={el}
            onClick={withOnClick ? onChange : undefined}
            key={el.value}
            index={i}
          />
        );
      })}
    </div>
  );
};

export const ServerSharedHeaderStepIndicator = forwardRef(HeaderStepIndicator);
