import { SVGProps, memo } from 'react';

const SvgCloseCircle24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M2.625 12a9.375 9.375 0 1 0 18.75 0 9.375 9.375 0 0 0-18.75 0v0Z"
      fill="#1F2937"
      stroke="#1F2937"
      strokeWidth={1.25}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m8.25 15.75 7.5-7.5M15.75 15.75l-7.5-7.5"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgCloseCircle24Px);
export default Memo;
