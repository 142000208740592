import { SVGProps, memo } from 'react';

const SvgLogout24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M18.875 17.625v1.25a1.25 1.25 0 0 1-1.25 1.25h-3.75M13.875 5.125h3.75a1.25 1.25 0 0 1 1.25 1.25v1.25M10.658 21.367l-7.5-1.034a.625.625 0 0 1-.533-.625V5.1a.625.625 0 0 1 .492-.6l7.5-1.85a.625.625 0 0 1 .758.608v17.5a.618.618 0 0 1-.717.609ZM18.875 10.125l2.5 2.5H14.5M18.875 15.125l2.5-2.5"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M8.146 12.287a.312.312 0 1 1 0-.625M8.146 12.287a.312.312 0 1 0 0-.625"
      stroke="#1F2937"
      strokeWidth={1.5}
    />
  </svg>
);

const Memo = memo(SvgLogout24Px);
export default Memo;
