import { ApolloClient, InMemoryCache, createHttpLink, from, Observable } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { Auth } from 'aws-amplify';
import { getCookie } from 'cookies-next';

import { COGNITO_JWT } from './constants/loggedIn';
import { NOT_AUTHORIZED_PAGES, DEMO_SIGN_IN_PAGE, SIGN_IN_PAGE } from './constants/paths';
import { logOutFunction } from './hooks/useLogout';
import store from './store';
import { setErrorHandler } from './store/app/reducer';
import { findLocalItems } from './utils/localStorageActions';
import { isDemo } from './_middleware';
import { jwtDecode } from 'jwt-decode';
import { EMAIL } from './constants/registration';

const checkCookies = function (email: string) {
  if (!email) {
    return;
  }
  const sessionEmail = sessionStorage.getItem(EMAIL);
  if (!sessionEmail || sessionEmail !== email) {
    sessionStorage.setItem(EMAIL, email);
    window.location.reload();
  }
};

// eslint-disable-next-line quotes
const cannotReadProperties = "Cannot read properties of null (reading 'email')";
const ACCESS_DENIED = 'Access Denied';

enum Errors {
  'NotAuthorizedActionException' = 'NotAuthorizedActionException',
  'LockedStateException' = 'LockedStateException',
}

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {
  const isLoggedIn = getCookie(COGNITO_JWT);
  if (graphQLErrors) {
    console.log('graphQLErrors', graphQLErrors);
    // can handle graphQLErrors
    graphQLErrors.forEach(({ message, locations, path }) => {
      console.warn(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`);
      if (
        !isLoggedIn &&
        window.location.pathname.includes('/p') &&
        window.location.pathname.includes('/deal-room') &&
        path?.includes('user')
      ) {
        return; // for public deal-room logout
      }
      if (!isLoggedIn && !NOT_AUTHORIZED_PAGES.includes(window.location.pathname)) {
        logOutFunction();
        window.location.replace(
          isDemo ? DEMO_SIGN_IN_PAGE : SIGN_IN_PAGE + `?redirectPage=${window.location.pathname}`,
        );
      }
    });

    if (graphQLErrors.find((el) => el.message.includes(Errors.NotAuthorizedActionException))) {
      store.dispatch(setErrorHandler(403));
    } else if (graphQLErrors.find((el) => el.message.includes(Errors.LockedStateException))) {
      store.dispatch(setErrorHandler(423));
    } else if (networkError && graphQLErrors.length) {
      console.warn(`ApolloClient Error, graphQLErrors: ${JSON.stringify(graphQLErrors)}`);
    }
    if (
      graphQLErrors.find(
        (el) =>
          el.message.includes(ACCESS_DENIED) &&
          !NOT_AUTHORIZED_PAGES.includes(window.location.pathname),
      )
    ) {
      logOutFunction();
      window.location.replace(
        isDemo ? DEMO_SIGN_IN_PAGE : SIGN_IN_PAGE + `?redirectPage=${window.location.pathname}`,
      );
    }
  }

  if (
    (networkError && (networkError as any)?.statusCode === 401) ||
    (isLoggedIn && networkError?.message === cannotReadProperties)
  ) {
    return new Observable((observer) => {
      Auth.currentSession()
        .then((session) => {
          const token = session.getIdToken().getJwtToken();
          const oldHeaders = operation.getContext().headers;
          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: `Bearer ${token}`,
            },
          });
        })
        .then(() => {
          const subscriber = {
            next: observer.next.bind(observer),
            error: observer.error.bind(observer),
            complete: observer.complete.bind(observer),
          };

          // Retry last failed request
          forward(operation).subscribe(subscriber);
        })
        .catch((error) => {
          logOutFunction();
          window.location.replace(
            isDemo ? DEMO_SIGN_IN_PAGE : SIGN_IN_PAGE + `?redirectPage=${window.location.pathname}`,
          );
          // No refresh or client token available, we force user to login
          observer.error(error);
        });
    });
  } else if (networkError) {
    console.warn(`ApolloClient Error, networkError: ${networkError?.message}}`);
  }
});

const httpLink = createHttpLink({
  uri: `${process.env.API_BASE}/graphql`,
});

const authLink = setContext((_, { headers }) => {
  const requiredHeaders = {
    'Accept-Language': 'en',
  };

  // we should to have a possibility to overwrite authorization from any query or mutation
  if (headers && Object.prototype.hasOwnProperty.call(headers, 'authorization')) {
    return {
      headers: {
        ...requiredHeaders,
        ...headers,
      },
    };
  }

  // get the authentication token from local storage if it exists
  const token = findLocalItems();
  const decoded = token && jwtDecode<any>(token);
  checkCookies(decoded?.email);

  const authorizationHeader = token ? { authorization: token ? `Bearer ${token}` : '' } : {};

  return {
    headers: {
      ...headers,
      ...requiredHeaders,
      ...authorizationHeader,
    },
  };
});

const client = new ApolloClient({
  cache: new InMemoryCache({
    addTypename: false,
  }),
  link: from([errorLink, authLink.concat(httpLink)]),
  connectToDevTools: true,
});

export default client;
