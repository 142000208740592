import { gql, useQuery, useLazyQuery, QueryHookOptions } from '@apollo/client';
import { EquityOffering, EquityOfferingStatus } from 'src/interfaces/offering';
import { equityOfferingGQLString, fileFields } from '../common.strings';

export const equityOfferings = gql`
  query equityOfferings($equityOfferingIds: [Long!], $statuses: [EquityOfferingStatus], $ventureId: Long) {
    equityOfferings(equityOfferingIds: $equityOfferingIds, statuses: $statuses, ventureId: $ventureId) {
      ${equityOfferingGQLString}    
      venture {
        id
        name
        logo {
          ${fileFields}
        }
        headerImage {
          ${fileFields}
        }
      }
    }
  }
`;

interface GetEquityOfferingsParams {
  equityOfferingIds?: number[];
  statuses?: EquityOfferingStatus[];
  ventureId?: number;
}

export function useGetEquityOfferingsList(
  variables: GetEquityOfferingsParams,
  params?: QueryHookOptions<{ equityOfferings: EquityOffering[] }, GetEquityOfferingsParams>,
) {
  const { data, loading, refetch } = useQuery<
    { equityOfferings: EquityOffering[] },
    GetEquityOfferingsParams
  >(equityOfferings, {
    variables,
    ...params,
  });

  return {
    equityOfferings: data?.equityOfferings || [],
    loading,
    refetch,
  };
}

export function useGetEquityOfferingsListLazy(
  params?: QueryHookOptions<{ equityOfferings: EquityOffering[] }, GetEquityOfferingsParams>,
) {
  const [getEquityOfferings, { data, loading }] = useLazyQuery<
    { equityOfferings: EquityOffering[] },
    GetEquityOfferingsParams
  >(equityOfferings, params);

  return {
    equityOfferings: data?.equityOfferings || [],
    loading,
    getEquityOfferings,
  };
}
