import { SVGProps, memo } from 'react';

const SvgPortfolioSelected = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M13.25 3.25v7.5h7.5a7.5 7.5 0 0 0-7.5-7.5Z"
      fill="#1F2937"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.75 5.75a7.5 7.5 0 1 0 7.5 7.5h-7.5v-7.5Z"
      fill="#1F2937"
      stroke="#1F2937"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const Memo = memo(SvgPortfolioSelected);
export default Memo;
