import { useCallback, useMemo } from 'react';

import { ServerSharedText } from 'src/server-shared/texts/text';
import { AQString } from 'src/shared/AQString';
import { palette, PaletteKeys } from 'src/styles/palette';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

export type CheckBoxSetValue = (string | number)[];

type Option = {
  value: number | string;
  label: string | JSX.Element;
  descriptionStringId?: string;
  standard?: boolean;
};

interface ICheckBoxSetProps {
  options: Option[];
  value: CheckBoxSetValue;
  onChange: (value: CheckBoxSetValue) => void;
  isrResourceString?: boolean;
  label?: string;
  background?: PaletteKeys;
}

interface CheckBoxProps {
  label: string | JSX.Element;
  value: number | string;
  isChecked: boolean;
  onCheck: (value: CheckBoxSetValue) => void;
  isrResourceString?: boolean;
  values: CheckBoxSetValue;
  disabled?: boolean;
  background?: PaletteKeys;
  className?: string;
}

interface CheckedItemProps
  extends Pick<CheckBoxProps, 'disabled' | 'value' | 'isChecked' | 'background'> {
  onChange: () => void;
}

export const ServerSharedCheckedItem = ({
  value,
  isChecked,
  onChange,
  disabled = false,
  background = 'primary600',
}: CheckedItemProps) => {
  const showSVG = useMemo(() => {
    if (disabled) {
      return disabled && isChecked;
    }
    return !disabled;
  }, [disabled, isChecked]);

  const style = useMemo(() => {
    if (isChecked && disabled) {
      return { backgroundColor: palette.grey500 };
    }
    return isChecked ? { backgroundColor: palette[background] } : {};
  }, [background, disabled, isChecked]);

  return (
    <div className={classNames(classes['checkbox__container'], { [classes.disabled]: disabled })}>
      <input
        className={classes['checkbox__hide']}
        type="checkbox"
        value={value}
        checked={isChecked}
        onChange={onChange}
        disabled={disabled}
      />
      <div
        style={style}
        className={classNames(classes['checkbox__styled-checkbox'], {
          [classes['checkbox__styled-checkbox-disabled']]: disabled,
          [classes['checked']]: isChecked,
        })}
      >
        {showSVG && (
          <svg className={classes['checkbox__icon']} viewBox="0 0 24 24">
            <polyline points="20 6 9 17 4 12" />
          </svg>
        )}
      </div>
    </div>
  );
};

export const ServerSharedCheckBox = ({
  label,
  value,
  isChecked,
  onCheck,
  values,
  isrResourceString = false,
  disabled = false,
  background = 'primary600',
  className = '',
}: CheckBoxProps) => {
  const onChange = useCallback(() => {
    if (isChecked) {
      onCheck(values.filter((el: string | number) => el !== value));
      return;
    }
    onCheck([...values, value]);
  }, [isChecked, values, onCheck, value]);

  return (
    <label className={classNames('grid-column template-mc-1fr s', className)}>
      <ServerSharedCheckedItem
        onChange={onChange}
        disabled={disabled}
        value={value}
        isChecked={isChecked}
        background={background}
      />
      <ServerSharedText clickable={!disabled}>
        {isrResourceString ? (
          <AQString componentId={typeof label === 'string' ? label : ''} />
        ) : (
          label
        )}
      </ServerSharedText>
    </label>
  );
};

export const ServerSharedSingleCheckBox = ({
  label,
  value,
  onChange,
  disabled = false,
  isrResourceString = false,
  background = 'primary600',
  className = '',
}: {
  label: string | JSX.Element;
  value: boolean;
  onChange: (value: boolean) => void;
  disabled?: boolean;
  isrResourceString?: boolean;
  background?: PaletteKeys;
  className?: string;
}) => {
  return (
    <label className={classNames('grid-column template-mc-1fr s', className)}>
      <div className={classes['checkbox__container']}>
        <input
          className={classes['checkbox__hide']}
          type="checkbox"
          checked={value}
          onChange={(e) => onChange(e.target.checked)}
          disabled={disabled}
        />
        <div
          style={{ backgroundColor: value ? palette[background] : '' }}
          className={classNames(classes['checkbox__styled-checkbox'], {
            [classes['checkbox__styled-checkbox-disabled']]: disabled,
            [classes['checked']]: value,
          })}
        >
          {!disabled && (
            <svg className={classes['checkbox__icon']} viewBox="0 0 24 24">
              <polyline points="20 6 9 17 4 12" />
            </svg>
          )}
        </div>
      </div>
      <ServerSharedText clickable>
        {isrResourceString ? (
          <AQString componentId={typeof label === 'string' ? label : ''} />
        ) : (
          label
        )}
      </ServerSharedText>
    </label>
  );
};

export const ServerSharedCheckBoxSet = ({
  options,
  value,
  onChange,
  isrResourceString,
  label,
  background = 'primary600',
  ...others
}: ICheckBoxSetProps) => {
  return (
    <>
      {label && (
        <div className={classes['checkbox__label']}>
          <ServerSharedText type="label" as="label">
            {isrResourceString ? <AQString componentId={label} /> : label}
          </ServerSharedText>
        </div>
      )}
      {options.map(({ value: optionValue, label, standard }) => (
        <div className={classes['checkbox__holder']} key={optionValue} {...others}>
          <ServerSharedCheckBox
            isrResourceString={isrResourceString}
            value={optionValue}
            label={label}
            values={value}
            isChecked={value.includes(optionValue)}
            onCheck={onChange}
            background={background}
          />
          {standard && <div className={classes['checkbox__standard']}>Standard</div>}
        </div>
      ))}
    </>
  );
};
