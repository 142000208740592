import { SVGProps, memo } from 'react';

const SvgTaxes24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#clip0_36821_322399)">
      <path
        d="M12 20.6719C16.7893 20.6719 20.6719 16.7893 20.6719 12C20.6719 7.21066 16.7893 3.32812 12 3.32812C7.21066 3.32812 3.32812 7.21066 3.32812 12C3.32812 16.7893 7.21066 20.6719 12 20.6719Z"
        stroke="#0D1117"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.19385 14.806L14.9165 9.08252"
        stroke="#0D1117"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M9.39844 9.57568C9.23879 9.57568 9.10938 9.44627 9.10938 9.28662C9.10938 9.12697 9.23879 8.99756 9.39844 8.99756"
        stroke="#0D1117"
        strokeWidth="1.5"
      />
      <path
        d="M9.39844 9.57568C9.55808 9.57568 9.6875 9.44627 9.6875 9.28662C9.6875 9.12697 9.55808 8.99756 9.39844 8.99756"
        stroke="#0D1117"
        strokeWidth="1.5"
      />
      <path
        d="M14.7124 14.8906C14.5528 14.8906 14.4233 14.7612 14.4233 14.6016C14.4233 14.4419 14.5528 14.3125 14.7124 14.3125"
        stroke="#0D1117"
        strokeWidth="1.5"
      />
      <path
        d="M14.7124 14.8906C14.872 14.8906 15.0015 14.7612 15.0015 14.6016C15.0015 14.4419 14.872 14.3125 14.7124 14.3125"
        stroke="#0D1117"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_36821_322399">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgTaxes24Px);
export default Memo;
