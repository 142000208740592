import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { fileFields } from '../common.strings';
import { venturePitchMarketGQLString } from '../venture-pitch/setup/market';
import { DataRoomMarketAndDistributionStrategy } from 'src/interfaces/data-room';

export const marketAndDistributionStrategyGQLString = `
addressableMarket {
  ${venturePitchMarketGQLString}
}
distributionChanelAttachments {
  ${fileFields}  
}
distributionChanelDescription
advantagesAttachments {
  ${fileFields}  
}
advantagesDescription
obstaclesAttachments {
  ${fileFields}  
}
obstaclesDescription
marketValidationAttachments {
  ${fileFields}  
}
marketValidationDescription
`;

const dataRoomMarketDistributionStrategyMutation = gql`
  mutation saveDataRoomMarketAndDistributionStrategy(
    $equityOfferingId: Long!
    $distributionStrategy: DataRoomMarketAndDistributionStrategyInput
  ) {
    saveDataRoomMarketAndDistributionStrategy(
      equityOfferingId: $equityOfferingId
      distributionStrategy: $distributionStrategy
    ) {
      ${marketAndDistributionStrategyGQLString}
    }
  }
`;

interface UseSaveDataRoomMarketDistributionStrategyMutation {
  equityOfferingId: number;
  distributionStrategy: DataRoomMarketAndDistributionStrategy;
}

export const useSaveDataRoomMarketDistributionStrategy = () => {
  const [mutateDataRoomMarketDistributionStrategy, { loading, data }] = useMutation(
    dataRoomMarketDistributionStrategyMutation,
  );

  const saveDataRoomMarketDistributionStrategy = useCallback(
    async (variables: UseSaveDataRoomMarketDistributionStrategyMutation) => {
      await mutateDataRoomMarketDistributionStrategy({ variables });
    },
    [mutateDataRoomMarketDistributionStrategy],
  );
  return {
    saveDataRoomMarketDistributionStrategy,
    loading,
    savedDataRoomMarketDistributionStrategy: data?.saveDataRoomMarketAndDistributionStrategy,
  };
};
