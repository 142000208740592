export enum DataRoomReviewSteps {
  'PITCH_DECK' = 'PITCH_DECK',
  'LEGAL_COMPANY_FOUNDATION' = 'LEGAL_COMPANY_FOUNDATION',
  'TEAM_AND_SHAREHOLDERS' = 'TEAM_AND_SHAREHOLDERS',
  'PREVIOUS_FINANCING' = 'PREVIOUS_FINANCING',
  'PRODUCT_AND_DEMO' = 'PRODUCT_AND_DEMO',
  'INTELLECTUAL_PROPERTY_AND_ASSETS' = 'INTELLECTUAL_PROPERTY_AND_ASSETS',
  'BUSINESS_MODEL' = 'BUSINESS_MODEL',
  'MARKET_OPPORTUNITY' = 'MARKET_OPPORTUNITY',
  'DISTRIBUTION_AND_GROWTH_STRATEGY' = 'DISTRIBUTION_AND_GROWTH_STRATEGY',
  'FINANCIALS' = 'FINANCIALS',
  'OTHERS' = 'OTHERS',
}

export const dataRoomReviewStepOptions = {
  active: false,
  available: true,
  complete: false,
};
