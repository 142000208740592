import { useState, useCallback, useMemo } from 'react';
import dynamic from 'next/dynamic';

import Search from 'public/svg-components/Search';
import { AQString } from 'src/shared/AQString';
import { countries } from 'src/constants/registration';
import { ServerSharedButton } from 'src/server-shared/buttons/button';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { AddVenturesModalProps } from './AddVenturesModal';
import { SuggestVenture, Venture } from 'src/interfaces/venture';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

const AddVenturesModal = dynamic<AddVenturesModalProps>(
  () => import('./AddVenturesModal').then((m) => m.AddVenturesModal),
  { ssr: false },
);

export interface SelectVentureProps {
  suggestedVentures: SuggestVenture[];
  selectedId?: string | number;
  onChecked: (selectedVenture: SuggestVenture) => void;
  userVentures?: Venture[];
}

export const SelectVenture = ({
  selectedId,
  onChecked,
  suggestedVentures,
  userVentures,
}: SelectVentureProps) => {
  const [showAddVentureModal, setShowAddVentureModal] = useState(false);
  const openAddVentureModal = useCallback(() => setShowAddVentureModal(true), []);
  const closeAddVentureModal = useCallback(() => setShowAddVentureModal(false), []);

  const [ventures, setVentures] = useState<Venture[]>([]);

  const addVenture = useCallback(
    (venture: Venture) => {
      setVentures((p) => {
        const item = p.find((e) => e.refId === venture.refId);
        if (item) return p;
        return p.concat(venture);
      });
      onChecked(venture);
    },
    [onChecked],
  );

  const v = useMemo(() => {
    return suggestedVentures.concat(ventures);
  }, [suggestedVentures, ventures]);

  return (
    <>
      <div className="grid-row xs">
        {v.map((el) => (
          <div
            className={classNames(classes.card, {
              [classes['card--active']]: el.refId === selectedId,
            })}
            key={el.id || el.refId || el.name}
            onClick={() => onChecked(el)}
          >
            <div
              className={classNames(classes.indicator, {
                [classes['indicator--active']]: el.refId === selectedId,
              })}
            />
            <ServerSharedText type="label">{el.name}</ServerSharedText>

            {Array.isArray(el.locations) && el.locations.length !== 0 && (
              <>
                <ServerSharedText color="grey500">{el.locations[0].street}</ServerSharedText>
                <ServerSharedText color="grey500">
                  {`${el.locations[0].postalCode} ${el.locations[0].city}, ${
                    (countries as any)[el.locations[0].country || 'DE']
                  }`}
                </ServerSharedText>
              </>
            )}
          </div>
        ))}
        <ServerSharedButton
          variant="dashed"
          className={classNames('flex xs', classes['search-icon'])}
          label={
            <>
              <Search />
              <AQString componentId="aquaty-generic.cta.select-other-venture" />
            </>
          }
          onClick={openAddVentureModal}
        />
      </div>

      <AddVenturesModal
        addVenture={addVenture}
        show={showAddVentureModal}
        closeEvent={closeAddVentureModal}
        userVentures={userVentures}
      />
    </>
  );
};
