import { SVGProps, memo } from 'react';

const SvgClubs24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width={24}
    height={24}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g clipPath="url(#clip0_4856_126818)">
      <path
        d="M16.9993 14.8359H17.0002C18.1273 14.8359 19.2084 15.2837 20.0054 16.0807C20.7235 16.7988 21.1581 17.7474 21.2371 18.7526H16.494C16.4444 17.4801 16.0976 16.2369 15.4816 15.1228C15.964 14.9331 16.4788 14.8353 16.9993 14.8359ZM14.9791 14.9376L14.9514 14.9314L15.0337 14.9498L14.9975 14.9417L14.9791 14.9376Z"
        stroke="#1F2937"
        strokeWidth="1.5"
      />
      <path
        d="M19.3252 10.5391C19.3252 11.8507 18.2619 12.9141 16.9502 12.9141C15.6385 12.9141 14.5752 11.8507 14.5752 10.5391C14.5752 9.22739 15.6385 8.16406 16.9502 8.16406C18.2619 8.16406 19.3252 9.22739 19.3252 10.5391Z"
        stroke="#1F2937"
        strokeWidth="1.5"
      />
      <path
        d="M11.4582 8.45833C11.4582 10.2302 10.0218 11.6667 8.24984 11.6667C6.47792 11.6667 5.0415 10.2302 5.0415 8.45833C5.0415 6.68642 6.47792 5.25 8.24984 5.25C10.0218 5.25 11.4582 6.68642 11.4582 8.45833Z"
        stroke="#1F2937"
        strokeWidth="1.5"
      />
      <path
        d="M12.1391 15.1969C13.0917 16.1495 13.6588 17.4155 13.7399 18.7526H2.7601C2.84123 17.4155 3.40831 16.1495 4.36091 15.1969C5.39236 14.1654 6.79131 13.5859 8.25 13.5859C9.70869 13.5859 11.1076 14.1654 12.1391 15.1969Z"
        stroke="#1F2937"
        strokeWidth="1.5"
      />
    </g>
    <defs>
      <clipPath id="clip0_4856_126818">
        <rect width="20" height="20" fill="white" transform="translate(2 2)" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgClubs24Px);
export default Memo;
