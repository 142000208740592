import { SVGProps, memo } from 'react';

const SvgClose24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.03 3.969a.75.75 0 0 1 0 1.06l-15 15a.75.75 0 1 1-1.06-1.06l15-15a.75.75 0 0 1 1.06 0Z"
      fill="#1F2937"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.97 3.969a.75.75 0 0 1 1.06 0l15 15a.75.75 0 0 1-1.06 1.06l-15-15a.75.75 0 0 1 0-1.06Z"
      fill="#1F2937"
    />
  </svg>
);

const Memo = memo(SvgClose24Px);
export default Memo;
