import { DropdownIndicatorProps, MenuListProps } from 'react-select';
import { useCallback, useEffect, useMemo, useState } from 'react';

import { ServerSharedSelect } from 'src/server-shared/inputs/select';
import { ServerSharedSelectProps } from 'src/server-shared/inputs/select/types';
import { IndicatorSeparator } from 'src/server-shared/inputs/select/components/indicator-separator';
import { palette } from 'src/styles/palette';
import { spacing } from 'src/styles/spacing';
import { inter } from 'src/styles/fonts';
import { borderRadius } from 'src/styles/border-radius';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { ControlComponentHeaderStepper } from '../components/control';
import { MenuListComponentHeaderStepper } from '../components/menu-list';
import { OptionComponentHeaderStepper } from '../components/option';
import { DropdownIndicator16px } from '../components/dropdown-indicator';
import { HeaderStepperMenu } from '../components/menu';
import classes from './index.module.scss';
import { HEIGHT_FOR_SHOW_STEPPER } from 'src/server-shared/header/application-header';

interface HeaderStepsDropdownProps
  extends Omit<
    ServerSharedSelectProps,
    'onChange' | 'options' | 'formatOptionLabel' | 'onInputChange'
  > {
  onChange: (value: any, step?: any) => void;
  options: StepIndicatorObject[];
  pageName: string;
  button: React.ReactNode | null;
}

const customStyle = (height: number) => ({
  control: (provided: any) => {
    return {
      ...provided,
      minWidth: 100,
      padding: `0 ${spacing.xs}`,
      margin: 0,
      marginLeft: 0,
      backgroundColor: palette.body,
      outline: 'none',
      border: 0,
      boxShadow: 'none',
      borderRadius: borderRadius.s,
      height: 32,
      minHeight: 32,
      cursor: 'pointer',

      '& span': {
        fontFamily: inter.style.fontFamily,
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: '14px',
        lineHeight: '20px',
        color: palette.grey900,
      },
    };
  },
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: palette.grey900,
    padding: 8,
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 8,
    paddingTop: 8,
    maxHeight: `calc(100vh - 32px - ${height}px)`,
  }),
  option: () => ({
    backgroundColor: palette.grey900,
    color: palette.body,
    cursor: 'pointer',
    borderRadius: 6,
  }),
});

export const HeaderStepsDropdown = ({ pageName, button, ...props }: HeaderStepsDropdownProps) => {
  const [height, setHeight] = useState(48);

  const headerHeight = useMemo(() => {
    return document.getElementsByTagName('header')[0].offsetHeight;
  }, []);

  const scrollHandler = useCallback(() => {
    if (window.scrollY >= HEIGHT_FOR_SHOW_STEPPER) {
      setHeight(headerHeight === 72 ? 72 : 48);
      return;
    }

    setHeight(headerHeight === 72 ? 72 : 96);
  }, [headerHeight]);

  useEffect(() => {
    window.addEventListener('scroll', scrollHandler, true);
    return () => {
      window.removeEventListener('scroll', scrollHandler, true);
    };
  }, [scrollHandler]);

  return (
    <ServerSharedSelect
      components={{
        IndicatorSeparator,
        DropdownIndicator: (props: DropdownIndicatorProps) => (
          <DropdownIndicator16px {...props} isBlackChevron />
        ),
        Control: ControlComponentHeaderStepper,
        MenuList: (props: MenuListProps<StepIndicatorObject>) => (
          <MenuListComponentHeaderStepper {...props} pageName={pageName} button={button} />
        ),
        Option: OptionComponentHeaderStepper,
        Menu: HeaderStepperMenu,
      }}
      styles={customStyle(height)}
      isSearchable={false}
      className={classes['no-width']}
      {...props}
    />
  );
};
