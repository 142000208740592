import { gql, QueryHookOptions, useQuery } from '@apollo/client';
import { InboundMessage } from 'src/interfaces/common';
import { fileFields } from '../common.strings';

export const inboundMessagesGQLString = `
attachments {
  ${fileFields}
}
body
createdAt
id
messageStatus
recipientId
sentFrom
subject`;

export const userInboundMessagesQuery = gql`
  query ($statuses: [InboundMessageStatus]) {
    userInboundMessages(statuses: $statuses) {
     ${inboundMessagesGQLString}
    }
  }
`;

export const useGetUserInboundMessages = (params?: QueryHookOptions) => {
  const { data, loading, error, refetch } = useQuery<{ userInboundMessages: InboundMessage[] }>(
    userInboundMessagesQuery,
    params,
  );

  return {
    userInboundMessages: data?.userInboundMessages || [],
    loading,
    error,
    refetch,
  };
};
