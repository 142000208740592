import AlertCircle48Px from 'public/svg-components/AlertCircle48Px';
import CheckCircle48Px from 'public/svg-components/CheckCircle48Px';
import InformationCircleFill24Px from 'public/svg-components/InformationCircleFill24Px';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { ServerSharedModal, SharedModalProps } from 'src/server-shared/modals/modal';
import { classNames } from 'src/utils/classNames';
import classes from './index.module.scss';

interface FeedbackModalProps extends SharedModalProps {
  type?: 'alert' | 'info' | 'success';
  title: string | JSX.Element;
  subtitle?: string | JSX.Element;
}

export const ServerSharedFeedbackModal = ({
  type = 'info',
  title,
  subtitle,
  footer,
  header,
  ...rest
}: FeedbackModalProps) => {
  const headerProps =
    typeof header === 'boolean' ? { withBorder: false } : { withBorder: false, ...header };

  const footerProps = {
    withBorder: false,
    footerSize: 'large' as const,
    ...(typeof footer === 'boolean' ? {} : footer),
  };

  return (
    <ServerSharedModal
      header={headerProps}
      footer={footerProps}
      bodyInlineStyles={{ overflowY: 'initial' }}
      closeByClickOutside={false}
      {...rest}
    >
      <div
        className={classNames(classes['icon-container'], 'flex', {
          [classes[`icon-container--${type}`]]: true,
        })}
      >
        {type === 'alert' && <AlertCircle48Px />}
        {type === 'success' && <CheckCircle48Px />}
        {type === 'info' && (
          <InformationCircleFill24Px width={48} height={48} viewBox="0 0 24 24" />
        )}
      </div>
      <div className={classNames('grid-row', 'xs', classes['text-container'])}>
        <ServerSharedText type="label-l">{title}</ServerSharedText>
        <ServerSharedText color="grey500">{subtitle}</ServerSharedText>
      </div>
    </ServerSharedModal>
  );
};
