import { VenturePitch } from 'src/interfaces/venture-pitch';

export const ACCESS_TOKEN = 'idToken';
export const _CREDENTIALS = 'Y3JlZGVudGlhbHM='; // 'credentials' (base64)

const VENTURE_PITCH_SETUP_DATA = 'venture_pitch_setup_data';

export const findLocalItems = (query: string = ACCESS_TOKEN): string | null => {
  let result: string | null = null;

  if (!process.browser) return result;

  for (const i in localStorage) {
    if (Object.prototype.hasOwnProperty.call(localStorage, i)) {
      if (i.match(query) || (!query && typeof i === 'string')) {
        result = localStorage.getItem(i);
      }
    }
  }
  return result;
};

export const clearVentureSetupDataFromLocalStorage = () =>
  localStorage.removeItem(VENTURE_PITCH_SETUP_DATA);

export const saveVentureSetupDataToLocalStorage = (data: Partial<VenturePitch>) =>
  localStorage.setItem(VENTURE_PITCH_SETUP_DATA, JSON.stringify(data));

export const getVentureSetupDataFromLocalStorage = (): Partial<VenturePitch> => {
  const data = localStorage.getItem(VENTURE_PITCH_SETUP_DATA);
  if (data) return JSON.parse(data);
  return {};
};

export const setCredentials = (string: string) =>
  localStorage.setItem(_CREDENTIALS, window.btoa(string));

export const removeCredentials = () => localStorage.removeItem(_CREDENTIALS);

export const getCredentials = () => {
  try {
    const hashed = localStorage.getItem(_CREDENTIALS);
    if (!hashed) return [];

    const unHashed = process.browser ? window.atob(hashed) : '';
    return unHashed.split(':');
  } catch (error) {
    return [];
  }
};
