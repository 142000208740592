import { ReactElement, Component } from 'react';
import { ErrorPage } from '..';
interface ErrorBoundaryState {
  hasError: boolean;
}

interface ErrorBoundaryProps {
  children: ReactElement;
}

export class ErrorBoundaryResettable extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  onReset = () =>
    this.setState((state) => ({
      hasError: !state.hasError,
    }));

  render() {
    if (this.state.hasError) {
      return <ErrorPage onReset={this.onReset} {...this.props} />;
    } else {
      return this.props.children;
    }
  }
}
