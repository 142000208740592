import { SVGProps, memo } from 'react';

const SvgAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M12.01 15.32a.75.75 0 1 0 0 1.5.75.75 0 0 0 0-1.5Z" fill="#F23535" />
    <path d="M12 13.25V6.375" stroke="#F23535" strokeWidth={1.5} strokeLinecap="round" />
    <path d="M2.625 12a9.375 9.375 0 1 0 18.75 0 9.375 9.375 0 0 0-18.75 0Z" stroke="#F23535" strokeWidth={1.5} />
  </svg>
);

const Memo = memo(SvgAlert);
export default Memo;
