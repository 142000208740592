import { MenuListProps } from 'react-select';
import { useRouter } from 'next/router';
import { useCallback, useState, useMemo } from 'react';
import { useDispatch } from 'react-redux';

import { palette } from 'src/styles/palette';
import { ServerSharedSelect } from 'src/server-shared/inputs/select';
import { IndicatorSeparator } from 'src/server-shared/inputs/select/components/indicator-separator';
import { DropdownIndicator16px } from 'src/server-shared/inputs/select/components/dropdown-indicator';
import { HeaderMenuList } from 'src/server-shared/inputs/select/components/menu-list';
import { HeaderCustomOptionExtended } from 'src/server-shared/inputs/select/components/option';
import { SingleValueFounderHeader } from 'src/server-shared/inputs/select/components/single-value';
import { AddVentureModal } from 'src/ui/menu/modals/AddVentureModal';
import { useGetVentureByRefId, useGetVentures } from 'src/queries/ventures.query';
import { UserVentureRole, Venture } from 'src/interfaces/venture';
import { useSaveUser } from 'src/queries/registration.query';
import { useToggle } from 'src/hooks/useToggle';
import { borderRadius } from 'src/styles/border-radius';
import { spacing } from 'src/styles/spacing';
import { setFounderSelectedVenture } from 'src/store/founder-shared-data/reducer';
import { resetVenturePitch } from 'src/store/founder-shared-data/reducer';
import { useGetUser } from 'src/queries/users/get-user.query';
import { VENTURE_DETAILS_PAGE } from 'src/constants/paths';

const customStyle = {
  control: (provided: any) => {
    return {
      ...provided,
      minWidth: 180,
      padding: `0 ${spacing.xs}`,
      margin: 0,
      marginLeft: 0,
      backgroundColor: palette.body,
      outline: 'none',
      border: 0,
      boxShadow: 'none',
      borderRadius: borderRadius.s,
      height: 28,
      minHeight: 28,
      cursor: 'pointer',

      '&:hover': {
        backgroundColor: palette.grey100,
      },
    };
  },
  menu: (provided: any) => ({
    ...provided,
    width: 320,
    right: 0,
    backgroundColor: palette.grey900,
    borderRadius: borderRadius.s,
    '&:hover': {
      backgroundColor: palette.grey900,
    },
  }),
  menuList: (provided: any) => ({
    ...provided,
    paddingBottom: 8,
    paddingTop: 8,
  }),
  option: (_: any, state: any) => {
    return {
      backgroundColor: palette.grey900,
      color: palette.body,
      cursor: state.data.data.isDisabled ? 'default' : 'pointer',
      pointerEvents: state.data.data.isDisabled ? ('none' as const) : ('all' as const),
      opacity: state.data.data.isDisabled ? 0.5 : 1,
      '&:hover': {
        backgroundColor: palette.grey700,
      },
    };
  },
};

export const FounderVentureList = () => {
  const [showAddVentureModal, setShowAddVentureModal] = useToggle();
  const [value, setValue] = useState('');

  const router = useRouter();
  const { ventureId } = router.query as { ventureId: string };

  const dispatch = useDispatch();

  const { user } = useGetUser();
  const {
    ventureOptions,
    loading: loadingVentures,
    ventures,
    refetch,
  } = useGetVentures({
    onCompleted: ({ ventures }) => {
      if (ventures.length) {
        const predefined = ventureId
          ? ventures.find((e: Venture) => e.id === Number(ventureId))
          : undefined;
        const verifiedVenture = user?.userVentureRelations?.find((el) => el.verified);
        const venture = ventures.find((el: Venture) => el.id === verifiedVenture?.ventureId);
        setValue(predefined ? predefined.refId : venture.refId || '');
        dispatch(setFounderSelectedVenture(predefined || venture || null));
      }
    },
    skip: !user,
  });

  const options = useMemo(() => {
    return ventureOptions.map((option) => {
      const userVenture = user?.userVentureRelations?.find(
        (el) => el.ventureId === (option.data?.item.id as number),
      );
      return { ...option, data: { ...option.data, isDisabled: !userVenture?.verified } };
    });
  }, [user?.userVentureRelations, ventureOptions]);

  const { saveUser } = useSaveUser();
  const { getVenture: getVentureByRefId } = useGetVentureByRefId();

  const setNewVenture = useCallback(
    async (selectedVenture) => {
      const newVenture = await getVentureByRefId(selectedVenture.refId);
      const userVentureRelations = [
        ...(user?.userVentureRelations || []),
        {
          role: UserVentureRole['FOUNDER'],
          ventureId: newVenture.id,
          userId: user?.id,
        },
      ];
      await saveUser({ ...user, userVentureRelations }).then(() => refetch());
    },
    [getVentureByRefId, refetch, saveUser, user],
  );

  const onChangeVenture = useCallback(
    ({ value: v, data }: any) => {
      const { item } = data as { item: Venture };
      if (v !== value) {
        setValue(v);
        dispatch(resetVenturePitch());
        dispatch(setFounderSelectedVenture(item));
        if ([VENTURE_DETAILS_PAGE].includes(router.pathname)) {
          router.push(
            {
              pathname: router.pathname,
              query: { ventureId: item.id },
            },
            undefined,
            { shallow: true },
          );
        }
      }
    },
    [dispatch, value, router],
  );

  return (
    <div className="flex" style={{ paddingRight: 32 }}>
      <ServerSharedSelect
        options={options}
        onChange={onChangeVenture}
        components={{
          IndicatorSeparator,
          DropdownIndicator: DropdownIndicator16px,
          Option: HeaderCustomOptionExtended,
          MenuList: (props: MenuListProps) => (
            <HeaderMenuList {...props} openModal={setShowAddVentureModal} />
          ),
          SingleValue: SingleValueFounderHeader,
        }}
        styles={customStyle}
        isSearchable={false}
        value={value}
        isLoading={loadingVentures}
      />

      {showAddVentureModal && (
        <AddVentureModal
          userVentures={ventures}
          show
          closeEvent={setShowAddVentureModal}
          onSetVenture={setNewVenture}
        />
      )}
    </div>
  );
};
