import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { fileFields, investmentOfferingOpex } from '../common.strings';
import { DataRoomFinancials } from 'src/interfaces/data-room';

export const financialsGQLString = `
liquidityPlaning {
  ${fileFields}  
}
longTermFinancingPlaning {
  ${fileFields}  
}
longTermPlaningDescription
opexes{
  ${investmentOfferingOpex}  
}
shortTermPlaningDescription
financialStatements {
  description
  id
  title
  file {
    ${fileFields}  
  }
}
`;

const dataRoomAssetsMutation = gql`
  mutation saveDataRoomFinancials($equityOfferingId: Long, $financials: DataRoomFinancialsInput) {
    saveDataRoomFinancials(equityOfferingId: $equityOfferingId, financials: $financials) {
      ${financialsGQLString}
    }
  }
`;

interface UseSaveDataRoomFinancialsMutation {
  equityOfferingId: number;
  financials: DataRoomFinancials;
}

export const useSaveDataRoomFinancials = () => {
  const [mutateDataRoomFinancials, { loading, data }] = useMutation(dataRoomAssetsMutation);

  const saveDataRoomFinancials = useCallback(
    async (variables: UseSaveDataRoomFinancialsMutation) => {
      await mutateDataRoomFinancials({ variables });
    },
    [mutateDataRoomFinancials],
  );
  return { saveDataRoomFinancials, loading, savedDataRoomFinancials: data?.saveDataRoomFinancials };
};
