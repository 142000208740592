import { SVGProps, memo } from 'react';

const SvgInformationCircleFill24Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#information-circle-fill-24px_svg__a)">
      <path
        d="M12 2a10 10 0 1 0 0 20 10 10 0 0 0 0-20Zm.208 4.167a1.25 1.25 0 1 1 0 2.5 1.25 1.25 0 0 1 0-2.5Zm1.875 11.25H10.75a.833.833 0 0 1 0-1.667h.625a.209.209 0 0 0 .208-.208v-3.75a.208.208 0 0 0-.208-.209h-.625a.834.834 0 0 1 0-1.666h.833a1.667 1.667 0 0 1 1.667 1.666v3.959a.209.209 0 0 0 .208.208h.625a.833.833 0 1 1 0 1.667Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="information-circle-fill-24px_svg__a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h20v20H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgInformationCircleFill24Px);
export default Memo;
