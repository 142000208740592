import { useSelector } from 'react-redux';
import { ServerSharedAQImageWithChildren } from 'src/server-shared/aq-image';
import { ServerSharedText } from 'src/server-shared/texts/text';
import { UserPlaceholderIcon } from 'src/server-shared/texts/user-placeholder-icon';
import { getStaffImpersonate } from 'src/store/staff-impersonate/reducer';

export const ImpersonateUser = () => {
  const { user, adminView } = useSelector(getStaffImpersonate);
  if (!user || !adminView) {
    return null;
  }

  return (
    <div className="grid-column template-mc-mc align-items-center xs" style={{ paddingRight: 32 }}>
      <ServerSharedAQImageWithChildren
        width={24}
        height={24}
        image={user.image}
        borderRadius="round"
        placeholderIcon={<UserPlaceholderIcon fullName={user.fullName} />}
      />
      <ServerSharedText color="grey500" type="label-s">
        {user.fullName}
      </ServerSharedText>
    </div>
  );
};
