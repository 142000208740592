import { FC, useState, useMemo, useCallback, createContext, useRef } from 'react';

import {
  SingleTopNotification,
  SingleTopNotificationProps,
} from 'src/server-shared/single-top-notification';

type ShowRibbonFunc = (props: Omit<SingleTopNotificationProps, 'open'>, delay?: number) => void;
type ShowRibbonWithDynamicTextFunc = (
  props: Omit<SingleTopNotificationProps, 'open'>,
  texts: string[],
) => void;

interface RibbonContextValue {
  showRibbon: ShowRibbonFunc;
  closeRibbon: () => void;
  showRibbonWithDynamicText: ShowRibbonWithDynamicTextFunc;
  isOpen: boolean;
}

export const RibbonContext = createContext<RibbonContextValue>({
  showRibbon: () => undefined,
  closeRibbon: () => undefined,
  showRibbonWithDynamicText: () => undefined,
  isOpen: false,
});

export const RibbonProvider: FC = ({ children }) => {
  const interval = useRef<NodeJS.Timer>();

  const [ribbon, setRibbon] = useState<SingleTopNotificationProps>({ open: false });

  const showRibbon: ShowRibbonFunc = useCallback((props, delay = 3000) => {
    setRibbon({ ...props, open: true });
    setTimeout(() => setRibbon((ps) => ({ ...ps, open: false })), delay);
  }, []);

  const closeRibbon = useCallback(() => {
    setRibbon((ps) => ({ ...ps, open: false }));
    if (interval.current) clearInterval(interval.current);
  }, []);

  const showRibbonWithDynamicText: ShowRibbonWithDynamicTextFunc = useCallback((props, texts) => {
    setRibbon({ ...props, open: true });
    interval.current = setInterval(() => {
      setRibbon((ps) => {
        const index = texts.findIndex((e) => e === ps.text);
        const nextText = texts[index + 1];

        return { ...ps, text: nextText ? nextText : texts[0], open: true };
      });
    }, 3000);
  }, []);

  const contextValue = useMemo(
    () => ({
      showRibbon,
      closeRibbon,
      showRibbonWithDynamicText,
      isOpen: ribbon.open,
    }),
    [showRibbon, ribbon, closeRibbon, showRibbonWithDynamicText],
  );

  return (
    <RibbonContext.Provider value={contextValue}>
      {children}

      <SingleTopNotification {...ribbon} />
    </RibbonContext.Provider>
  );
};
