import { FileField } from '../../interfaces/common';
import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { fileFields } from '../common.strings';
import { venturePitchProblemGQLString } from '../venture-pitch/setup/problem';
import { venturePitchSolutionGQLString } from '../venture-pitch/setup/solution';
import { venturePitchProductGQLString } from '../venture-pitch/setup/product';
import { VentureProblem, VentureProduct, VentureSolution } from 'src/interfaces/venture-pitch';

export const productAndDemoGQLString = `
customerPersonasAttachments {
  ${fileFields}
}
customerPersonasDescription
customerProblem {
  ${venturePitchProblemGQLString}
}
customerSolution {
  ${venturePitchSolutionGQLString}
}
product {
  ${venturePitchProductGQLString}
}
uspClarificationAttachments {
  ${fileFields}
}
uspClarificationDescription
problemValidationAttachments {
  ${fileFields}
}
problemValidationDescription
`;

const dataRoomProductAndDemoMutation = gql`
  mutation saveDataRoomProductAndDemo(
    $equityOfferingId: Long!
    $productAndDemo: DataRoomProductAndDemoInput
  ) {
    saveDataRoomProductAndDemo(equityOfferingId: $equityOfferingId, productAndDemo: $productAndDemo) {
      ${productAndDemoGQLString}
    }
  }
`;

interface UseSaveDataRoomProductAndDemoMutation {
  equityOfferingId: number;
  productAndDemo: {
    problemValidationDescription: string;
    problemValidationAttachments: FileField[];
    customerPersonasAttachments: FileField[];
    customerPersonasDescription: string;
    uspClarificationAttachments: FileField[];
    uspClarificationDescription: string;
    customerProblem: VentureProblem;
    customerSolution: VentureSolution;
    product: VentureProduct;
  };
}

export const useSaveDataRoomProductAndDemo = () => {
  const [mutateDataRoomProductAndDemo, { loading, data }] = useMutation(
    dataRoomProductAndDemoMutation,
  );

  const saveDataRoomProductAndDemo = useCallback(
    async (variables: UseSaveDataRoomProductAndDemoMutation) => {
      await mutateDataRoomProductAndDemo({ variables });
    },
    [mutateDataRoomProductAndDemo],
  );
  return {
    saveDataRoomProductAndDemo,
    loading,
    savedDataRoomProductAndDemo: data?.saveDataRoomProductAndDemo,
  };
};
