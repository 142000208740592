import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PathItem, UserNavigationPathItem } from 'src/interfaces/app-navigation';
import { User } from 'src/interfaces/user';
import { Notification } from 'src/shared/AqNotification';
import { dataRoomReviewStepOptions } from 'src/constants/dataRoomReview';
import {
  Configuration,
  DataRoomSectionAttributeDTO,
  SideBarStep,
} from 'src/interfaces/configuration';
import { RootState } from '..';
import initialState from '../initial-store';
import { StepIndicatorObject } from 'src/server-shared/page-step-indicator';
import { getConfigByUser } from 'src/constants/navigation';

export const dataRoomReviewStepsMapper = (data: SideBarStep[]) => {
  return data.map((el, i) => ({
    ...el,
    label: el.titleStringId,
    ...dataRoomReviewStepOptions,
    active: i === 0 ? true : false,
  }));
};

const getBreadcrumbsParts = (path: string, userPaths: PathItem[]) => {
  const parts = path.split('/').filter(Boolean);
  const [, breadcrumbs] = parts.reduce(
    ([accPath, acc], part) => {
      const newPath = !accPath ? `/${part}` : `${accPath}/${part}`;
      const pathItem = userPaths.find((item) => item.path === newPath);
      if (pathItem) {
        acc.push(pathItem);
      }
      return [newPath, acc];
    },
    ['', [] as PathItem[]],
  );
  return breadcrumbs;
};

const getNavigationPaths = (user: User): UserNavigationPathItem[] => {
  const paths = getConfigByUser(user).paths;
  const arrayOfPaths = Object.keys(paths).map((el) => paths[el]);
  return arrayOfPaths.map((e) => ({
    ...e,
    breadcrumbs: getBreadcrumbsParts(e.path, arrayOfPaths),
  }));
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState.app,
  reducers: {
    setConfiguration(state, { payload }: PayloadAction<Configuration>) {
      state.dataRoomReviewSteps = dataRoomReviewStepsMapper(payload.dataRoomReviewSections);
      state.dataRoomEvaluationSteps = payload.dataRoomEvaluationSections.map((el, i) => ({
        ...el,
        label: el.titleStringId,
        active: i === 0 ? true : false,
        available: true,
        complete: false,
      }));

      state.dataRoomSectionAttributes = payload.dataRoomSectionAttributes.reduce((acc, curr) => {
        return { ...acc, [curr.value]: curr };
      }, {} as { [key: string]: DataRoomSectionAttributeDTO });

      state.notificationEventClusters = payload.notificationEventClusters;
      state.notificationEvents = payload.notificationEvents;
    },
    setActiveNavigationItem(state, { payload }: PayloadAction<string>) {
      const item = state.userNavigationItems.find((e) => e.path === payload);
      state.activeNavigationItem = item || state.activeNavigationItem;
    },
    updateNavigationItem(state, { payload }: PayloadAction<{ path: string; item: PathItem }>) {
      state.userNavigationItems = state.userNavigationItems.map((e) => {
        if (e.path === payload.path) {
          return {
            ...e,
            breadcrumbs: e.breadcrumbs.map((bc) =>
              bc.path === payload.path ? { ...bc, ...payload.item } : bc,
            ),
          };
        }
        return e;
      });

      state.activeNavigationItem = {
        ...state.activeNavigationItem,
        breadcrumbs: state.activeNavigationItem.breadcrumbs.map((bc) =>
          bc.path === payload.path ? { ...bc, ...payload.item } : bc,
        ),
      };
    },
    updateNavigationItems(state, { payload }: PayloadAction<{ path: string; item: PathItem }[]>) {
      state.userNavigationItems = state.userNavigationItems.map((e) => {
        const payloadItem = payload.find((el) => el.path === e.path);
        if (payloadItem) {
          return {
            ...e,
            breadcrumbs: e.breadcrumbs.map((bc) =>
              bc.path === payloadItem.path ? { ...bc, ...payloadItem.item } : bc,
            ),
          };
        }
        return e;
      });

      state.activeNavigationItem = {
        ...state.activeNavigationItem,
        breadcrumbs: state.activeNavigationItem.breadcrumbs.map((bc) => {
          const payloadItem = payload.find((el) => el.path === bc.path);
          return payloadItem ? { ...bc, ...payloadItem.item } : bc;
        }),
      };
    },
    updateHeaderTagItem(state, { payload }: PayloadAction<Partial<PathItem['headerTag']>>) {
      state.activeNavigationItem = {
        ...state.activeNavigationItem,
        headerTag: {
          ...state.activeNavigationItem.headerTag,
          ...payload,
        },
      };
    },
    replaceNavigationItem(state, { payload }: PayloadAction<{ data: UserNavigationPathItem }>) {
      state.userNavigationItems = state.userNavigationItems.map((e) =>
        e.path === payload.data.path ? payload.data : e,
      );

      if (state.activeNavigationItem.path === payload.data.path) {
        state.activeNavigationItem = payload.data;
      }
    },
    setUserPaths(state, { payload }: PayloadAction<User>) {
      const navigationItems = getNavigationPaths(payload);
      state.userNavigationItems = navigationItems;
    },
    addNotification(state, { payload }: PayloadAction<Notification>) {
      state.notifications = [...state.notifications, payload];
    },
    removeLastNotification(state) {
      const newState = [...state.notifications];
      newState.splice(-1);
      state.notifications = newState;
    },
    setErrorHandler(state, { payload }: PayloadAction<number>) {
      state.code = payload;
    },
    resetErrorHandler(state) {
      state.code = null;
    },
    removeBreadcrumb(state, { payload }: PayloadAction<string>) {
      state.activeNavigationItem = {
        ...state.activeNavigationItem,
        breadcrumbs: state.activeNavigationItem.breadcrumbs.filter((bc) => bc.path !== payload),
      };
    },
    setHeaderStepper(
      state,
      { payload }: PayloadAction<{ steps: StepIndicatorObject[]; title?: string }>,
    ) {
      state.headerStepper.steps = payload.steps || state.headerStepper.steps;
      state.headerStepper.title = payload.title || state.headerStepper.title;
    },
    setHeaderStepperButton(state, { payload }: PayloadAction<{ titleButtonText: string }>) {
      state.headerStepper.titleButtonText =
        payload.titleButtonText || state.headerStepper.titleButtonText;
    },
    resetHeaderStepper(state) {
      state.headerStepper.steps = [];
      state.headerStepper.title = '';
      state.headerStepper.titleButtonText = '';
    },
  },
});

export const {
  setActiveNavigationItem,
  updateNavigationItem,
  updateNavigationItems,
  updateHeaderTagItem,
  replaceNavigationItem,
  setUserPaths,
  addNotification,
  removeLastNotification,
  setErrorHandler,
  resetErrorHandler,
  removeBreadcrumb,
  setConfiguration,
  setHeaderStepper,
  resetHeaderStepper,
  setHeaderStepperButton,
} = appSlice.actions;

export default appSlice.reducer;

const selectSelf = (state: RootState) => state;
export const getActiveNavigationItem = createSelector(
  selectSelf,
  (state: RootState) => state.app.activeNavigationItem,
);
export const getNotifications = createSelector(
  selectSelf,
  (state: RootState) => state.app.notifications,
);
export const gerErrorCode = createSelector(selectSelf, (state: RootState) => state.app.code);

export const getDataRoomReviewStepsSelector = createSelector(
  selectSelf,
  (state: RootState) => state.app.dataRoomReviewSteps,
);

export const getDataRoomEvaluationStepsSelector = createSelector(
  selectSelf,
  (state: RootState) => state.app.dataRoomEvaluationSteps,
);

export const getDataRoomReviewSectionAttributesSelector = createSelector(
  selectSelf,
  (state: RootState) => state.app.dataRoomSectionAttributes,
);

export const getHeaderStepper = createSelector(
  selectSelf,
  (state: RootState) => state.app.headerStepper,
);

export const getNotificationEventClusters = createSelector(
  selectSelf,
  (state: RootState) => state.app.notificationEventClusters,
);

export const getNotificationEvents = createSelector(
  selectSelf,
  (state: RootState) => state.app.notificationEvents,
);
