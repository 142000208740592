import { SVGProps, memo } from 'react';

const SvgUser16Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M8 7.875a2.75 2.75 0 1 0 0-5.5 2.75 2.75 0 0 0 0 5.5ZM8 8.625a4.755 4.755 0 0 0-4.75 4.75.25.25 0 0 0 .25.25h9a.25.25 0 0 0 .25-.25A4.755 4.755 0 0 0 8 8.625Z"
      fill="#1F2937"
    />
  </svg>
);

const Memo = memo(SvgUser16Px);
export default Memo;
