import { SVGProps, memo } from 'react';

const SvgChevronDown16Px = (props: SVGProps<SVGSVGElement>) => (
  <svg width={16} height={16} fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
    <g clipPath="url(#chevron-down-16px_svg__a)">
      <path
        d="M8 11.75c-.35 0-.65-.15-.85-.4L2.2 5.8c-.3-.4-.25-1 .15-1.3.4-.3.95-.3 1.25.1l4.3 4.9c.05.05.1.05.2 0l4.3-4.9c.35-.4.9-.45 1.3-.1s.45.9.1 1.3l-.05.05-4.9 5.55c-.2.2-.55.35-.85.35Z"
        fill="#1F2937"
      />
    </g>
    <defs>
      <clipPath id="chevron-down-16px_svg__a">
        <path fill="#fff" transform="translate(2 2)" d="M0 0h12v12H0z" />
      </clipPath>
    </defs>
  </svg>
);

const Memo = memo(SvgChevronDown16Px);
export default Memo;
