import { useMemo, useState } from 'react';
import Link from 'next/link';

import { AQSquareLogo } from 'src/shared/AQSquareLogo';
import { ServerSharedAQImageWithChildren } from 'src/server-shared/aq-image';
import { Roles } from 'src/interfaces/user';
import { NavItem } from './nav-item';
import { getConfigByUser } from 'src/constants/navigation';
import { useGetUser } from 'src/queries/users/get-user.query';
import { SidebarUserMenu } from './sidebar-user-menu';
import classes from './index.module.scss';
import { AvatarBadge } from 'src/server-shared/modals/complete-profile';
import { UserPlaceholderIcon } from 'src/server-shared/texts/user-placeholder-icon';
import { classNames } from 'src/utils/classNames';
import { useGetRoleAccreditation } from 'src/queries/role-accreditation';

export const ApplicationSidebar = () => {
  const { user, role, refetch } = useGetUser();
  const { userRoleAccreditationDetails } = useGetRoleAccreditation();
  const [showUserMenu, setShowUserMenu] = useState(false);

  const userSideMenu = useMemo(() => {
    if (!user) return [];
    return getConfigByUser(user).sidebar;
  }, [user]);

  const height = useMemo(() => 48 * userSideMenu.length, [userSideMenu.length]);

  return userSideMenu.length === 0 ? null : (
    <nav className={classes['side-bar-container']}>
      <div className={classes['side-bar-container__static']}>
        {role === Roles.AQUATY_STAFF ? (
          <div className={classes['side-bar-container__logo-anchor']}>
            <AQSquareLogo />
          </div>
        ) : (
          <Link href="/home" legacyBehavior passHref>
            <div className={classes['side-bar-container__logo-anchor']}>
              <AQSquareLogo />
            </div>
          </Link>
        )}

        <ul className={classes['side-bar-container__menu']} style={{ height }}>
          {userSideMenu.map((item) => (
            <NavItem key={item.path} {...item} />
          ))}
        </ul>

        <div
          className={classNames('flex', classes['side-bar-container__user'])}
          onClick={() => setShowUserMenu(true)}
        >
          <div className={classNames('flex', classes['side-bar-container__user-avatar'])}>
            <ServerSharedAQImageWithChildren
              image={user?.image}
              width={40}
              height={40}
              borderRadius="round"
              placeholderIcon={<UserPlaceholderIcon fullName={user?.fullName} />}
            />
            {role === Roles.SOFT_REGISTERED_VENTURE_INVESTOR && <AvatarBadge isBlack isSmall />}
          </div>
          {showUserMenu && (
            <SidebarUserMenu
              user={user}
              role={role}
              onHide={() => setShowUserMenu(false)}
              userRoleAccreditationDetails={userRoleAccreditationDetails}
              refetchUser={refetch}
            />
          )}
        </div>
      </div>
    </nav>
  );
};
