import { useCallback } from 'react';
import { gql, useMutation } from '@apollo/client';
import { DataRoomPreviousFinancingEvent } from 'src/interfaces/data-room';

export const previousFinancingEventsGQLString = `
capitalAmount
id
interestRate
legalLenderName
loanTerminationDate
postMoneyValuation
roundName
selectedConvertibleLoans
signingDate
type
participants {
  id
  participationAmount
  investorId
}
`;

const dataRoomPreviousFinancingsMutation = gql`
  mutation saveDataRoomPreviousFinancing(
    $equityOfferingId: Long!
    $previousFinancing: [DataRoomPreviousFinancingEventInput]
  ) {
    saveDataRoomPreviousFinancing(
      equityOfferingId: $equityOfferingId
      previousFinancing: $previousFinancing
    ) {
      ${previousFinancingEventsGQLString}
    }
  }
`;

interface UseSaveDataRoomPreviousFinancingsMutation {
  equityOfferingId: number;
  previousFinancing: DataRoomPreviousFinancingEvent[];
}

export const useSaveDataRoomPreviousFinancings = () => {
  const [mutateDataRoomPreviousFinancings, { loading, data }] = useMutation(
    dataRoomPreviousFinancingsMutation,
  );

  const saveDataRoomPreviousFinancings = useCallback(
    async (variables: UseSaveDataRoomPreviousFinancingsMutation) => {
      await mutateDataRoomPreviousFinancings({ variables });
    },
    [mutateDataRoomPreviousFinancings],
  );
  return {
    saveDataRoomPreviousFinancings,
    loading,
    savedDataRoomPreviousFinancings: data?.saveDataRoomPreviousFinancing,
  };
};
